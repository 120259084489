import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import '../../styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.denatranVehicleInfos';

function VehiclesRestrictionsExtraInfosCollapsePanel({
  restrictionsExtraInfo = {},
  toPrint = false,
}) {
  const { t: translate } = useTranslation();

  const title = useMemo(() => {
    return restrictionsExtraInfo?.infoType === 'financing'
      ? translate(`${I18N_BASE_PATH}.columns.financingTitle`)
      : translate(`${I18N_BASE_PATH}.columns.renajudTitle`);
  }, [restrictionsExtraInfo, translate]);

  const content = useMemo(() => {
    if (!restrictionsExtraInfo) return [];

    if (restrictionsExtraInfo?.infoType === 'financing') {
      return [
        {
          title: translate(`${I18N_BASE_PATH}.columns.financialCgc`),
          value: restrictionsExtraInfo?.financialCgc || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.agentCode`),
          value: restrictionsExtraInfo?.agentCode || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.inclusionDate`),
          value: restrictionsExtraInfo?.inclusionDate || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.contractValidityDate`),
          value: restrictionsExtraInfo?.contractValidityDate || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.financialAgentName`),
          value: restrictionsExtraInfo?.financialAgentName || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.financedName`),
          value: restrictionsExtraInfo?.financedName || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.financedCpfCnpj`),
          value: restrictionsExtraInfo?.financedCpfCnpj || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.contractNumber`),
          value: restrictionsExtraInfo?.contractNumber || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.financingRestrictionType`),
          value: restrictionsExtraInfo?.financingRestrictionType || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.transactionType`),
          value: restrictionsExtraInfo?.transactionType || '-',
        },
      ];
    }

    if (restrictionsExtraInfo?.infoType === 'renajud') {
      return [
        {
          title: translate(`${I18N_BASE_PATH}.columns.date`),
          value: restrictionsExtraInfo?.date || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.type`),
          value: restrictionsExtraInfo?.type || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.court`),
          value: restrictionsExtraInfo?.court || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.judicialAgency`),
          value: restrictionsExtraInfo?.judicialAgency || '-',
        },
        {
          title: translate(`${I18N_BASE_PATH}.columns.process`),
          value: restrictionsExtraInfo?.process || '-',
        },
      ];
    }
  }, [restrictionsExtraInfo, translate]);

  const customHeader = useMemo(() => {
    return (
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col span={18}>
          <i className="caf-ic_driver" />
          <span className="text-dark text-bold pdd-left-10">{title}</span>
        </Col>
      </Row>
    );
  }, [title]);

  return (
    <DataSourceCardCollapsePanel customHeader={customHeader} toPrint={toPrint}>
      <Row justify="space-between" className="mrg-top-10">
        {content?.map((item) => (
          <ColumnTitleValue
            className="inline-infos"
            key={item.title}
            span={8}
            title={`${item.title}:`}
            value={item.value}
          />
        ))}
      </Row>
    </DataSourceCardCollapsePanel>
  );
}

export default VehiclesRestrictionsExtraInfosCollapsePanel;
