import React from 'react';
import PropTypes from 'prop-types';

import './styles.less';

const CollapseTitle = ({ title, icon, customIcon, extra, classNameCustom }) => {
  return (
    <div id="collapse-header-title">
      <span className="width-100">
        {icon && (
          <div className="div-icon">
            <i className={icon} />
          </div>
        )}
        {customIcon}
        <span className={`collapse-title ${classNameCustom}`}>{title}</span>
        {extra}
      </span>
    </div>
  );
};

CollapseTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  extra: PropTypes.node,
  classNameCustom: PropTypes.string,
};

CollapseTitle.defaultProps = {
  extra: null,
  classNameCustom: '',
};

export default CollapseTitle;
