import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './styles.less';
import { rudderAnalytics } from 'hooks/rudderstack';
import { useAuth } from 'hooks/auth';

const HiddenParagraph = ({ text, hidden, copyable, trackEvent = false }) => {
  const [isHidden, setIsHidden] = useState(hidden);
  const { t: translate } = useTranslation();
  const { user } = useAuth();

  const toggleIsHidden = useCallback(() => {
    setIsHidden((state) => !state);
  }, []);

  const onCopy = useCallback(() => {
    message.info(translate('components.hiddenParagraph.index.message'));
  }, [translate]);

  return (
    <div id="hidden-paragraph-component">
      {isHidden ? text.split('').map(() => '*') : text}{' '}
      {copyable && (
        <CopyToClipboard text={text} onCopy={onCopy}>
          <Button
            className="no-mrg no-pdd"
            type="link"
            onClick={() => {
              if (trackEvent) {
                rudderAnalytics.track('AUTHORIZATION_KEY_COPIED', {
                  user: user?.username,
                  tenantId: user?.accountData?.tenantId,
                  date: new Date().toISOString(),
                });
              }
            }}
          >
            {translate('components.hiddenParagraph.index.copy')}
          </Button>
        </CopyToClipboard>
      )}
      {hidden && (
        <Button
          className="no-mrg no-pdd"
          type="link"
          onClick={() => {
            if (isHidden && trackEvent) {
              rudderAnalytics.track('AUTHORIZATION_KEY_SHOWED', {
                user: user?.username,
                tenantId: user?.accountData?.tenantId,
                date: new Date().toISOString(),
              });
            }
            toggleIsHidden();
          }}
        >
          {isHidden
            ? translate('components.hiddenParagraph.index.show')
            : translate('components.hiddenParagraph.index.hidden')}
        </Button>
      )}
    </div>
  );
};

HiddenParagraph.propTypes = {
  text: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  copyable: PropTypes.bool,
  trackEvent: PropTypes.bool,
};

HiddenParagraph.defaultProps = {
  hidden: false,
  copyable: false,
  trackEvent: false,
};

export default HiddenParagraph;
