/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';

import DottedLabel from 'components/DottedLabel';
import LegalRecordsList from '../../Lists/LegalRecordsList';
import './styles.less';

import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.legalInformation.componentes.legalRecordsExtended.index.legalRecordsExtended';

const LegalRecordsExtended = ({ source = {}, type, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const legalRecordsExtended = useMemo(() => source.data, [source.data]);

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const legalRecordsExtendedQuerySucceeded = useMemo(
    () =>
      source?.statusCode === '01' ||
      source?.available === true ||
      Object.keys(source?.data || {}).length,
    [source]
  );

  const hasContent = useMemo(() => legalRecordsExtended?.lawsuits?.length > 0, [
    legalRecordsExtended,
  ]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_file"
      title={translate(`${I18N_BASE_PATH}.legalRecordsExtendedTitle`)}
      customId="legalInformation"
      toPrint={toPrint}
      classNameCustom="width-100"
      extra={
        hasContent ? (
          <div className="width-100 text-light">
            <DottedLabel
              className="text-light end-start"
              description={translate(`${I18N_BASE_PATH}.foundLawsuits`, {
                value: legalRecordsExtended?.lawsuits?.length,
              })}
              type="danger"
            />
          </div>
        ) : (
          <></>
        )
      }
    >
      <div id="executions-legal-information-processes-container">
        <Col span={24}>
          {legalRecordsExtended && (
            <>
              {legalRecordsExtendedQuerySucceeded ? (
                <>
                  {hasContent ? (
                    <LegalRecordsList
                      data={legalRecordsExtended?.lawsuits}
                      toPrint={toPrint}
                      hasPersonProcesses={!!legalRecordsExtended?.lawsuits?.length}
                    />
                  ) : (
                    <EmptyState
                      type="empty-success"
                      description={translate(
                        `${I18N_BASE_PATH}.querySucceeded.emptyState.description`,
                        { type }
                      )}
                      className="mrg-btm-20 mrg-top-0"
                    />
                  )}
                </>
              ) : (
                <EmptyExecutionState
                  statusCode={statusCode}
                  message={source?.message || legalRecordsExtended?.message}
                />
              )}
            </>
          )}
        </Col>
      </div>
    </DataSourceCardCollapsePanel>
  );
};

export default LegalRecordsExtended;
