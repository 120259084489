import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import '../styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.denatranDriverAltInfos';

function DriverLicensePointsInfosCollapsePanel({ licensePoints = {}, toPrint = false }) {
  const { t: translate } = useTranslation();

  const customHeader = useMemo(() => {
    return (
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col span={18}>
          <i className="caf-ic_driver" />
          <span className="text-dark text-bold pdd-left-10">
            {translate(`${I18N_BASE_PATH}.licensePointsTitle`).toUpperCase()}
          </span>
        </Col>
      </Row>
    );
  }, [translate]);

  const DetailsInfos = (data, title) => {
    return (
      <Row justify="space-between" className="mrg-top-10">
        {Object.keys(data).map((dtKey) => {
          return (
            <ColumnTitleValue
              key={dtKey}
              span={12}
              className="inline-infos"
              title={`${translate(`${I18N_BASE_PATH}.columns.${dtKey}`)}:`}
              value={data[dtKey] || '-'}
            />
          );
        })}
      </Row>
    );
  };

  return (
    <DataSourceCardCollapsePanel customHeader={customHeader} toPrint={toPrint}>
      {DetailsInfos(licensePoints)}
    </DataSourceCardCollapsePanel>
  );
}

export default DriverLicensePointsInfosCollapsePanel;
