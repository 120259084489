/* eslint-disable react/prop-types */

import { React } from 'react';
import { Divider } from 'antd';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import DottedLabel from 'components/DottedLabel';
import { useExecution } from 'hooks/execution';
import { useTranslation } from 'react-i18next';
import ColumnInformation from '../ColumnInformation';
import RelatedPersonDetails from '../PersonsRelated';

const I18N_BASE_PATH =
  'components.dataSourceCards.relationships.components.peopleComplianceBet.index.components.SportsAndBettingCard';

const validationBettingHouseOrSports = (validations) => {
  if (!validations?.length) return null;
  const validation = validations.find(
    (validation) => validation.code === 'is_prevented_from_betting'
  );

  return {
    type: validation?.status === 'VALID' ? 'primary' : 'danger',
    description: validation?.description,
    status: validation?.status,
    code: validation?.code,
  };
};

const PeopleComplianceBetCard = ({ source }) => {
  const { results, queryId, queryDate } = source.data;

  const { i18nFormatDate } = useI18nFormatters();
  const { t: translate } = useTranslation();
  const { execution } = useExecution();
  const validation = validationBettingHouseOrSports(execution?.validations);

  return (
    <>
      <header className="mrg-top-20 mrg-btm-20">
        {execution?.validations?.length ? (
          <>
            <DottedLabel
              className="text-bold"
              type={validation?.type}
              description={
                validation?.code
                  ? translate(
                      `validations.${validation?.code}.status.${validation?.status}`,
                      validation?.description
                    )
                  : validation?.description
              }
            />
            {validation?.type === 'danger' && (
              <div className="mrg-top-5" style={{ fontSize: '15px', color: '#323232' }}>
                {translate(`${I18N_BASE_PATH}.reprovedMessage`)}
              </div>
            )}
          </>
        ) : (
          <DottedLabel
            className="text-bold"
            type="primary"
            description={translate(`${I18N_BASE_PATH}.emptyState.description`)}
          />
        )}
      </header>

      <Divider />
      <ColumnInformation
        title={translate(`${I18N_BASE_PATH}.title.query`)}
        fields={[
          {
            label: translate(`${I18N_BASE_PATH}.label.queryId`),
            value: queryId,
            span: 8,
          },
          {
            label: translate(`${I18N_BASE_PATH}.label.queryDate`),
            value: queryDate ? i18nFormatDate(queryDate, 3) : null,
            span: 8,
          },
        ]}
      />
      {results.map((result, index) => (
        <div key={index}>
          <RelatedPersonDetails result={result} queryDate={queryDate} query={results} />
        </div>
      ))}
    </>
  );
};

export default PeopleComplianceBetCard;
