import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import { useAuth } from 'hooks/auth';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';

import templateServicesCategories from 'utils/data/templateServicesCategories.json';
import * as executionDataSources from 'utils/executionDataSource';

import { CPF } from './components/CPF';
import { PfData } from './components/pfData';
import { PfBasicData } from './components/pfBasicData';
import { PfCpfData } from './components/pfCpfData';
import { PfPersonalRelationships } from './components/PfPersonalRelationships';
import { PfAddresses } from './components/pfAddresses';
import { PfLocalizaAssertiva } from './components/pfLocalizaAssertiva';
import PfBiometriaUnico from './components/pfBiometriaUnico';
import PfAddressAuthentication from './components/pfAddressAuthentication';
import PfPhoneScore from './components/pfPhoneScore';
import PjData from './components/Company/pjData';
import PjSimples from './components/Company/pjSimples';
import PjSintegraData from './components/Company/pjSintegraData';
import SimSwapAlert from './components/simSwapAlert';
import PfPhoneAnalysis from './components/pfPhoneAnalysis';
import PfEmailAnalysis from './components/pfEmailAnalysis';
import IdentityVerificationKyc from './components/IdentityVerificationKyc';
import { KYCAndCompliance } from '../RestrictiveLists/components/KYCAndCompliance';

import DataSourceCardCollapsePanel from '../DataSourceCardCollapsePanel';

import './styles.less';

const BasicInfos = ({ toPrint = false }) => {
  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();
  const { user } = useAuth();
  const { t: translate } = useTranslation();

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );

  const cpf = useMemo(() => findSourceSection('cpf'), [findSourceSection]);
  const pfData = useMemo(() => findSourceSection('pfData'), [findSourceSection]);
  const pfCpfData = useMemo(() => findSourceSection('pfCpfData'), [findSourceSection]);
  const pfBasicData = useMemo(() => findSourceSection('pfBasicData'), [
    findSourceSection,
  ]);
  const pfPersonalRelationships = useMemo(
    () => findSourceSection('pfPersonalRelationships'),
    [findSourceSection]
  );
  const pfAddresses = useMemo(() => findSourceSection('pfAddresses'), [
    findSourceSection,
  ]);
  const pfAddressesAlt = useMemo(() => findSourceSection('pfAddressesAlt'), [
    findSourceSection,
  ]);
  const pfLocalizaAssertiva = useMemo(() => findSourceSection('pfLocalizaAssertiva'), [
    findSourceSection,
  ]);
  const pfBiometriaUnico = useMemo(() => findSourceSection('pfBiometriaUnico'), [
    findSourceSection,
  ]);
  const pjData = useMemo(() => findSourceSection('pjData'), [findSourceSection]);
  const pjSimples = useMemo(() => findSourceSection('pjSimples'), [findSourceSection]);
  const pjSintegraData = useMemo(() => findSourceSection('pjSintegraData'), [
    findSourceSection,
  ]);
  const pfPhoneScore = useMemo(() => findSourceSection('phoneTrustScore'), [
    findSourceSection,
  ]);

  const simSwapAlert = useMemo(() => findSourceSection('simSwapAlert'), [
    findSourceSection,
  ]);

  const pfAddressAuthentication = useMemo(
    () => findSourceSection('pfAddressAuthentication'),
    [findSourceSection]
  );

  const phoneAnalysis = useMemo(() => findSourceSection('phoneAnalysis'), [
    findSourceSection,
  ]);

  const phoneAnalysisStatusCode =
    person?.backgroundChecking?.services?.phone_analysis?.statusCode;

  const emailAnalysis = useMemo(() => findSourceSection('emailAnalysis'), [
    findSourceSection,
  ]);

  const emailAnalysisStatusCode =
    person?.backgroundChecking?.services?.email_analysis?.statusCode;

  const identityVerificationKyc = useMemo(
    () => findSourceSection('globalIdentityVerifications'),
    [findSourceSection]
  );

  const identityVerificationKyc2x2 = useMemo(
    () => findSourceSection('globalIdentityVerifications2x2'),
    [findSourceSection]
  );

  const complyPeps = useMemo(
    () =>
      findSourceSection('kycPepsEmergingCountries') ||
      findSourceSection('kycPepsLatamCountries'),
    [findSourceSection]
  );

  const complySanctionsList = useMemo(
    () =>
      findSourceSection('kycSanctionsEmergingCountries') ||
      findSourceSection('kycSanctionsLatamCountries') ||
      findSourceSection('sanctions'),
    [findSourceSection]
  );

  const complyAdverseMedia = useMemo(
    () =>
      findSourceSection('kycAdverseMediaEmergingCountries') ||
      findSourceSection('kycAdverseMediaLatamCountries'),
    [findSourceSection]
  );

  const complyWarnings = useMemo(
    () =>
      findSourceSection('kycWarningsEmergingCountries') ||
      findSourceSection('kycWarningsLatamCountries'),
    [findSourceSection]
  );

  const sourcesComponent = useMemo(() => {
    return (
      <>
        {cpf && <CPF source={cpf} toPrint={toPrint} />}
        {pfData && <PfData source={pfData} toPrint={toPrint} />}
        {pfBasicData && <PfBasicData source={pfBasicData} toPrint={toPrint} />}
        {pfCpfData && <PfCpfData source={pfCpfData} toPrint={toPrint} />}
        {pfAddresses && (
          <PfAddresses source={pfAddresses} toPrint={toPrint} type="default" />
        )}
        {pfAddressesAlt && (
          <PfAddresses source={pfAddressesAlt} toPrint={toPrint} type="alt" />
        )}
        {pfPersonalRelationships && (
          <PfPersonalRelationships source={pfPersonalRelationships} toPrint={toPrint} />
        )}
        {pfLocalizaAssertiva && (
          <PfLocalizaAssertiva source={pfLocalizaAssertiva} toPrint={toPrint} />
        )}
        {pfAddressAuthentication && (
          <PfAddressAuthentication source={pfAddressAuthentication} toPrint={toPrint} />
        )}
        {pfBiometriaUnico && (
          <PfBiometriaUnico source={pfBiometriaUnico} toPrint={toPrint} />
        )}
        {pjData && <PjData source={pjData} toPrint={toPrint} />}
        {pjSimples && <PjSimples source={pjSimples} toPrint={toPrint} />}
        {pjSintegraData && <PjSintegraData source={pjSintegraData} toPrint={toPrint} />}
        {pfPhoneScore && <PfPhoneScore source={pfPhoneScore} toPrint={toPrint} />}
        {simSwapAlert && <SimSwapAlert source={simSwapAlert} toPrint={toPrint} />}
        {phoneAnalysis && (
          <PfPhoneAnalysis
            source={phoneAnalysis}
            statusCodePfPhoneAnalysis={phoneAnalysisStatusCode}
            toPrint={toPrint}
          />
        )}
        {emailAnalysis && (
          <PfEmailAnalysis
            source={emailAnalysis}
            statusCodePfEmailAnalysis={emailAnalysisStatusCode}
            toPrint={toPrint}
          />
        )}
        {identityVerificationKyc && (
          <IdentityVerificationKyc source={identityVerificationKyc} toPrint={toPrint} />
        )}
        {identityVerificationKyc2x2 && (
          <IdentityVerificationKyc
            source={identityVerificationKyc2x2}
            toPrint={toPrint}
            is2x2
          />
        )}
        {complyPeps && (
          <KYCAndCompliance
            source={complyPeps}
            toPrint={toPrint}
            executionStatus={execution?.status ?? 'no-status'}
            executionReviews={
              execution?.reviews ? execution?.reviews?.map((review) => review?.data) : []
            }
            type="peps"
          />
        )}
        {complySanctionsList && (
          <KYCAndCompliance
            source={complySanctionsList}
            toPrint={toPrint}
            executionStatus={execution?.status ?? 'no-status'}
            executionReviews={
              execution?.reviews ? execution?.reviews?.map((review) => review?.data) : []
            }
            type="sanctions"
          />
        )}
        {complyAdverseMedia && (
          <KYCAndCompliance
            source={complyAdverseMedia}
            toPrint={toPrint}
            executionStatus={execution?.status ?? 'no-status'}
            executionReviews={
              execution?.reviews ? execution?.reviews?.map((review) => review?.data) : []
            }
            type="adverseMedia"
          />
        )}
        {complyWarnings && (
          <KYCAndCompliance
            source={complyWarnings}
            toPrint={toPrint}
            executionStatus={execution?.status ?? 'no-status'}
            executionReviews={
              execution?.reviews ? execution?.reviews?.map((review) => review?.data) : []
            }
            type="warnings"
          />
        )}
      </>
    );
  }, [
    pfAddressesAlt,
    complyWarnings,
    complyAdverseMedia,
    complyPeps,
    complySanctionsList,
    cpf,
    emailAnalysis,
    emailAnalysisStatusCode,
    execution,
    identityVerificationKyc,
    identityVerificationKyc2x2,
    pfAddressAuthentication,
    pfAddresses,
    pfBasicData,
    pfBiometriaUnico,
    pfData,
    pfCpfData,
    pfLocalizaAssertiva,
    pfPersonalRelationships,
    pfPhoneScore,
    phoneAnalysis,
    phoneAnalysisStatusCode,
    pjData,
    pjSimples,
    pjSintegraData,
    simSwapAlert,
    toPrint,
  ]);

  return hasFeatureFlag('isWorkflowBuilder', user) ? (
    sourcesComponent
  ) : (
    <DataSourceCardCollapsePanel
      id="basic-infos-component"
      title={translate(templateServicesCategories.basic_info.title)?.toUpperCase()}
      icon={templateServicesCategories.basic_info.icon}
      toPrint={toPrint}
    >
      {sourcesComponent}
    </DataSourceCardCollapsePanel>
  );
};

export default BasicInfos;
