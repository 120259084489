import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

const CardTag = ({ text }) => {
  return (
    <>
      <span className="card-tag">
        <p>{text}</p>
      </span>
    </>
  );
};

CardTag.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CardTag;
