import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';
import Cookies from 'js-cookie';

import api from 'services/api';

import { secondsToDay } from 'utils/formatters';
import handleHubSelection from 'utils/handleHubSelection';
import { useSocket } from './socket';

const isLocalhost = !!window.location.origin.startsWith('http://localhost');
const isCafIo = window.location.origin.indexOf('caf.io') !== -1;

const ACCESS_TOKEN = isLocalhost ? 'CAF_access_token' : '__Secure-CAF_access_token';
const REFRESH_TOKEN = isLocalhost ? 'CAF_refresh_token' : '__Secure-CAF_refresh_token';
const LANGUAGE = isLocalhost ? 'CAF_language' : '__Secure-CAF_language';

// eslint-disable-next-line no-nested-ternary
const COOKIE_OPTIONS = isLocalhost
  ? {
      path: '/',
    }
  : isCafIo
  ? {
      domain: '.caf.io',
      secure: true,
      sameSite: 'strict',
    }
  : {
      domain: '.combateafraude.com',
      secure: true,
      sameSite: 'strict',
    };

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const { connect, addFunction, disconnect } = useSocket();

  const [query] = useQueryParams({
    continue: StringParam,
  });

  const [user, setUser] = useState();
  const [language, setLanguage] = useState();
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const [errorSignIn, setErrorSignIn] = useState(undefined);

  const handleChangeLanguage = useCallback(
    (lang) => {
      i18n.changeLanguage(lang);

      Cookies.set(LANGUAGE, lang, COOKIE_OPTIONS);
    },
    [i18n]
  );

  useEffect(() => {
    if (language && language !== i18n.language) {
      handleChangeLanguage(language);
    }
  }, [handleChangeLanguage, i18n, language]);

  const signIn = useCallback(
    async ({ email, password, captchaToken }) => {
      try {
        setLoadingSignIn(true);

        const response = await api.post(
          `${process.env.REACT_APP_BASE_URL_AUTH_API}/token`,
          {
            username: email,
            password,
            captchaToken,
          }
        );

        const { expires_in, access_token, refresh_token } = response?.data || {};

        const ACCESS_TOKEN_OPTIONS = {
          ...COOKIE_OPTIONS,
          expires: secondsToDay(expires_in),
        };

        const REFRESH_TOKEN_OPTIONS = {
          ...COOKIE_OPTIONS,
          expires: 7,
        };

        Cookies.set(ACCESS_TOKEN, access_token, ACCESS_TOKEN_OPTIONS);
        Cookies.set(REFRESH_TOKEN, refresh_token, REFRESH_TOKEN_OPTIONS);

        api.defaults.headers.Authorization = `Bearer ${access_token}`;

        setUser('idle');

        if (query?.continue) {
          const redirectDomain = decodeURIComponent(query.continue);

          history.push(redirectDomain);
          return;
        }

        history.push('/home');
      } catch (error) {
        setErrorSignIn(error?.response?.data || true);
      } finally {
        setLoadingSignIn(false);
      }
    },
    [query, history]
  );

  const refreshToken = useCallback(async () => {
    const refreshTokenFromCookies = Cookies.get(REFRESH_TOKEN);

    return api
      .post(`${process.env.REACT_APP_BASE_URL_AUTH_API}/token`, {
        grant_type: 'refresh_token',
        refresh_token: refreshTokenFromCookies,
      })
      .then((response) => {
        const { expires_in, access_token, refresh_token } = response?.data || {};

        const ACCESS_TOKEN_OPTIONS = {
          ...COOKIE_OPTIONS,
          expires: secondsToDay(expires_in),
        };

        const REFRESH_TOKEN_OPTIONS = {
          ...COOKIE_OPTIONS,
          expires: 7,
        };

        Cookies.set(ACCESS_TOKEN, access_token, ACCESS_TOKEN_OPTIONS);
        Cookies.set(REFRESH_TOKEN, refresh_token, REFRESH_TOKEN_OPTIONS);

        api.defaults.headers.Authorization = `Bearer ${access_token}`;

        return response?.data;
      });
  }, []);

  const signOut = useCallback(async () => {
    return api
      .post(`${process.env.REACT_APP_BASE_URL_AUTH_API}/revoke`)
      .then((response) => {
        handleHubSelection.removeHubSelected();
        Cookies.remove(ACCESS_TOKEN, COOKIE_OPTIONS);
        Cookies.remove(REFRESH_TOKEN, COOKIE_OPTIONS);
        setUser();
        return response?.data;
      });
  }, []);

  const isAuthenticated = useCallback(() => {
    return !!Cookies.get(ACCESS_TOKEN);
  }, []);

  const isAdmin = useCallback(() => {
    return user?.usersGroupId === '6112a54246c2514b138bc35e';
  }, [user]);

  useEffect(() => {
    if (user?.username) {
      connect(user.username);
    } else {
      disconnect();
    }
  }, [addFunction, connect, disconnect, signOut, user]);

  addFunction('authForceDisconnection', (message) => {
    if (message.action === 'forceDisconnection') {
      Cookies.set('DISCONNECTED_REASON', message.reason, COOKIE_OPTIONS);
      signOut();
    }
  });

  const getLoggedUser = useCallback(
    async () =>
      api
        .get(`${process.env.REACT_APP_BASE_URL_AUTH_API}/users/me`)
        .then(({ data: response }) => {
          if (response?.data?.mfaExpired) {
            signOut();
          } else {
            const userLanguage =
              response?.data?.language ||
              (!!Cookies.get(LANGUAGE) && Cookies.get(LANGUAGE) !== 'undefined'
                ? Cookies.get(LANGUAGE)
                : window.navigator.language);

            const languagesAvailable = ['pt-BR', 'en-US', 'es-MX'];

            setUser(response?.data);
            setLanguage(
              languagesAvailable.includes(userLanguage) ? userLanguage : 'pt-BR'
            );

            Cookies.remove('DISCONNECTED_REASON', {
              domain: '.caf.io',
              path: '/',
            });

            return response?.data;
          }
        })
        .finally(() => setLoadingAuth(false)),
    [signOut]
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        signOut,
        refreshToken,
        isAuthenticated,
        isAdmin,
        getLoggedUser,
        loadingAuth,
        signIn,
        loadingSignIn,
        errorSignIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
