import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form, Input, Spin, Row, Col, Select, Divider } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';

import { useAuth } from 'hooks/auth';

import PageTitle from 'components/PageTitle';
import RectangleButton from 'components/Button/RectangleButton';
import { CardWrapper } from 'components/CardWrapper';

import Wrapper from 'pages/private/wrapper';
import {
  useProfileTemplate,
  PROFILE_RULES_INITIAL_VALUE,
  QSA_RULES_INITIAL_VALUE,
} from 'hooks/profileTemplate';

import mailTo from 'utils/mailToNewTemplate';
import { useOnboardingTemplate } from 'hooks/onboardingTemplate';
import moment from 'moment';
import Subheader from './components/Subheader';
import OnboardingServices from '../components/OnboardingServices';
import BackgroundChecking from '../components/BackgroundChecking';
import ComplianceRules from '../components/ComplianceRules';
import PriceWidget from '../components/PriceWidget';
import { productServices } from '../utils';

import './styles.less';

const { Option } = Select;

const I18N_BASE_PATH = 'pages.private.profileModels.profileModelUpdate';
const I18N_ALT_BASE_PATH = 'pages.private.profileModels.profileModelCreate';

const ProfileModelContent = () => {
  const { t: translate } = useTranslation();

  const {
    loadOnboardingTemplates,
    allOnboardingTemplates,
    loading: loadingOnboardingTemplates,
  } = useOnboardingTemplate();

  const [form] = Form.useForm();
  const { user } = useAuth();

  const {
    loadCafServicesList,
    loadingCafServicesList,
    cafServicesList,
    getProfileTemplateById,
    setProfileTemplate,
    summaryPrices,
    profileTemplate,
    setProduct,
    product,
    loadAllProfileTemplates,
    allRecursiveProfileTemplates,
    loadingAllProfileTemplates,
  } = useProfileTemplate();

  const { profileTemplateId } = useParams();
  const [query] = useQueryParams({
    type: StringParam,
  });
  const filteredTemplates = useMemo(
    () => allOnboardingTemplates?.filter((t) => t?.type === query?.type),
    [allOnboardingTemplates, query]
  );

  const [disabled, setDisabled] = useState(!!profileTemplateId);
  const [dataSourceServices, setDataSourceServices] = useState([]);
  const [onboardingServices, setOnboardingServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newValidations, setNewValidations] = useState([]);

  const profileRulesInitialValue = useMemo(() => {
    const data = PROFILE_RULES_INITIAL_VALUE(translate);
    return data;
  }, [translate]);

  const qsaRulesInitialValue = useMemo(() => {
    const data = QSA_RULES_INITIAL_VALUE(translate);
    return data;
  }, [translate]);

  const onFormValuesChange = useCallback(
    (e) => {
      if (
        e?.backgroundCheckingServices &&
        e?.backgroundCheckingServices[Object.keys(e?.backgroundCheckingServices)[0]]
      ) {
        setNewValidations(
          e?.backgroundCheckingServices[Object.keys(e?.backgroundCheckingServices)[0]]
            .relatedRules
        );
      } else if (
        e?.onboardingServices &&
        e?.onboardingServices[Object.keys(e?.onboardingServices)[0]]
      ) {
        setNewValidations(
          e?.onboardingServices[Object.keys(e?.onboardingServices)[0]].relatedRules
        );
      }

      setProfileTemplate({ ...form.getFieldsValue() });
    },
    [form, setProfileTemplate]
  );

  const fetch = useCallback(async () => {
    const defaultTemplate = await loadOnboardingTemplates();
    form.resetFields();
    setLoading(true);
    const [profileTemplateResponse, servicesResponse] = await Promise.all([
      profileTemplateId && getProfileTemplateById(profileTemplateId, false),
      loadCafServicesList(),
    ]);

    if (profileTemplateResponse) {
      const formValues = {
        type:
          profileTemplateResponse?.type === 'PF'
            ? translate(
                `${I18N_BASE_PATH}.index.fetch.profileTemplateResponse.formValues.value.person`
              )
            : translate(
                `${I18N_BASE_PATH}.index.fetch.profileTemplateResponse.formValues.value.company`
              ),
        alwaysManualReview: profileTemplateResponse?.extraSettings?.alwaysManualReview,
        name: profileTemplateResponse?.name,
        webhook: profileTemplateResponse?.webhook,
        profileWebhook: profileTemplateResponse?.profileWebhook,
        onboardingTemplateId:
          profileTemplateResponse?.onboardingTemplateId ||
          defaultTemplate[query?.type]?._id ||
          null,
      };
      // ['backgroundChecking', 'onboarding'].forEach((key) => {
      //   if (profileTemplateResponse[key]) {
      //     profileTemplateResponse[key].services = profileTemplateResponse[
      //       key
      //     ].services?.reduce((obj, item) => {
      //       const extraDataSource =
      //         servicesResponse?.docs?.find(({ source }) => source === item.source) || {};
      //       return { ...obj, [item.source]: { ...extraDataSource, ...item } };
      //     }, {});
      //   }
      // });
      const [bgcServices, onbServices] = ['backgroundChecking', 'onboarding'].map(
        (key) => {
          if (profileTemplateResponse[key]) {
            return profileTemplateResponse[key].services?.reduce((obj, item) => {
              const extraDataSource =
                servicesResponse?.docs?.find(({ source }) => source === item.source) ||
                {};
              return { ...obj, [item.source]: { ...extraDataSource, ...item } };
            }, {});
          }
          return {};
        }
      );

      let onbServicesProduct = [];
      if (profileTemplateResponse?.product) {
        const _product = servicesResponse?.docs?.find(
          (service) =>
            service?.productType === 'template' &&
            service?.source === profileTemplateResponse.product
        );
        setProduct(_product);

        onbServicesProduct = _product?.template?.onboarding?.services.reduce(
          (obj, item) => {
            const extraDataSource =
              servicesResponse?.docs?.find(({ source }) => source === item.source) || {};
            return { ...obj, [item.source]: { ...extraDataSource, ...item } };
          },
          {}
        );
      } else {
        setProduct(null);
      }
      const isProcessKeywords = (rule) =>
        !!profileTemplateResponse?.extraSettings?.processesWithKeywords &&
        rule === 'has_processes_keywords';

      formValues.backgroundCheckingServices = bgcServices;
      formValues.onboardingServices = { ...onbServices, ...onbServicesProduct };
      formValues.validations = [
        ...(profileTemplateResponse?.backgroundChecking?.validations || []),
        ...(profileTemplateResponse?.onboarding?.validations || []),
      ].map((validation) => ({
        ...validation,
        switchCustom:
          !!validation?.actionWhenPending ||
          !!validation?.extraSettings ||
          isProcessKeywords(validation?.rule),
        ...profileTemplateResponse?.extraSettings?.processesWithKeywords,
        category: validation?.category,
        cnaesType: validation?.extraSettings?.type ?? undefined,
        cnaes: validation?.extraSettings?.list ?? undefined,
        legalNatureType: validation?.extraSettings?.type ?? undefined,
        legalNature: validation?.extraSettings?.list ?? undefined,
        requiredFields: validation?.extraSettings?.requiredFields ?? undefined,
        limit: validation?.extraSettings?.limit ?? undefined,
        timeUnit: validation?.extraSettings?.timeUnit ?? undefined,
        cpfType: validation?.extraSettings?.type ?? undefined,
        cpf: validation?.extraSettings?.list ?? undefined,
        invalidRangeAgesList: validation?.extraSettings?.invalidRangeAges ?? undefined,
        documentTypes: validation?.extraSettings?.documentTypes ?? undefined,
        sendTypes: validation?.extraSettings?.sendTypes ?? undefined,
        parametersToCompare: validation?.extraSettings?.parametersToCompare ?? undefined,
        months: validation?.extraSettings?.months ?? undefined,
        belowScore: Number(validation?.extraSettings?.belowScore) ?? undefined,
        overScore: Number(validation?.extraSettings?.overScore) ?? undefined,
        minScore: Number(validation?.extraSettings?.minScore) ?? undefined,
        maxScore: Number(validation?.extraSettings?.maxScore) ?? undefined,
        onlyQsaCompatible: validation?.extraSettings?.onlyQsaCompatible || false,
        departmentsType: validation?.extraSettings?.type ?? undefined,
        departments: validation?.extraSettings?.list ?? undefined,
        sourcesType: validation?.extraSettings?.type ?? undefined,
        sources: validation?.extraSettings?.list ?? undefined,
        thresholds: validation?.extraSettings?.thresholds ?? undefined,
        rule: {
          key: validation.rule,
          label: validation.title,
          value: validation.rule,
        },
        enabled: true,
      }));

      if (profileTemplateResponse?.type === 'PF') {
        formValues.profileRules = profileTemplateResponse?.profileRules
          ?.filter(({ actionWhenInvalid }) => actionWhenInvalid !== 'IGNORE')
          ?.map((validation) => {
            const rule = profileRulesInitialValue?.find(
              (r) => r.rule === validation.rule
            );
            return {
              ...validation,
              switchCustom: !!validation?.actionWhenPending,
              ...rule,
              rule: {
                key: validation.rule,
                label: rule.title,
                value: validation.rule,
              },
              enabled: true,
            };
          });
      } else {
        formValues.profileRules = profileTemplateResponse?.profileRules
          ?.filter(({ actionWhenInvalid }) => actionWhenInvalid !== 'IGNORE')
          ?.map((validation) => {
            const rule = qsaRulesInitialValue?.find((r) => r.rule === validation.rule);
            return {
              ...validation,
              switchCustom: !!validation?.actionWhenPending,
              ...rule,
              rule: {
                key: validation.rule,
                label: rule?.title,
                value: validation.rule,
              },
              enabled: true,
            };
          });
      }

      formValues.qsaProfileTemplateId = profileTemplateResponse?.qsaProfileTemplateId;
      if (profileTemplateResponse?.qsaProfileTemplateId) {
        formValues.qsaSwitch = true;
      }

      form.setFieldsValue(formValues);
      setProfileTemplate(formValues);
    }

    setDataSourceServices(
      servicesResponse?.docs?.filter(({ category }) => category !== 'onboarding') || []
    );

    setOnboardingServices(
      servicesResponse?.docs?.filter(({ category }) => category === 'onboarding') || []
    );
    setLoading(false);
  }, [
    loadOnboardingTemplates,
    form,
    profileTemplateId,
    getProfileTemplateById,
    loadCafServicesList,
    translate,
    query,
    setProfileTemplate,
    setProduct,
    profileRulesInitialValue,
    qsaRulesInitialValue,
  ]);

  const firstLoad = useRef(true);

  useEffect(() => {
    if (!firstLoad.current) return;
    firstLoad.current = false;
    fetch();
  }, [fetch]);

  useEffect(() => {
    loadAllProfileTemplates({
      active: true,
      type: 'PF',
    });
  }, [loadAllProfileTemplates]);

  const chooseMail = useMemo(() => {
    const date = new Date();
    const subject = 'Solicitação de novo template de Onboarding Web.';
    const body = `Olá! Estou solicitando para o time a criação de um novo modelo de Onboarding Web. Conseguem entrar em contato para marcarmos uma call assim que der? \n \nDados técnicos:\n\tSolicitante: ${
      user?.username
    }\n\tCliente: ${user?.accountData?.companyName}\n\tModelo de consulta: ${
      profileTemplate?.name
    }\n\n\nSolicitação enviada no dia ${moment(date).format('DD')} de ${moment(
      date
    ).format('MMMM')} às ${moment(date).format('HH:mm')}`;
    if (process.env.REACT_APP_ENV !== 'dev') {
      return `${
        mailTo[process.env.REACT_APP_ENV]
      }?subject=${subject}&body=${encodeURIComponent(body)}`;
    }
    return `${user?.username}?subject=${subject}&body=${encodeURIComponent(body)}`;
  }, [profileTemplate, user]);

  return (
    <Wrapper hideMainHeader customClassName="gx-position-relative">
      <CardWrapper hasPadding>
        <Subheader
          formRef={form}
          customData={cafServicesList?.docs || []}
          loading={loading || loadingOnboardingTemplates}
          disabled={disabled}
          setDisabled={setDisabled}
          fetch={fetch}
          type={query?.type}
        />
        {loading || loadingOnboardingTemplates || loadingCafServicesList ? (
          <div className="custom-loading-container">
            <Spin className="mrg-top-30" />
          </div>
        ) : (
          <div id="profile-model-content" className="mrg-btm-100">
            <Form
              form={form}
              layout="vertical"
              className={`${disabled ? 'disabled' : ''}`}
              onValuesChange={onFormValuesChange}
              // initialValues={{
              //   type: query.type,
              //   services: {},
              // profileRules: profileRulesInitialValue,
              // }}
            >
              {/* Geral */}
              <section className="profile-model-content-header gx-w-100">
                <div className="flex-1">
                  <PageTitle
                    title={translate(
                      `${I18N_BASE_PATH}.index.profileModelUpdate.pageTitle.title`
                    )}
                    subtitle={translate(
                      `${I18N_BASE_PATH}.index.profileModelUpdate.pageTitle.subtitle`
                    )}
                  />
                  <Row className="profile-model-basic-info" style={{ maxWidth: 680 }}>
                    <Col span={14}>
                      <Form.Item
                        name="name"
                        label={translate(
                          `${I18N_BASE_PATH}.index.profileModelUpdate.form.name.label`
                        )}
                        rules={[{ required: true }]}
                      >
                        <Input disabled={disabled} />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name="onboardingTemplateId"
                        label={translate(
                          `${I18N_BASE_PATH}.index.profileModelUpdate.form.onboardingTemplate.label`
                        )}
                      >
                        {filteredTemplates?.length > 0 ? (
                          <Select
                            disabled={disabled}
                            placeholder={translate(
                              `${I18N_ALT_BASE_PATH}.starterCustomModel.starterCustomModelModal.form.input.name.placeholder`
                            )}
                            dropdownRender={(menu) => (
                              <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <a
                                  href={`mailto:${chooseMail}`}
                                  style={{
                                    marginLeft: '12px',
                                    lineHeight: '2',
                                  }}
                                >
                                  <i className="caf-ic_add mrg-right-5" />
                                  {translate(
                                    `${I18N_ALT_BASE_PATH}.starterCustomModel.starterCustomModelModal.form.input.onboardingTemplateSelector.label`
                                  )}
                                </a>
                              </div>
                            )}
                          >
                            {filteredTemplates
                              ?.filter((t) => t?.type === query?.type)
                              .map((template) => (
                                <Option key={template?._id} value={template?._id}>
                                  {template?.favicon && (
                                    <img
                                      style={{
                                        width: 20,
                                        height: 20,
                                        objectFit: 'contain',
                                        marginRight: 5,
                                      }}
                                      src={template?.favicon}
                                      alt=""
                                    />
                                  )}
                                  {template?.name || template?.description}
                                </Option>
                              ))}
                          </Select>
                        ) : (
                          <div>
                            <a
                              disabled={disabled}
                              href={`mailto:${chooseMail}`}
                              style={{
                                marginLeft: '12px',
                                lineHeight: '2',
                              }}
                            >
                              <i className="caf-ic_add mrg-right-5" />
                              {translate(
                                `${I18N_ALT_BASE_PATH}.starterCustomModel.starterCustomModelModal.form.input.onboardingTemplateSelector.label`
                              )}
                            </a>
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="webhook"
                        label={translate(
                          `${I18N_BASE_PATH}.index.profileModelUpdate.form.webhook.label`
                        )}
                      >
                        <Input disabled={disabled} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="profileWebhook"
                        label={translate(
                          `${I18N_BASE_PATH}.index.profileModelUpdate.form.profileWebhook.label`
                        )}
                      >
                        <Input disabled={disabled} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <PriceWidget
                  pricePerProfile={summaryPrices?.pricePerProfile}
                  pricePerMonth={summaryPrices?.pricePerMonth}
                  hideInfos={false}
                />
              </section>

              {/* Produto */}
              <div className="mrg-btm-20">
                <Row align="middle" className="no-mrg mrg-top-40">
                  <Col flex={1}>
                    <PageTitle
                      title={translate(
                        `${I18N_BASE_PATH}.index.profileModelUpdate.product.pageTitle.title`
                      )}
                    />
                  </Col>
                </Row>
                {product && (
                  <Row gutter={[16, 14]}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                      <div className="height-100">
                        <RectangleButton
                          title={product.title}
                          description={product.description}
                          icon={productServices[product.source]?.icon || 'caf-ic_hash'}
                          selected
                          disabled
                          orientation="horizontal"
                          price={product.price}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {!product && (
                  <Row gutter={[16, 14]}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                      <div className="height-100">
                        <RectangleButton
                          title={translate(
                            `${I18N_ALT_BASE_PATH}.starterFlow.index.customProduct.title`
                          )}
                          description={translate(
                            `${I18N_ALT_BASE_PATH}.starterFlow.index.customProduct.description`
                          )}
                          icon="caf-ic_settings"
                          selected
                          disabled
                          orientation="horizontal"
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </div>

              {/* Serviços */}
              {query?.type === 'PF' && (
                <>
                  <Row align="middle" className="no-mrg mrg-top-40">
                    <Col flex={1}>
                      <PageTitle
                        title={translate(
                          `${I18N_BASE_PATH}.index.profileModelUpdate.identityAnalysis.pageTitle.title`
                        )}
                        subtitle={translate(
                          `${I18N_BASE_PATH}.index.profileModelUpdate.identityAnalysis.pageTitle.subtitle`
                        )}
                      />
                    </Col>
                  </Row>
                  <OnboardingServices
                    services={onboardingServices}
                    disabled={disabled}
                    formRef={form}
                    // onValuesChangeHandler={onValuesChangeHandler}
                    // formValues={formValues}
                    onValuesChange={onFormValuesChange}
                  />
                </>
              )}

              {/* Fontes de dados & Recorrência */}
              <>
                <Row align="middle" className="no-mrg mrg-top-40">
                  <Col flex={1}>
                    <PageTitle
                      title={translate(
                        `${I18N_BASE_PATH}.index.profileModelUpdate.backgroundChecking.pageTitle.title`
                      )}
                      subtitle={translate(
                        `${I18N_BASE_PATH}.index.profileModelUpdate.backgroundChecking.pageTitle.subtitle`
                      )}
                    />
                  </Col>
                </Row>
                <BackgroundChecking
                  profileType={query.type}
                  dataSources={dataSourceServices}
                  // checkedDataSources={calculatedPrice?.checkedDataSources || []}
                  disabled={disabled}
                  formRef={form}
                  onValuesChange={onFormValuesChange}
                  // onValuesChangeHandler={onValuesChangeHandler}
                />
              </>

              {/* Regras de compliance */}
              <>
                <Row align="middle" className="no-mrg mrg-top-40">
                  <Col span={12}>
                    <PageTitle
                      title={translate(
                        `${I18N_BASE_PATH}.index.profileModelUpdate.complianceRules.pageTitle.title`
                      )}
                      subtitle={translate(
                        `${I18N_BASE_PATH}.index.profileModelUpdate.complianceRules.pageTitle.subtitle`
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <Form form={form} layout="inline" onValuesChange={onFormValuesChange}>
                      <Form.Item
                        name="alwaysManualReview"
                        label={translate(
                          `${I18N_BASE_PATH}.index.profileModelUpdate.complianceRules.manualButton.title`
                        )}
                        margin={5}
                      >
                        <Select
                          disabled={disabled}
                          defaultValue={translate(
                            `${I18N_BASE_PATH}.index.profileModelUpdate.complianceRules.manualButton.false`
                          )}
                        >
                          <Option value>
                            {translate(
                              `${I18N_BASE_PATH}.index.profileModelUpdate.complianceRules.manualButton.true`
                            )}
                          </Option>
                          <Option value={false}>
                            {translate(
                              `${I18N_BASE_PATH}.index.profileModelUpdate.complianceRules.manualButton.false`
                            )}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
                <ComplianceRules
                  profilePfTemplates={allRecursiveProfileTemplates}
                  loadingAllProfileTemplates={loadingAllProfileTemplates}
                  newValidations={newValidations}
                  profileType={query.type}
                  disabled={disabled}
                  formRef={form}
                  onValuesChange={onFormValuesChange}
                  isEdit
                />
              </>
            </Form>
          </div>
        )}
      </CardWrapper>
    </Wrapper>
  );
};

export default ProfileModelContent;
