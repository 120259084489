/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import { Divider, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks/auth';
import { useExecution } from 'hooks/execution';
import { useProfileTemplate } from 'hooks/profileTemplate';
import { useReports } from 'hooks/reports';
import { useWorkflow } from 'hooks/useWorkflow';

// import EmptyMessage from 'components/EmptyMessage';

import CheckpointModel from 'pages/private/Executions/components/CheckpointModel';
import ProfileWidget from 'pages/private/Profiles/components/ProfileWidget';

// import servicesCategories from 'utils/data/templateServicesCategories.json';

// import TrustItem from './components/TrustItem';
import ValidationsTimeline from './components/ValidationsTimeline';

const ExecutionValidations = ({ toPrint }) => {
  const { t: translate } = useTranslation();

  const { execution, loadingExecution: loading, routine } = useExecution();
  const { allReportsObject, loadAllReports } = useReports();

  const { loadProfileTemplates, allProfileTemplates } = useProfileTemplate();
  const { data: allWorkflows, getWorkflowsData } = useWorkflow();
  const { user } = useAuth();

  const backgroundCheckingDetails = useMemo(() => {
    if (!execution) return undefined;

    const _validations = execution?.validations;

    if (!_validations?.length) return undefined;

    const rule = _validations.find((v) => v.rule === 'valid_background_checking');

    if (!rule) return undefined;
    return rule?.data?.backgroundChecking || [];
  }, [execution]);

  useEffect(() => {
    loadAllReports();
    getWorkflowsData({ tenantId: user?.tenantId, _limit: 1000 });
    loadProfileTemplates({});
  }, [loadAllReports, getWorkflowsData, user, loadProfileTemplates]);

  const checkpointData = useMemo(() => {
    if (
      !!execution?.report &&
      execution?.report?.id !== `000000000000000000000000` &&
      execution?.report !== `000000000000000000000000`
    ) {
      return {
        title: translate(
          'pages.private.executions.components.executionValidations.index.checkpointData.title.hasReportId'
        ),
        description:
          execution?.report?.name ||
          allReportsObject[execution?.report?._id]?.name ||
          allReportsObject[execution?.report]?.name ||
          translate(
            'pages.private.executions.components.executionValidations.index.checkpointData.description.hasReportId'
          ),
      };
    }

    if (execution?.templateName) {
      return {
        title: translate(
          'pages.private.executions.components.executionValidations.index.checkpointData.title.hasTemplateId'
        ),
        description: execution.templateName,
      };
    }

    if (execution?.templateId && allProfileTemplates?.length > 0) {
      const template = allProfileTemplates?.find((t) => t._id === execution?.templateId);

      if (template) {
        return {
          title: translate(
            'pages.private.executions.components.executionValidations.index.checkpointData.title.hasTemplateId'
          ),
          description: template.name,
        };
      }
    }

    if (
      (execution?.workflowId || execution?.metadata?.workflowId) &&
      allWorkflows?.docs?.length
    ) {
      const workflow = allWorkflows.docs.find(
        (workflow) => workflow._id === execution?.workflowId
      );

      if (execution?.isRedirected) {
        return {
          title: translate(
            'pages.private.executions.components.executionValidations.index.checkpointData.title.hasTemplateId'
          ),
          description: workflow?.definition?.name,
        };
      }

      return {
        title: translate(
          'pages.private.executions.components.executionValidations.index.checkpointData.title.hasWorkflowName'
        ),
        description: workflow?.definition?.name,
      };
    }

    if (routine?.name) {
      return {
        title: translate(
          'pages.private.executions.components.executionValidations.index.checkpointData.title.hasRoutineName'
        ),
        description: routine?.name || '-',
      };
    }

    return {
      title: translate(
        'pages.private.executions.components.executionValidations.index.checkpointData.title.default'
      ),
      description: translate(
        'pages.private.executions.components.executionValidations.index.checkpointData.description.default'
      ),
    };
  }, [
    execution,
    allProfileTemplates,
    allReportsObject,
    translate,
    routine,
    allWorkflows,
  ]);

  return (
    <ProfileWidget
      title={translate(
        'pages.private.executions.components.executionValidations.index.executionValidations.profileWidget.title'
      )}
      subtitle={translate(
        'pages.private.executions.components.executionValidations.index.executionValidations.profileWidget.subtitle'
      )}
      icon="caf-ic_check_d"
      customClassName={
        !loading && backgroundCheckingDetails?.length > 0 ? 'reset-border-top' : ''
      }
    >
      <div className="identity-info">
        {!loading && (
          <ValidationsTimeline
            validations={execution?.mappedValidations?.filter(
              (x) => x.status && x.status.toUpperCase() !== 'DISABLED'
            )}
            status={execution?.workflowId ? execution?.status : null}
          />
        )}

        {loading && (
          <div className="custom-loading-spin trust-validations">
            <Spin />
          </div>
        )}
      </div>

      {!loading && (
        <footer>
          <CheckpointModel
            title={checkpointData?.title}
            description={checkpointData?.description}
          />
        </footer>
      )}

      {toPrint && <Divider className="custom-divider" />}
    </ProfileWidget>
  );
};

export default ExecutionValidations;
