import React, { useCallback, useMemo } from 'react';
import EmptyExecutionState from 'components/EmptyExecutionState';
import { useTranslation } from 'react-i18next';
import { ThumpsUp, ThumpsUp1, DollarSign } from '@combateafraude/icons/general';
import { Row, Col, Table, Card, Tag } from 'antd';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import { labelStatus } from 'utils/handlerStatus';
import DottedLabel from 'components/DottedLabel';
import NotAvailable from 'components/NotAvailable';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ScoreIndicator from '../../../Components/ScoreIndicator';

import './styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.financialInformation.people.components';

const CreditScore = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const data = useMemo(() => source.data, [source]);
  const statusCode = useMemo(
    () =>
      source?.data?.statusCode ||
      source?.statusCode ||
      source?.data?.code ||
      source?.code,
    [source]
  );
  const renderTags = useCallback(
    (score) => {
      if (score === 'A' || score === 'B') {
        return <Tag className="good-tag">{translate(`${I18N_BASE_PATH}.good`)}</Tag>;
      }
      if (score === 'C') {
        return (
          <Tag className="regular-tag">{translate(`${I18N_BASE_PATH}.regular`)}</Tag>
        );
      }
      return <Tag className="bad-tag">{translate(`${I18N_BASE_PATH}.bad`)}</Tag>;
    },
    [translate]
  );

  return (
    <DataSourceCardCollapsePanel
      customIcon={
        <div className="div-icon">
          <DollarSign color="#004af7" />
        </div>
      }
      title={translate(`${I18N_BASE_PATH}.title`)}
      toPrint={toPrint}
    >
      {statusCode !== '01' || !source?.data ? (
        <EmptyExecutionState
          statusCode={statusCode}
          message={source?.message || source?.data?.message}
        />
      ) : (
        <div id="credit-score-pf">
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.consumerStatus`)}</h3>
            <div className="flex row text-dark">
              <div className="mrg-right-10 font-size-20">
                {data?.decision?.aprove.includes('APROVA') ||
                data?.consumerStatus?.message.includes(
                  'CONSUMIDOR PARTICIPANTE DO CADASTRO POSITIVO'
                ) ? (
                  <ThumpsUp />
                ) : (
                  <ThumpsUp1 />
                )}
              </div>
              {data?.consumerStatus?.message}
            </div>
          </div>
          <div className="mrg-btm-30">
            <h3>{translate(`${I18N_BASE_PATH}.score`)}</h3>
            <ScoreIndicator
              source={{
                data: {
                  score: data?.scores[0]?.score,
                  scoreMessage: data?.scores[0]?.scoreMessage,
                },
              }}
            />
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.positiveIndicators`)}</h3>
            <Row>
              <Col span={12}>
                <Card className="height-100">
                  <h4>{translate(`${I18N_BASE_PATH}.paymentBehavior`)}</h4>
                  <h5>{translate(`${I18N_BASE_PATH}.invoicesOnTheBack`)}</h5>
                  <div className="flex-row start-center mrg-vertical-15">
                    <h1>
                      {data?.positiveControlPanel?.positivePaymentBehaviorIndicators
                        ?.lateInvoiceBehaviorScore?.score?.length === 1
                        ? data?.positiveControlPanel?.positivePaymentBehaviorIndicators
                            ?.lateInvoiceBehaviorScore?.score
                        : '-'}
                    </h1>
                    <div className="mrg-left-10">
                      {!!data?.positiveControlPanel?.positivePaymentBehaviorIndicators
                        ?.lateInvoiceBehaviorScore?.score?.length === 1 &&
                        renderTags(
                          data?.positiveControlPanel?.positivePaymentBehaviorIndicators
                            ?.lateInvoiceBehaviorScore?.score
                        )}
                    </div>
                  </div>
                  <span>{translate(`${I18N_BASE_PATH}.maxDaysOverdue`)}</span>
                </Card>
              </Col>
              <Col span={12}>
                <Card className="height-100">
                  <h4>{translate(`${I18N_BASE_PATH}.consumptionBehavior`)}</h4>
                  <h5>{translate(`${I18N_BASE_PATH}.recentContracts`)}</h5>
                  <div className="flex-row start-center mrg-vertical-15">
                    <h1>
                      {data?.positiveControlPanel?.positiveConsumptionBehaviorIndicators
                        ?.recentContractBehaviorScore?.score?.length === 1
                        ? data?.positiveControlPanel
                            ?.positiveConsumptionBehaviorIndicators
                            ?.recentContractBehaviorScore?.score
                        : '-'}
                    </h1>
                    <div className="mrg-left-10">
                      {!!data?.positiveControlPanel?.positiveConsumptionBehaviorIndicators
                        ?.recentContractBehaviorScore?.score?.length === 1 &&
                        renderTags(
                          data?.positiveControlPanel
                            ?.positiveConsumptionBehaviorIndicators
                            ?.recentContractBehaviorScore?.score
                        )}
                    </div>
                  </div>
                  <span>{translate(`${I18N_BASE_PATH}.creditCommitmentLevel`)}</span>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.identification`)}</h3>
            <Row>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.name`)}
                  value={data?.identification?.name || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.cpf`)}
                  value={data?.identification?.document || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.cpfStatus`)}
                  value={
                    <DottedLabel
                      description={data?.identification?.revenueStatus || '-'}
                      type={
                        data?.identification?.revenueStatus &&
                        labelStatus(data?.identification?.revenueStatus).type
                      }
                    />
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.nationality`)}
                  value={data?.identification?.nationality || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.cpfRegion`)}
                  value={data?.identification?.cpfRegion || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.adultStatus`)}
                  value={
                    data?.identification?.message ===
                    'TITULAR DO CPF NÃO TEM 18 ANOS COMPLETOS.'
                      ? translate(`${I18N_BASE_PATH}.no`)
                      : translate(`${I18N_BASE_PATH}.yes`)
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.gender`)}
                  value={data?.identification?.gender || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.birthDate`)}
                  value={data?.identification?.dateOfBirth || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.dependents`)}
                  value={data?.identification?.numberOfDependents || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.maritalStatus`)}
                  value={data?.identification?.maritalStatus || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.voterRegistration`)}
                  value={data?.identification?.voterRegistration || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.educationLevel`)}
                  value={data?.identification?.educationLevel || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.updateDate`)}
                  value={data?.identification?.updateDate || <NotAvailable />}
                  span={24}
                />
              </Col>
            </Row>
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.income`)}</h3>
            <span>{translate(`${I18N_BASE_PATH}.estimatedIncome`)}</span>
            <Row className="mrg-top-20">
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.monthlyIncome`)}
                  value={data?.scores[1]?.presumedIncomeRange || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.class`)}
                  value={data?.scores[1]?.numericalClassification || <NotAvailable />}
                  span={24}
                />
              </Col>
            </Row>
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.location`)}</h3>
            <Row>
              <Col span={16}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.address`)}
                  value={
                    data?.location?.streetName ??
                    `${data?.location?.streetType || ''} ${data?.location?.streetName}, ${
                      data?.location?.streetNumber || ''
                    }`
                  }
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.neighborhood`)}
                  value={data?.location?.neighborhood || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.city`)}
                  value={data?.location?.city || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.state`)}
                  value={data?.location?.state || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.postalCode`)}
                  value={data?.location?.postalCode || <NotAvailable />}
                  span={24}
                />
              </Col>
              <Col span={8}>
                <ColumnTitleValue
                  title={translate(`${I18N_BASE_PATH}.phone`)}
                  value={`${data?.location?.areaCode1 || ''} ${
                    data?.location?.phoneNumber1 || ''
                  }`}
                  span={24}
                />
              </Col>
            </Row>
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.previousInquiries`)}</h3>
            <Table
              columns={[
                {
                  title: translate(`${I18N_BASE_PATH}.occurrenceType`),
                  dataIndex: 'occurrenceType',
                  key: 'occurrenceType',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.date`),
                  dataIndex: 'date',
                  key: 'date',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.amount`),
                  dataIndex: 'amount',
                  key: 'amount',
                  render: (_, record) =>
                    record?.amount ? (
                      `${record?.currency || ''} ${record?.amount}`
                    ) : (
                      <NotAvailable />
                    ),
                },
              ]}
              pagination={{ hideOnSinglePage: true }}
              dataSource={data?.previousInquiries}
            />
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.debtRecords`)}</h3>
            <Table
              columns={[
                {
                  title: translate(`${I18N_BASE_PATH}.occurrenceType`),
                  dataIndex: 'occurrenceType',
                  key: 'occurrenceType',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.date`),
                  dataIndex: 'occurrenceDate',
                  key: 'occurrenceDate',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.amount`),
                  dataIndex: 'amount',
                  key: 'amount',
                  render: (_, record) =>
                    record?.amount ? (
                      `${record?.currency || ''} ${record?.amount}`
                    ) : (
                      <NotAvailable />
                    ),
                },
              ]}
              pagination={{ hideOnSinglePage: true }}
              dataSource={data?.debts}
            />
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.civilActions`)}</h3>
            <Table
              columns={[
                {
                  title: translate(`${I18N_BASE_PATH}.action`),
                  dataIndex: 'civilAction',
                  key: 'civilAction',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.plaintiff`),
                  dataIndex: 'plaintiff',
                  key: 'plaintiff',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.date`),
                  dataIndex: 'distributionDate',
                  key: 'distributionDate',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.amount`),
                  dataIndex: 'amount',
                  key: 'amount',
                  render: (_, record) =>
                    record?.amount ? (
                      `${record?.currency || ''} ${record?.amount}`
                    ) : (
                      <NotAvailable />
                    ),
                },
              ]}
              pagination={{ hideOnSinglePage: true }}
              dataSource={data?.civilActionsList}
            />
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.bankruptcies`)}</h3>
            <Table
              columns={[
                {
                  title: translate(`${I18N_BASE_PATH}.companyName`),
                  dataIndex: 'companyName',
                  key: 'companyName',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.documentNumber`),
                  dataIndex: 'documentNumber',
                  key: 'documentNumber',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.date`),
                  dataIndex: 'occurrenceDate',
                  key: 'occurrenceDate',
                },
              ]}
              pagination={{ hideOnSinglePage: true }}
              dataSource={data?.bankruptcyJudicialRecoveryRelationship}
            />
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.companyShares`)}</h3>
            <Table
              columns={[
                {
                  title: translate(`${I18N_BASE_PATH}.documentNumber`),
                  dataIndex: 'documentNumberA',
                  key: 'documentNumberA',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.companyName`),
                  dataIndex: 'companyName',
                  key: 'companyName',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.role`),
                  dataIndex: 'role',
                  key: 'role',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.participation`),
                  dataIndex: 'percentageValue',
                  key: 'percentageValue',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.entryDate`),
                  dataIndex: 'entryDate',
                  key: 'entryDate',
                },
              ]}
              pagination={{ hideOnSinglePage: true }}
              dataSource={data?.participationsOfConsultedDocument}
            />
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.cancelledChecks`)}</h3>
            <Table
              columns={[
                {
                  title: translate(`${I18N_BASE_PATH}.bank`),
                  dataIndex: 'bank',
                  key: 'bank',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.amount`),
                  dataIndex: 'amount',
                  key: 'amount',
                  render: (_, record) =>
                    record?.amount ? (
                      `${record?.currency || ''} ${record?.amount}`
                    ) : (
                      <NotAvailable />
                    ),
                },
                {
                  title: translate(`${I18N_BASE_PATH}.date`),
                  dataIndex: 'occurrenceDate',
                  key: 'occurrenceDate',
                },
              ]}
              pagination={{ hideOnSinglePage: true }}
              dataSource={data?.checksCancelledReason21}
            />
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.userReportedReturns`)}</h3>
            <Table
              columns={[
                {
                  title: translate(`${I18N_BASE_PATH}.bank`),
                  dataIndex: 'bank',
                  key: 'bank',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.amount`),
                  dataIndex: 'amount',
                  key: 'amount',
                  render: (_, record) =>
                    record?.amount ? (
                      `${record?.currency || ''} ${record?.amount}`
                    ) : (
                      <NotAvailable />
                    ),
                },
                {
                  title: translate(`${I18N_BASE_PATH}.date`),
                  dataIndex: 'occurrenceDate',
                  key: 'occurrenceDate',
                },
              ]}
              pagination={{ hideOnSinglePage: true }}
              dataSource={data?.userReportedReturns}
            />
          </div>
          <div className="mrg-vertical-30">
            <h3>{translate(`${I18N_BASE_PATH}.protests`)}</h3>
            <Table
              columns={[
                {
                  title: translate(`${I18N_BASE_PATH}.occurrenceType`),
                  dataIndex: 'occurrenceType',
                  key: 'occurrenceType',
                },
                {
                  title: translate(`${I18N_BASE_PATH}.amount`),
                  dataIndex: 'amount',
                  key: 'amount',
                  render: (_, record) =>
                    record?.amount ? (
                      `${record?.currency || ''} ${record?.amount}`
                    ) : (
                      <NotAvailable />
                    ),
                },
                {
                  title: translate(`${I18N_BASE_PATH}.date`),
                  dataIndex: 'occurrenceDate',
                  key: 'occurrenceDate',
                },
              ]}
              pagination={{ hideOnSinglePage: true }}
              dataSource={data?.protestedBills}
            />
          </div>
        </div>
      )}
    </DataSourceCardCollapsePanel>
  );
};
export default CreditScore;
