import { Col, Row, Divider, Alert } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DottedLabel from 'components/DottedLabel';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';
import ColumnLabel from 'pages/private/Profiles/components/TableInfoCard/ColumnLabel';

import VehiclesRestrictionsExtraInfosCollapsePanel from './VehiclesRestrictionsExtraInfosCollapsePanel';

import '../styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.denatranVehicleInfos';

function VehicleRestrictionsInfosCollapsePanel({ restrictions = {}, toPrint = false }) {
  const { t: translate } = useTranslation();

  const restrictionsDetails = useMemo(() => restrictions, [restrictions]);

  const customHeader = useMemo(() => {
    return (
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col span={18}>
          <i className="caf-ic_driver" />
          <span className="text-dark text-bold pdd-left-10">
            {translate(`${I18N_BASE_PATH}.restrictionsTitle`).toUpperCase()}
          </span>
        </Col>
      </Row>
    );
  }, [translate]);

  const hasPendingValues = useMemo(() => {
    const { ipva, dpvat, fines, licensing } = restrictionsDetails;

    return [
      {
        title: translate(`${I18N_BASE_PATH}.columns.ipva`),
        value: `R$ ${ipva?.pendingValue}`,
      },
      {
        title: translate(`${I18N_BASE_PATH}.columns.dpvat`),
        value: `R$ ${dpvat?.pendingValue}`,
      },
      {
        title: translate(`${I18N_BASE_PATH}.columns.fines`),
        value: `R$ ${fines?.pendingValue}`,
      },
      {
        title: translate(`${I18N_BASE_PATH}.columns.licensing`),
        value: `R$ ${licensing?.pendingValue}`,
      },
    ];
  }, [restrictionsDetails, translate]);

  const finacingInfo = useMemo(() => {
    if (!restrictionsDetails?.financing) return null;

    return {
      ...restrictionsDetails?.financing,
      infoType: 'financing',
    };
  }, [restrictionsDetails]);

  const renajudInfo = useMemo(() => {
    if (!restrictionsDetails?.renajud) return null;

    return {
      ...restrictionsDetails?.renajud,
      infoType: 'renajud',
    };
  }, [restrictionsDetails]);

  return (
    <DataSourceCardCollapsePanel customHeader={customHeader} toPrint={toPrint}>
      <Row
        className="mrg-top-0 pdd-left-15 pdd-right-15"
        align="middle"
        justify="space-between"
      >
        <Col className="text-dark text-bold">
          <i className="caf-ic_law mrg-left-10 mrg-right-25" />
          <span>{translate(`${I18N_BASE_PATH}.columns.generalRestriction`)}</span>
        </Col>
        <Col>
          {restrictionsDetails?.hasGeneralRestriction &&
          restrictionsDetails?.hasGeneralRestriction !== '0' ? (
            <DottedLabel
              description={translate(`${I18N_BASE_PATH}.hasRestrictions`)}
              type="danger"
            />
          ) : (
            <DottedLabel
              description={translate(`${I18N_BASE_PATH}.hasNoRestrictions`)}
              type="primary"
            />
          )}
        </Col>
      </Row>
      <Divider dashed />
      <Row
        className="mrg-top-0 pdd-left-15 pdd-right-15"
        align="middle"
        justify="space-between"
      >
        <Col className="text-dark text-bold">
          <i className="caf-ic_law mrg-left-10 mrg-right-25" />
          {translate(`${I18N_BASE_PATH}.columns.financialRestriction`)}
        </Col>
        <Col>
          {restrictionsDetails?.hasFinancialRestriction &&
          restrictionsDetails?.hasFinancialRestriction !== '0' ? (
            <DottedLabel
              description={translate(`${I18N_BASE_PATH}.hasRestrictions`)}
              type="danger"
            />
          ) : (
            <DottedLabel
              description={translate(`${I18N_BASE_PATH}.hasNoRestrictions`)}
              type="primary"
            />
          )}
        </Col>
      </Row>
      <Divider dashed />
      <Row
        className="mrg-top-0 pdd-left-15 pdd-right-15"
        align="middle"
        justify="space-between"
      >
        <Col className="text-dark text-bold">
          <i className="caf-ic_law mrg-left-10 mrg-right-25" />
          {translate(`${I18N_BASE_PATH}.columns.renajudRestriction`)}
        </Col>
        <Col>
          {restrictionsDetails?.hasRenajudRestriction &&
          restrictionsDetails?.hasRenajudRestriction !== '0' ? (
            <DottedLabel
              description={translate(`${I18N_BASE_PATH}.hasRestrictions`)}
              type="danger"
            />
          ) : (
            <DottedLabel
              description={translate(`${I18N_BASE_PATH}.hasNoRestrictions`)}
              type="primary"
            />
          )}
        </Col>
      </Row>
      <Divider dashed />
      <Row
        className="mrg-top-0 pdd-left-15 pdd-right-15"
        align="middle"
        justify="space-between"
      >
        <Col className="text-dark text-bold">
          <i className="caf-ic_law mrg-left-10 mrg-right-25" />
          {translate(`${I18N_BASE_PATH}.columns.theftRegister`)}
        </Col>
        <Col>
          {restrictionsDetails?.hasTheftRegister &&
          restrictionsDetails?.hasTheftRegister !== '0' ? (
            <DottedLabel
              description={translate(`${I18N_BASE_PATH}.hasRestrictions`)}
              type="danger"
            />
          ) : (
            <DottedLabel
              description={translate(`${I18N_BASE_PATH}.hasNoRestrictions`)}
              type="primary"
            />
          )}
        </Col>
      </Row>
      <Divider dashed />
      <Row
        className="mrg-top-0 pdd-left-15 pdd-right-15"
        align="middle"
        justify="space-between"
      >
        <Col className="text-dark text-bold">
          <i className="caf-ic_law mrg-left-10 mrg-right-25" />
          {translate(`${I18N_BASE_PATH}.columns.decommissionedVehicle`)}
        </Col>
        <Col>
          {restrictionsDetails?.decommissionedVehicle &&
          restrictionsDetails?.decommissionedVehicle !== '0' ? (
            <DottedLabel
              description={translate(`${I18N_BASE_PATH}.hasRestrictions`)}
              type="danger"
            />
          ) : (
            <DottedLabel
              description={translate(`${I18N_BASE_PATH}.hasNoRestrictions`)}
              type="primary"
            />
          )}
        </Col>
      </Row>
      <Divider />

      {restrictionsDetails?.messages?.length && (
        <>
          {restrictionsDetails?.messages.map((message, index) => (
            <Alert key={index} message={message?.message} type="warning" showIcon />
          ))}
        </>
      )}

      <Divider />
      <Row align="middle" justify="space-around">
        {hasPendingValues?.map((restriction) => (
          <ColumnTitleValue
            span={4}
            key={restriction?.title}
            title={restriction?.title}
            value={restriction?.value || '-'}
          />
        ))}
      </Row>

      {finacingInfo && (
        <>
          <Divider />
          <VehiclesRestrictionsExtraInfosCollapsePanel
            restrictionsExtraInfo={finacingInfo}
          />
        </>
      )}

      {renajudInfo && (
        <VehiclesRestrictionsExtraInfosCollapsePanel
          restrictionsExtraInfo={renajudInfo}
        />
      )}
    </DataSourceCardCollapsePanel>
  );
}

export default VehicleRestrictionsInfosCollapsePanel;
