/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Popover, Form, Tooltip, Tag } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import FeatureFlagWrapper from 'components/FeatureFlagWrapper';
import Checkbox from 'components/Form/Checkbox';
// import currency from 'utils/currencyFormatter';

import PermissionWrapper from 'routes/PermissionWrapper';
import { useProfileTemplate } from 'hooks/profileTemplate';
import { usePopoverRemoveValidations } from '../PopoverRemoveValidations';

const { Text } = Typography;

const newSources = [
  'pf_cpf_data',
  'people_compliance_bet',
  'pf_phones_alt',
  'pf_legal_records_extended',
  'pj_legal_records_extended',
  'pf_legal_records_extended_keywords',
  'pj_legal_records_extended_keywords',
];

const I18N_BASE_PATH =
  'pages.private.profileModels.components.backgroundChecking.renderPanelContentRow.renderPanelContentRow';
const RenderPanelContentRow = ({
  formRef,
  allServices,
  service,
  disabled,
  onChange,
  mode,
}) => {
  const { t: translate } = useTranslation();
  const { i18nNumberToFormattedString } = useI18nFormatters();
  const { serviceIsOnProduct } = useProfileTemplate();

  const [checked, setChecked] = useState(false);

  const {
    open: openRemoveValidationPopover,
    content: removeValidationPopoverContent,
    isVisible,
  } = usePopoverRemoveValidations();

  const renderDescription = useCallback(
    (description) => {
      const sanitizedDescription = description?.trim();
      if (!sanitizedDescription.length || description === 'desc')
        return translate(`${I18N_BASE_PATH}.descriptionNotAvailable`);
      return description;
    },
    [translate]
  );

  const onCheckboxChangeHandler = useCallback(
    (value) => {
      setChecked(value?.target?.checked);

      const data = value?.target?.checked
        ? {
            [service.source]: {
              ...service,
              frequency: 'NONE',
              onProfileCreation: true,
            },
          }
        : {
            [service.source]: undefined,
          };

      if (onChange) onChange(data);
    },
    [onChange, service]
  );

  const onCheckboxClick = useCallback(
    (e) => {
      if (e.target.checked === undefined) return;

      if (!e.target.checked) {
        openRemoveValidationPopover(e, { allServices, service, formRef }, () => {
          onCheckboxChangeHandler({ target: { checked: false } });
        });
      }
    },
    [allServices, formRef, onCheckboxChangeHandler, openRemoveValidationPopover, service]
  );

  useEffect(() => {
    const source = formRef.getFieldValue(['backgroundCheckingServices', service.source]);
    if (source) setChecked(true);
  }, [formRef, service]);

  return (
    <Row key={service.source} className="data-source-checkbox">
      <Col span={mode === 'edit' ? 12 : 24} className="column-checkbox">
        <Form.Item name={['backgroundCheckingServices', service.source]} noStyle>
          <Popover
            content={removeValidationPopoverContent}
            visible={isVisible}
            placement="topLeft"
            overlayClassName="custom-card-popover remove-template-validations"
          >
            <Checkbox
              disabled={disabled || serviceIsOnProduct(service.source)}
              checked={checked}
              onClick={onCheckboxClick}
              onChange={onCheckboxChangeHandler}
            >
              <Text className="max-lines-2">
                <Text
                  className="text-dark"
                  title={translate(`services.${service.source}.title`, service.title)}
                >
                  {newSources.includes(service?.source) && (
                    <Tag color="blue">
                      <strong>{translate(`components.dataSourceCards.new`)}</strong>
                    </Tag>
                  )}
                  <Tooltip placement="right" title={service.sectionAttr}>
                    <span
                      className="width-100"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <span style={{ fontWeight: '700' }}>
                        {!!service.subtitle &&
                          translate(`services.${service.source}.subtitle`, service.title)}
                      </span>
                      <span>
                        {translate(`services.${service.source}.title`, service.title)}
                      </span>
                    </span>
                  </Tooltip>
                </Text>
                {service?.status === 'DEPRECATED' && (
                  <Tag color="warning">
                    <Tooltip
                      placement="right"
                      title={translate(
                        'pages.private.profileModels.components.backgroundChecking.renderPanelContentRow.renderPanelContentRow.tooltip.deprecated'
                      )}
                    >
                      <i className="caf-ic_alert-triangle" />
                    </Tooltip>
                  </Tag>
                )}
                {serviceIsOnProduct(service.source) || (
                  <FeatureFlagWrapper featureFlag="costPrices">
                    <Text className="data-source-price">
                      + {i18nNumberToFormattedString(service?.price || 0, 'pt-BR')} /{' '}
                      {translate(`${I18N_BASE_PATH}.price.consultation`)}
                    </Text>
                  </FeatureFlagWrapper>
                )}
              </Text>
            </Checkbox>
          </Popover>
        </Form.Item>
      </Col>
      {mode === 'edit' && (
        <>
          <Col span={8} className="mrg-right-15">
            <span>
              {renderDescription(
                translate(`services.${service?.source}.description`, service.description)
              )}
            </span>
          </Col>
          <PermissionWrapper requiredPermissions={['integration-guide:read']}>
            <Col span={2}>
              <a
                href={service?.documentationUrl}
                disabled={!service?.documentationUrl}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </Col>
          </PermissionWrapper>
        </>
      )}
    </Row>
  );
};

RenderPanelContentRow.propTypes = {
  service: PropTypes.shape({
    source: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
    relatedRules: PropTypes.arrayOf(PropTypes.shape({ rule: PropTypes.string })),
    documentationUrl: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  allServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['preview-compact', 'edit']),
};

RenderPanelContentRow.defaultProps = {
  mode: 'edit',
};

export default RenderPanelContentRow;
