import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Form, Input, Spin, Select, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';

import { useAuth } from 'hooks/auth';
import { useFetch } from 'services/hooks';
import PageTitle from 'components/PageTitle';
import Wrapper from 'pages/private/wrapper';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useProfileTemplate } from 'hooks/profileTemplate';
import confirm from 'antd/lib/modal/confirm';
import { CardWrapper } from 'components/CardWrapper';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import RoutineSubheader from './components/RoutineSubheader';
import RoutineFilter from './components/RoutineFilter';
import RoutineFrequency from './components/RoutineFrequency';
import { options, newRoutineDefault } from './configs';
import {
  funcSumPrice,
  funcExtractValitions,
  funcRemoveValidationsFromFormField,
  getUniqueItemsByRule,
} from './utils';
import PriceWidget from './components/PriceWidget';

import RoutineService from './components/RoutineService';
import RoutineValidation from './components/RoutineValidation';
import Unauthorized from '../../Unauthorized';

const I18N_BASE_PATH = 'pages.private.routines.detail.index';

const RoutineDetails = () => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const history = useHistory();
  const { routineId } = useParams();
  const [form] = Form.useForm();
  const [routine, setRoutine] = useState();

  const [price, setPrice] = useState(0);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [validationOptions, setValidationOptions] = useState([]);

  const [query] = useQueryParams({
    type: StringParam,
    profileType: StringParam,
  });

  const { get, post, put, loading } = useFetch();

  const {
    loadCafServicesList,
    cafServicesList,
    loadingCafServicesList,
  } = useProfileTemplate();

  useEffect(() => {
    loadCafServicesList();
  }, [loadCafServicesList]);

  useEffect(() => {
    if (routine?.profileType) {
      const newServiceOptions = cafServicesList?.docs?.filter(
        (service) =>
          service.productType === 'source' && service.type === routine.profileType
      );
      setServiceOptions(newServiceOptions);
    }
  }, [cafServicesList, routine]);

  const fetch = useCallback(async () => {
    let _newRoutine = { ...newRoutineDefault };
    if (routineId) {
      const res = await get({
        url: `routines/${routineId}`,
      });
      _newRoutine = res.data;
    }

    if (query?.type) _newRoutine.type = query.type;
    if (query?.profileType) _newRoutine.profileType = query.profileType;

    if (_newRoutine?.filters) {
      _newRoutine.filters = _newRoutine.filters?.map((filter) => {
        const { field } = filter;
        const fieldOption = options.find((option) => option.field === field);
        if (fieldOption.valueType === 'date') {
          filter.value = moment(filter.value);
        }
        return filter;
      });
    }

    setRoutine(_newRoutine);
    setPrice(funcSumPrice(_newRoutine.services, cafServicesList));

    const newValidationOptions = funcExtractValitions(
      _newRoutine.services,
      cafServicesList,
      _newRoutine.validations
    );

    const newValidationOptionsFiltered = getUniqueItemsByRule(newValidationOptions || []);

    setValidationOptions(newValidationOptionsFiltered);
  }, [cafServicesList, get, query, routineId]);

  const save = useCallback(
    (payload) => {
      if (payload._id) {
        return put({
          url: `routines/${routineId}`,
          payload,
        });
      }
      return post({
        url: `routines`,
        payload,
      });
    },
    [post, put, routineId]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const onFrequencyChange = useCallback(
    (frequency) => {
      const newRoutine = { ...routine };
      newRoutine.frequency = frequency;
      setRoutine(newRoutine);
    },
    [routine]
  );

  const onServicesChange = useCallback(
    (services) => {
      if (services) {
        const newRoutine = { ...routine, services };
        setRoutine(newRoutine);
        setPrice(funcSumPrice(newRoutine.services, cafServicesList));
        const newValidationOptions = funcExtractValitions(
          newRoutine.services,
          cafServicesList,
          newRoutine.validations
        );

        const newValidationOptionsFiltered = getUniqueItemsByRule(
          newValidationOptions || []
        );

        setValidationOptions(newValidationOptionsFiltered);

        form.setFieldsValue({
          validations: funcRemoveValidationsFromFormField(
            form.getFieldValue('validations'),
            newValidationOptions
          ),
        });
      }
    },
    [cafServicesList, form, routine]
  );

  const onValidationChange = useCallback(
    (validationsSelected) => {
      const newValidationOptions = funcExtractValitions(
        routine.services,
        cafServicesList,
        validationsSelected
      );

      const newValidationOptionsFiltered = getUniqueItemsByRule(
        newValidationOptions || []
      );

      setValidationOptions(newValidationOptionsFiltered);
    },
    [cafServicesList, routine]
  );

  const showConfirmModal = useCallback(
    (payload) => {
      confirm({
        title: translate(`${I18N_BASE_PATH}.confirmSave`),
        icon: <ExclamationCircleOutlined />,
        content: translate(`${I18N_BASE_PATH}.emptyValidationListOnSave`),
        onOk() {
          save(payload).then(history.push(`/routines`));
        },
      });
    },
    [history, save, translate]
  );

  const onFinish = useCallback(
    async (formValues) => {
      const payload = {
        ...routine,
        ...formValues,
      };

      if (!payload.validations || payload.validations.length === 0) {
        showConfirmModal(payload);
        return;
      }

      save(payload).then(history.push(`/routines`));
    },
    [history, routine, save, showConfirmModal]
  );

  return !user?.accountData?.featureFlags?.profilesModule ? (
    <Unauthorized />
  ) : (
    <>
      {!loading && !loadingCafServicesList && routine ? (
        <Form
          form={form}
          name="routine-form"
          initialValues={routine}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Wrapper
            id="routines-wrapper"
            subheader={<RoutineSubheader routine={routine} />}
            hideMainHeader
          >
            <CardWrapper hasPadding>
              <RoutineSubheader routine={routine} />
              <Row align="middle" className="flex no-mrg">
                <Col className="flex-1 page-title">
                  <PageTitle
                    title={translate(`${I18N_BASE_PATH}.title`)}
                    subtitle={translate(`${I18N_BASE_PATH}.subtitle`)}
                  />
                </Col>
                <Col span={6} offset={4}>
                  <PriceWidget pricePerProfile={price} />
                </Col>
              </Row>
              <Row>
                <Col span={12} offset={4}>
                  <Alert
                    className="alert-info-form"
                    message={
                      <span>
                        <strong>{translate(`${I18N_BASE_PATH}.warning.title`)}</strong>{' '}
                        {translate(`${I18N_BASE_PATH}.warning.description`)}
                      </span>
                    }
                    type="warning"
                  />
                </Col>
              </Row>

              <Col span={24}>
                <Row gutter={50}>
                  <Col span={8}>
                    <Form.Item
                      name="name"
                      label={translate(`${I18N_BASE_PATH}.form.name.label`)}
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="type"
                      label={translate(`${I18N_BASE_PATH}.form.type.label`)}
                      rules={[{ required: true }]}
                    >
                      <Select disabled>
                        <Select.Option value="recurrence">
                          {translate(`${I18N_BASE_PATH}.form.type.types.recurrence`)}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="profileType"
                      label={translate(`${I18N_BASE_PATH}.form.profileType.label`)}
                      rules={[{ required: true }]}
                    >
                      <Select disabled>
                        <Select.Option value="PF">
                          {translate(`${I18N_BASE_PATH}.form.profileType.types.PF`)}
                        </Select.Option>
                        <Select.Option value="PJ">
                          {translate(`${I18N_BASE_PATH}.form.profileType.types.PJ`)}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <RoutineFilter form={form} type={routine.profileType} />
              <RoutineFrequency
                frequency={routine.frequency}
                onChange={onFrequencyChange}
              />
              {serviceOptions && (
                <RoutineService
                  services={routine.services}
                  servicesOptions={serviceOptions}
                  onChange={onServicesChange}
                />
              )}
              {validationOptions && (
                <RoutineValidation
                  validations={routine.validations}
                  validationsOptions={validationOptions}
                  onChange={onValidationChange}
                  form={form}
                />
              )}
            </CardWrapper>
          </Wrapper>
        </Form>
      ) : (
        <Wrapper id="routines-wrapper" hideMainHeader>
          <CardWrapper hasPadding>
            <Row align="middle" justify="center">
              <Spin />
            </Row>
          </CardWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default RoutineDetails;
