import { React, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFetch } from 'services/hooks';
import Wrapper from 'pages/private/wrapper';
import { Tabs } from 'antd';
import items from '../../../utils/data/globalDashboardItems.json';
import DashboardLoading from './components/DashboardLoading';
import { usePostDashboard, useIFrameLoading } from './hooks';
import './styles.less';

const GlobalDashboard = () => {
  const { post, data, loading } = useFetch();
  const { pathname } = useLocation();

  const [isFrameLoading, setIsFrameLoading] = useState(true);
  const [tabPath, setTabPath] = useState(null);

  const onTabChange = (path) => {
    setTabPath(path);
  };

  usePostDashboard(post, setIsFrameLoading, tabPath, setTabPath);
  useIFrameLoading(setIsFrameLoading);

  return (
    <Wrapper>
      {pathname.split('/')[2] === 'onboarding' && (
        <Tabs defaultActiveKey="1" onChange={onTabChange}>
          {items.map((item) => (
            <Tabs.TabPane tab={item.title} key={item.path} />
          ))}
        </Tabs>
      )}
      {loading ? (
        <DashboardLoading /> // API request loading
      ) : (
        <>
          {isFrameLoading && <DashboardLoading />} {/** iframe event loading */}
          <iframe
            src={data}
            title="metabase-dashboard"
            className="iframe"
            hidden={isFrameLoading}
          />
        </>
      )}
    </Wrapper>
  );
};

export default GlobalDashboard;
