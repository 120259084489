import { Col, Row, Divider, Typography, Alert } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import '../styles.less';

const { Title } = Typography;

const I18N_BASE_PATH = 'components.dataSourceCards.denatranVehicleInfos';

function VehiclesDefaultInfosCollapsePanel({ vehicleInfo = {}, toPrint = false }) {
  const { t: translate } = useTranslation();

  const vehicleData = useMemo(() => vehicleInfo, [vehicleInfo]);

  const customHeader = useMemo(() => {
    return (
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col span={18}>
          <i className="caf-ic_driver" />
          <span className="text-dark text-bold pdd-left-10">
            {translate(
              `${I18N_BASE_PATH}.columns.${
                vehicleData?.infoType === 'prf' ? 'prfTitle' : 'dnitTitle'
              }`
            ).toUpperCase()}
          </span>
        </Col>
      </Row>
    );
  }, [vehicleData, translate]);

  const ListInfos = (data, title) => {
    return (
      <>
        {title && (
          <Row justify="center" className="mrg-top-10">
            <Col span={24}>
              <Title level={5}>{title}</Title>
            </Col>
          </Row>
        )}

        {data &&
          data?.map((dt, index) => (
            <React.Fragment key={index}>
              <Row justify="space-between" className="mrg-top-10">
                {Object.keys(dt).map((dtKey) => (
                  <ColumnTitleValue
                    span={8}
                    key={dtKey}
                    className="inline-infos"
                    title={`${translate(`${I18N_BASE_PATH}.columns.${dtKey}`)}:`}
                    value={dt[dtKey] || '-'}
                  />
                ))}
              </Row>
              {index < data?.length - 1 && <Divider />}
            </React.Fragment>
          ))}
      </>
    );
  };

  return (
    <DataSourceCardCollapsePanel
      panelProps={{
        disabled:
          (vehicleData?.infoType === 'prf' &&
            !vehicleData?.hasFines &&
            !vehicleData?.hasStatus) ||
          (vehicleData?.infoType === 'dnit' && !vehicleData?.violations?.length),
      }}
      customHeader={customHeader}
      toPrint={toPrint}
    >
      {vehicleData?.infoType === 'prf' && (
        <>
          {vehicleData?.hasStatus ? (
            <>
              <Row justify="center" className="mrg-top-10">
                <Col span={24}>
                  {!vehicleData?.hasFines ? (
                    <Alert
                      message={`${translate(
                        `${I18N_BASE_PATH}.columns.prfStatusNotFound`
                      )}`}
                      type="success"
                      showIcon
                    />
                  ) : (
                    <Alert
                      message={`${translate(
                        `${I18N_BASE_PATH}.columns.prfStatusFound`
                      )}:`}
                      type="error"
                      showIcon
                    />
                  )}
                </Col>
              </Row>
            </>
          ) : null}

          {vehicleData?.fines?.length && ListInfos(vehicleData?.fines, 'Infrações')}
        </>
      )}

      {vehicleData?.infoType === 'dnit' && (
        <>
          {vehicleData?.message ? (
            <>
              <Row justify="center" className="mrg-top-10">
                <Col span={24}>
                  <Alert message={vehicleData?.message} type="info" showIcon />
                </Col>
              </Row>
            </>
          ) : null}

          {vehicleData?.violations?.length &&
            ListInfos(vehicleData?.violations, 'Infrações')}
        </>
      )}
    </DataSourceCardCollapsePanel>
  );
}

export default VehiclesDefaultInfosCollapsePanel;
