import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactJsonView from 'react-json-view';

import PageTitle from 'components/PageTitle';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { useExecution } from 'hooks/execution';
import './styles.less';

const I18N_BASE_PATH = 'components.ModalExecutionsApiGuide';

const ModalExecutionsApiGuide = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState({});

  const { t: translate } = useTranslation();
  const { execution } = useExecution();

  const { user } = useAuth();
  const { get, loading } = useFetch();

  const getUrl = useMemo(
    () => `${process.env.REACT_APP_BASE_URL_CAF_API}/v1/transactions/${execution?._id}`,
    [execution]
  );

  const headers = useMemo(() => {
    const token = user?.accountData?.products?.trust?.cafAccessToken || '';
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }, [user]);

  const getExecutionData = useCallback(async () => {
    const response = await get({
      url: getUrl,
      config: {
        ...headers,
      },
    });

    setData(response);
  }, [get, getUrl, headers]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setData(null);
  }, []);

  const openModal = useCallback(() => {
    setModalVisible(true);
    getExecutionData();
  }, [getExecutionData]);

  const ExecutionApiGuideModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={700}
        onCancel={closeModal}
      >
        <PageTitle
          title={translate(`${I18N_BASE_PATH}.PageTitle.title`)}
          subtitle={translate(`${I18N_BASE_PATH}.PageTitle.subtitle`)}
          style={{ marginBottom: 20 }}
        />

        <strong className="text-dark text-bold">
          {translate(`${I18N_BASE_PATH}.label.endpoint`)}
        </strong>

        <div className="section-container">
          <div className="url-button-wrapper">
            <Button
              type="primary"
              shape="round"
              className="flex no-mrg-btm center request-type-button"
              outlined="false"
            >
              GET
            </Button>
            <span className="request-url text-dark">{getUrl}</span>
          </div>
          <Button
            type="link"
            style={{ paddingLeft: 0 }}
            onClick={() =>
              copyTextToClipboard(
                getUrl,
                translate(`${I18N_BASE_PATH}.copyButton.urlCopySuccess`),
                translate(`${I18N_BASE_PATH}.copyButton.urlCopyFail`)
              )
            }
          >
            {translate(`${I18N_BASE_PATH}.copyButton.title`)}
          </Button>
        </div>

        <strong className="text-dark text-bold">
          {translate(`${I18N_BASE_PATH}.label.headers`)}
        </strong>
        <div className="section-container pdd-top-10 pdd-btm-10">
          <div className="json-button-wrapper">
            <ReactJsonView
              name={false}
              indentWidth={2}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              src={headers}
            />
          </div>
          <Button
            type="link"
            onClick={() =>
              copyTextToClipboard(
                JSON.stringify(headers),
                translate(`${I18N_BASE_PATH}.copyButton.dataCopySuccess`),
                translate(`${I18N_BASE_PATH}.copyButton.dataCopyFail`)
              )
            }
          >
            {translate(`${I18N_BASE_PATH}.copyButton.title`)}
          </Button>
        </div>

        <strong className="text-dark text-bold">
          {translate(`${I18N_BASE_PATH}.label.response`)}
        </strong>
        <div className="section-container pdd-top-10 pdd-btm-10">
          <div className="json-button-wrapper">
            {loading ? (
              <Spin className="json-loading-spin" />
            ) : (
              <ReactJsonView
                name={false}
                indentWidth={2}
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
                src={data}
                collapsed={1}
              />
            )}
          </div>
          <Button
            type="link"
            disabled={loading}
            onClick={() =>
              copyTextToClipboard(
                JSON.stringify(data),
                translate(`${I18N_BASE_PATH}.copyButton.dataCopySuccess`),
                translate(`${I18N_BASE_PATH}.copyButton.dataCopyFail`)
              )
            }
          >
            {translate(`${I18N_BASE_PATH}.copyButton.title`)}
          </Button>
        </div>

        <div className="modal-integration-action-buttons" align="middle" justify="end">
          <Button
            type="primary"
            shape="round"
            className="flex no-mrg-btm center"
            outlined="false"
            onClick={closeModal}
          >
            {translate(`${I18N_BASE_PATH}.closeButton.title`)}
          </Button>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, translate, getUrl, loading, headers, data]
  );

  return { openModal, ExecutionApiGuideModal };
};

export default ModalExecutionsApiGuide;
