/* eslint-disable react/prop-types */
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';
import * as executionDataSources from 'utils/executionDataSource';
import Processes from './components/Processes';
import ProcessesAlt from './components/ProcessesAlt';
import ProcessesWithKeyWords from './components/ProcessesWithKeyWords';
import LegalRecordsExtended from './components/LegalRecordsExtended';
import LegalRecordsExtendedKeywords from './components/LegalRecordsExtendedKeywords';
import ProcessOnCade from './components/ProcessesCADE';

import DataSourceCardCollapsePanel from '../DataSourceCardCollapsePanel';

const LegalInformation = ({ toPrint }) => {
  const { t: translate } = useTranslation();

  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );
  const hasProcessOnCade = useMemo(() => findSourceSection('pfProcessOnCade'), [
    findSourceSection,
  ]);

  const hasPersonProcesses = useMemo(
    () =>
      !!(
        findSourceSection('pfProcesses') ||
        findSourceSection('pfProcessesAlt') ||
        findSourceSection('pfProcessesWithKeywords')
      )?.data?.total,
    [findSourceSection]
  );

  const hasProcesses = useMemo(
    () => findSourceSection('pfProcesses') || findSourceSection('pjProcesses'),
    [findSourceSection]
  );
  const hasProcessesAlt = useMemo(
    () => findSourceSection('pfProcessesAlt') || findSourceSection('pjProcessesAlt'),
    [findSourceSection]
  );
  const hasProcessesWithKeyWords = useMemo(
    () =>
      findSourceSection('pfProcessesWithKeywords') ||
      findSourceSection('pjProcessesWithKeywords'),
    [findSourceSection]
  );
  const hasLegalRecordsExtended = useMemo(
    () =>
      findSourceSection('pfLegalRecordsExtended') ||
      findSourceSection('pjLegalRecordsExtended'),
    [findSourceSection]
  );
  const hasLegalRecordsExtendedKeywords = useMemo(
    () =>
      findSourceSection('pfLegalRecordsExtendedKeywords') ||
      findSourceSection('pjLegalRecordsExtendedKeywords'),
    [findSourceSection]
  );

  return (
    <DataSourceCardCollapsePanel
      id="processess-infos-component"
      title={translate(
        'components.dataSourceCards.legalInformation.index.legalInformation.dossierCard.title'
      )}
      icon="caf-ic_law"
      toPrint={toPrint}
    >
      <div className="flex flex-column no-mrg no-pdd">
        {hasProcessOnCade && (
          <ProcessOnCade source={hasProcessOnCade} toPrint={toPrint} />
        )}
        {hasProcesses && (
          <Processes
            source={hasProcesses}
            toPrint={toPrint}
            type={translate(
              'components.dataSourceCards.legalInformation.index.legalInformation.processes.type'
            )}
            hasPersonProcesses={hasPersonProcesses}
          />
        )}
        {hasProcessesAlt && (
          <ProcessesAlt
            source={hasProcessesAlt}
            toPrint={toPrint}
            type={translate(
              'components.dataSourceCards.legalInformation.index.legalInformation.processes.type'
            )}
            hasPersonProcesses={hasPersonProcesses}
          />
        )}
        {hasProcessesWithKeyWords && (
          <ProcessesWithKeyWords
            source={hasProcessesWithKeyWords}
            toPrint={toPrint}
            type={translate(
              'components.dataSourceCards.legalInformation.index.legalInformation.processes.type'
            )}
            hasPersonProcesses={hasPersonProcesses}
          />
        )}
        {hasLegalRecordsExtended && (
          <LegalRecordsExtended
            source={hasLegalRecordsExtended}
            toPrint={toPrint}
            type={translate(
              'components.dataSourceCards.legalInformation.index.legalInformation.processes.type'
            )}
          />
        )}
        {hasLegalRecordsExtendedKeywords && (
          <LegalRecordsExtendedKeywords
            source={hasLegalRecordsExtendedKeywords}
            toPrint={toPrint}
            type={translate(
              'components.dataSourceCards.legalInformation.index.legalInformation.processes.type'
            )}
          />
        )}
      </div>
    </DataSourceCardCollapsePanel>
  );
};

export default LegalInformation;
