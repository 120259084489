/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import { Col, Divider, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { fieldLabelMappings } from './mapping';

const VehicleInfosDetails = () => {
  const { t: translate } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [vehicle, setVehicle] = useState({});

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback((data) => {
    setVehicle(data);
    setModalVisible(true);
  }, []);

  const additionalInformation = (additionalInformation) => {
    if (!additionalInformation) return <></>;
    const elements = additionalInformation.map((info) => {
      return (
        <Row className="mrg-btm-10">
          <Col span={24}>
            <span className="text-bold text-dark">{info.description}:</span>{' '}
            <span>{info.value || '-'}</span>
          </Col>
        </Row>
      );
    });

    return elements;
  };

  const content = useMemo(() => {
    if (!vehicle) return <></>;
    const vehicleKeys = Object.keys(vehicle || {});

    const elements = vehicleKeys.map((key) => {
      if (fieldLabelMappings(key)) {
        return (
          <Row className="mrg-btm-10">
            <Col span={24}>
              <span className="text-bold text-dark">
                {translate(fieldLabelMappings(key))}:
              </span>{' '}
              <span>{vehicle[key] || '-'}</span>
            </Col>
          </Row>
        );
      }

      return <></>;
    });

    if (vehicle?.additionalInformation) {
      elements.push(
        <Divider key="divider" className="mrg-btm-10">
          {translate(
            'components.dataSourceCards.vehicleInfosModal.columns.additionalInformationTitle'
          )}
        </Divider>
      );
      elements.push(additionalInformation(vehicle.additionalInformation));
    }

    return elements;
  }, [vehicle, translate]);

  const VehicleInfosDetailsModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal"
        onCancel={closeModal}
        footer={null}
        width={700}
        title={vehicle?.vehicleDescription}
        destroyOnClose
      >
        {content}
      </Modal>
    ),
    [modalVisible, closeModal, vehicle, content]
  );

  return {
    openModal,
    closeModal,
    VehicleInfosDetailsModal,
  };
};

export default VehicleInfosDetails;
