import { Col, Row, Divider, Typography, Alert } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import '../styles.less';

const { Title } = Typography;

const I18N_BASE_PATH = 'components.dataSourceCards.denatranVehicleInfos';

function VehicleDetranInfosCollapsePanel({ vehicleInfo = {}, toPrint = false }) {
  const { t: translate } = useTranslation();

  const vehicleData = useMemo(() => vehicleInfo, [vehicleInfo]);

  const customHeader = useMemo(() => {
    return (
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col span={18}>
          <i className="caf-ic_driver" />
          <span className="text-dark text-bold pdd-left-10">
            {translate(`${I18N_BASE_PATH}.columns.detranTitle`).toUpperCase()}
          </span>
        </Col>
      </Row>
    );
  }, [translate]);

  const ListInfos = (data, title) => {
    return (
      <>
        {title && (
          <Row justify="center" className="mrg-top-10">
            <Col span={24}>
              <Title level={5}>{title}</Title>
            </Col>
          </Row>
        )}

        {data?.map((dt, index) => {
          return (
            <React.Fragment key={index}>
              <Row justify="space-between" className="mrg-top-10">
                {Object.keys(dt).map((dtKey) => (
                  <ColumnTitleValue
                    key={dtKey}
                    span={6}
                    title={`${translate(`${I18N_BASE_PATH}.columns.${dtKey}`)}:`}
                    value={dt[dtKey] || '-'}
                  />
                ))}
              </Row>
              {index < data?.length - 1 && <Divider dashed />}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const DetailsInfos = (data, title) => {
    return (
      <>
        {title && (
          <Row justify="center" className="mrg-top-10">
            <Col span={24}>
              <Title level={5}>{title}</Title>
            </Col>
          </Row>
        )}
        <Row justify="space-between" className="mrg-top-10">
          {Object.keys(data).map((dtKey) => {
            return (
              <ColumnTitleValue
                span={6}
                title={`${translate(`${I18N_BASE_PATH}.columns.${dtKey}`)}:`}
                value={data[dtKey] || '-'}
              />
            );
          })}
        </Row>
      </>
    );
  };

  const TitleInfo = (title) => {
    return (
      <Row justify="center" className="mrg-top-10">
        <Col span={24}>
          <Title level={5}>{title}</Title>
        </Col>
      </Row>
    );
  };

  const restrictionsAndImpactsInfos = (restrictions, title) => {
    return (
      <>
        {title && (
          <Row justify="center" className="mrg-top-10">
            <Col span={24}>
              <Title level={5}>{title}</Title>
            </Col>
          </Row>
        )}

        {restrictions?.map((restriction, index) => (
          <React.Fragment key={index}>
            <Row justify="space-between" className="mrg-top-10">
              {Object.keys(restriction).map((key) => {
                if (key !== 'details') {
                  return (
                    <ColumnTitleValue
                      key={key}
                      span={6}
                      title={`${translate(`${I18N_BASE_PATH}.columns.${key}`)}:`}
                      value={restriction[key] || '-'}
                    />
                  );
                }
                return null;
              })}
            </Row>

            {restriction?.impacts && (
              <>
                <Row justify="center" className="mrg-top-10">
                  <Col span={24}>
                    <Title level={5}>Detalhes</Title>
                  </Col>
                </Row>
                {restriction?.impacts.map((impact, impactIndex) => (
                  <Row justify="space-between" className="mrg-top-10" key={impactIndex}>
                    <ColumnTitleValue span={6} value={impact || '-'} />
                  </Row>
                ))}
              </>
            )}

            {index < restrictions?.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </>
    );
  };

  const TotalDebtsInfos = (totalDebts, title) => {
    return (
      <>
        {title && (
          <Row justify="center" className="mrg-top-10">
            <Col span={24}>
              <Title level={5}>{title}</Title>
            </Col>
          </Row>
        )}

        {totalDebts?.map((extract, index) => (
          <React.Fragment key={index}>
            <Row justify="space-between" className="mrg-top-10">
              {Object.keys(extract).map((key) => {
                if (key !== 'debtsDetails') {
                  return (
                    <ColumnTitleValue
                      key={key}
                      span={6}
                      title={`${translate(`${I18N_BASE_PATH}.columns.${key}`)}:`}
                      value={extract[key] || '-'}
                    />
                  );
                }
                return null;
              })}
            </Row>

            {extract?.debtsDetails && (
              <>
                <Row justify="center" className="mrg-top-10">
                  <Col span={24}>
                    <Title level={5}>Detalhes dos débitos</Title>
                  </Col>
                </Row>
                {extract?.debtsDetails?.map((debt, debtIndex) => (
                  <Row justify="space-between" className="mrg-top-10" key={debtIndex}>
                    {Object.keys(debt).map((debtKey) => (
                      <ColumnTitleValue
                        key={debtKey}
                        span={6}
                        title={`${translate(`${I18N_BASE_PATH}.columns.${debtKey}`)}:`}
                        value={debt[debtKey] || '-'}
                      />
                    ))}
                  </Row>
                ))}
              </>
            )}

            {index < totalDebts?.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <DataSourceCardCollapsePanel customHeader={customHeader} toPrint={toPrint}>
      {vehicleData?.AC && (
        <DataSourceCardCollapsePanel title="Acre (AC)" toPrint={toPrint}>
          <>
            {DetailsInfos(
              {
                message: vehicleData?.AC?.message,
                plate: vehicleData?.AC?.plate,
                renavam: vehicleData?.AC?.renavam,
                chassis: vehicleData?.AC?.chassis,
                brandModel: vehicleData?.AC?.brandModel,
                color: vehicleData?.AC?.color,
                manufactureModelYear: vehicleData?.AC?.manufactureModelYear,
                type: vehicleData?.AC?.type,
                fuel: vehicleData?.AC?.fuel,
                powerCapacity: vehicleData?.AC?.powerCapacity,
                category: vehicleData?.AC?.category,
                passengerCapacity: vehicleData?.AC?.passengerCapacity,
                species: vehicleData?.AC?.species,
                nationality: vehicleData?.AC?.nationality,
                city: vehicleData?.AC?.city,
                hasTheft: vehicleData?.AC?.hasTheft,
                vehicleSituation: vehicleData?.AC?.vehicleSituation,
                lastLicensingYear: vehicleData?.AC?.lastLicensingYear,
              },
              translate(
                'pages.private.executions.components.dataSourceCards.basicInfos.components.basicInfosCard.sources.vehicleViolations'
              )
            )}
          </>

          {vehicleData?.AC?.licensingFee && (
            <>
              <Divider dashed />

              {DetailsInfos(
                {
                  totalLicensing: vehicleData?.AC?.licensingFee?.totalLicensing,
                  detranMessage: vehicleData?.AC?.licensingFee?.detranMessage,
                },
                translate(`${I18N_BASE_PATH}.columns.licensingFee`)
              )}
              <Divider dashed />
              {ListInfos(
                vehicleData?.AC?.licensingFee?.licensingDetails,
                translate(`${I18N_BASE_PATH}.columns.licensingDetails`)
              )}
            </>
          )}

          {vehicleData?.AC?.ipvas && (
            <>
              <Divider />
              {DetailsInfos(
                {
                  totalIpva: vehicleData?.AC?.ipvas?.totalIpva,
                  detranMessage: vehicleData?.AC?.ipvas?.detranMessage,
                },
                'IPVA'
              )}
              <Divider dashed />

              {ListInfos(
                vehicleData?.AC?.ipvas?.ipvaDetails,
                translate(`${I18N_BASE_PATH}.columns.ipvaDetails`)
              )}
            </>
          )}

          {vehicleData?.AC?.mandatoryInsurance && (
            <>
              <Divider />
              {TitleInfo(
                `${translate(`${I18N_BASE_PATH}.columns.mandatoryInsurance`)}: ${
                  vehicleData?.AC?.mandatoryInsurance?.detranMessage
                }`
              )}
            </>
          )}

          {vehicleData?.AC?.infraction && (
            <>
              <Divider />
              {DetailsInfos(
                {
                  detranMessage: vehicleData?.AC?.infraction?.detranMessage,
                },
                translate(
                  'components.dataSourceCards.denatranDriverAltInfos.violationsTitle'
                )
              )}
              {ListInfos(
                vehicleData?.AC?.infraction?.infractionDetails,
                translate(`${I18N_BASE_PATH}.columns.infractionDetails`)
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.AM && (
        <DataSourceCardCollapsePanel title="Amazonas (AM)" toPrint={toPrint}>
          {vehicleData?.AM?.message && (
            <Alert message={vehicleData?.AM?.message} type="info" showIcon />
          )}

          {vehicleData?.AM?.ownerData && (
            <>
              {DetailsInfos(
                vehicleData?.AM?.ownerData,
                translate(`${I18N_BASE_PATH}.columns.licensingFee`)
              )}
            </>
          )}

          {vehicleData?.AM?.vehicleData && (
            <>
              <Divider />
              {DetailsInfos(
                vehicleData?.AM?.vehicleData,
                translate(
                  'pages.private.executions.components.dataSourceCards.basicInfos.components.basicInfosCard.sources.vehicleViolations'
                )
              )}
            </>
          )}

          {vehicleData?.AM?.vehicleSituation && (
            <>
              <Divider />
              {DetailsInfos(
                vehicleData?.AM?.vehicleSituation,
                translate(`${I18N_BASE_PATH}.columns.vehicleSituation`)
              )}
            </>
          )}

          {vehicleData?.AM?.debts && (
            <>
              <Divider />
              {DetailsInfos(
                vehicleData?.AM?.debts,
                translate(`${I18N_BASE_PATH}.columns.debts`)
              )}
            </>
          )}

          {vehicleData?.AM?.fineConsultation && (
            <>
              <Divider />
              {DetailsInfos(
                { message: vehicleData?.AM?.fineConsultation?.message },
                'Consulta de Multas'
              )}
              {ListInfos(
                vehicleData?.AM?.fineConsultation?.fines,
                translate(`${I18N_BASE_PATH}.columns.fines`)
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.CE && (
        <DataSourceCardCollapsePanel title="Ceará (CE)" toPrint={toPrint}>
          {vehicleData?.CE?.message && (
            <Alert message={vehicleData?.CE?.message} type="info" showIcon />
          )}

          {vehicleData?.CE?.plateMessage && (
            <>
              <Alert
                message={`${translate(`${I18N_BASE_PATH}.columns.plateMessage`)}: ${
                  vehicleData?.CE?.plateMessage
                }`}
                type="info"
                showIcon
              />
            </>
          )}

          {vehicleData?.CE?.fine && (
            <>
              <Divider />
              {TitleInfo('Multas')}
              {DetailsInfos({ message: vehicleData?.CE?.fine?.message })}

              {ListInfos(
                vehicleData?.CE?.fine?.details,
                translate(`${I18N_BASE_PATH}.columns.fineDetails`)
              )}
            </>
          )}

          {vehicleData?.CE?.licensing && (
            <>
              <Divider />
              {TitleInfo('Licenciamento')}
              {DetailsInfos({ message: vehicleData?.CE?.licensing?.message })}

              {ListInfos(
                vehicleData?.CE?.licensing?.details,
                translate(`${I18N_BASE_PATH}.columns.licensingDetails`)
              )}
            </>
          )}

          {vehicleData?.CE?.totalLicensing && (
            <>
              <Divider />
              {DetailsInfos(
                { totalValue: vehicleData?.CE?.totalLicensing?.totalValue },
                translate(`${I18N_BASE_PATH}.columns.totalLicensing`)
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.MA && (
        <DataSourceCardCollapsePanel title="Maranhão (MA)" toPrint={toPrint}>
          {vehicleData?.MA?.message && (
            <Alert message={vehicleData?.MA?.message} type="info" showIcon />
          )}

          {vehicleData?.MA && (
            <>
              {DetailsInfos(
                {
                  plate: vehicleData?.MA?.plate,
                  renavam: vehicleData?.MA?.renavam,
                  previousPlate: vehicleData?.MA?.previousPlate,
                  type: vehicleData?.MA?.type,
                  bodywork: vehicleData?.MA?.bodywork,
                  species: vehicleData?.MA?.species,
                  ownerName: vehicleData?.MA?.ownerName,
                  brandModel: vehicleData?.MA?.brandModel,
                  color: vehicleData?.MA?.color,
                  category: vehicleData?.MA?.category,
                  places: vehicleData?.MA?.places,
                  power: vehicleData?.MA?.power,
                  fuel: vehicleData?.MA?.fuel,
                  licensedUntil: vehicleData?.MA?.licensedUntil,
                  acquiredIn: vehicleData?.MA?.acquiredIn,
                  SNGInformation: vehicleData?.MA?.SNGInformation,
                  impediments: vehicleData?.MA?.impediments,
                  additionalInformation: vehicleData?.MA?.additionalInformation,
                },
                translate(
                  'pages.private.executions.components.dataSourceCards.basicInfos.components.basicInfosCard.sources.vehicleViolations'
                )
              )}
            </>
          )}

          {vehicleData?.MA?.debts && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.MA?.debts?.debtsDetails,
                `${translate(`${I18N_BASE_PATH}.columns.debts`)}: ${
                  vehicleData?.MA?.debts?.message
                }`
              )}
            </>
          )}

          {vehicleData?.MA?.autuations && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.MA?.autuations?.autuationsDetails,
                `${translate(`${I18N_BASE_PATH}.columns.autuations`)}: ${
                  vehicleData?.MA?.autuations?.message
                }`
              )}
            </>
          )}

          {vehicleData?.MA?.fines && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.MA?.fines?.finesDetails,
                `${translate(`${I18N_BASE_PATH}.columns.fines`)}: ${
                  vehicleData?.MA?.fines?.message
                }`
              )}
            </>
          )}

          {vehicleData?.MA?.infractionAppeals && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.MA?.infractionAppeals?.appealsDetails,
                `${translate(`${I18N_BASE_PATH}.columns.infractionAppeals`)}: ${
                  vehicleData?.MA?.infractionAppeals?.message
                }`
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.MG && (
        <DataSourceCardCollapsePanel title="Minas Gerais (MG)" toPrint={toPrint}>
          {vehicleData?.MG?.hasAlert && (
            <>
              <Col span={24}>
                {vehicleData?.MG?.alert?.map((message, index) => (
                  <Alert key={index} message={message} type="warning" showIcon />
                ))}
              </Col>
            </>
          )}

          {vehicleData?.MG?.hasVehicleData && (
            <>
              {DetailsInfos(
                vehicleData?.MG?.vehicleData,
                translate(
                  'pages.private.executions.components.dataSourceCards.basicInfos.components.basicInfosCard.sources.vehicleViolations'
                )
              )}
            </>
          )}

          {vehicleData?.MG?.hasAutuation && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.MG?.autuation,
                translate(`${I18N_BASE_PATH}.columns.autuation`)
              )}
            </>
          )}

          {vehicleData?.MG?.hasFines && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.MG?.fines,
                translate(`${I18N_BASE_PATH}.columns.fines`)
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.PE && (
        <DataSourceCardCollapsePanel title="Pernambuco (PE)" toPrint={toPrint}>
          {vehicleData?.PE &&
            DetailsInfos(
              {
                plate: vehicleData?.PE?.plate,
                type: vehicleData?.PE?.type,
                model: vehicleData?.PE?.model,
                powerCapacityCylinder: vehicleData?.PE?.powerCapacityCylinder,
                color: vehicleData?.PE?.color,
                chassis: vehicleData?.PE?.chassis,
                fuel: vehicleData?.PE?.fuel,
                manufactureModelYear: vehicleData?.PE?.manufactureModelYear,
                category: vehicleData?.PE?.category,
                installment: vehicleData?.PE?.installment,
                detranFees: vehicleData?.PE?.detranFees,
                finesWithSuspensiveAppeal: vehicleData?.PE?.finesWithSuspensiveAppeal,
                infractionsSuspendedByCourtOrder:
                  vehicleData?.PE?.infractionsSuspendedByCourtOrder,
                debtsSuspendedByCourtOrder: vehicleData?.PE?.debtsSuspendedByCourtOrder,
                mandatoryInsurance: vehicleData?.PE?.mandatoryInsurance,
              },
              translate(
                'pages.private.executions.components.dataSourceCards.basicInfos.components.basicInfosCard.sources.vehicleViolations'
              )
            )}

          {vehicleData?.PE?.restrictions?.length && (
            <>
              <Divider />

              <Row justify="center" className="mrg-top-10">
                <Col span={24}>
                  <Title level={5}>{`${translate(
                    `${I18N_BASE_PATH}.columns.restrictions`
                  )}`}</Title>
                </Col>
              </Row>
              {vehicleData?.PE?.restrictions.map((message, index) => (
                <Row justify="space-between" className="mrg-top-10" key={index}>
                  <ColumnTitleValue span={24} value={message || '-'} />
                </Row>
              ))}
            </>
          )}

          {vehicleData?.PE?.restrictionsDetails && (
            <>
              <Divider />

              <Row justify="center" className="mrg-top-10">
                <Col span={24}>
                  <Title level={5}>{`${translate(
                    `${I18N_BASE_PATH}.columns.restrictionsDetails`
                  )} - ${vehicleData?.PE?.restrictionsDetails?.message}`}</Title>
                </Col>
              </Row>
              {vehicleData?.PE?.restrictionsDetails?.restrictions.map(
                (message, index) => (
                  <Row justify="space-between" className="mrg-top-10" key={index}>
                    <ColumnTitleValue span={24} value={message || '-'} />
                  </Row>
                )
              )}
            </>
          )}

          {vehicleData?.PE?.finesDetails && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.PE?.finesDetails?.details,
                `${translate(`${I18N_BASE_PATH}.columns.fines`)} - ${
                  vehicleData?.PE?.finesDetails?.message
                }`
              )}
            </>
          )}

          {vehicleData?.PE?.autuationsDetails && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.PE?.autuationsDetails?.details,
                `${translate(`${I18N_BASE_PATH}.columns.autuations`)} - ${
                  vehicleData?.PE?.autuationsDetails?.message
                }`
              )}
            </>
          )}

          {vehicleData?.PE?.licensings && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.PE?.licensings?.details,
                `${translate(`${I18N_BASE_PATH}.columns.licensing`)} - ${
                  vehicleData?.PE?.licensings?.message
                }`
              )}
            </>
          )}

          {vehicleData?.PE?.totalDebtsWithExceptionOfFinesSuspensiveAndAutuations && (
            <>
              <Divider />
              {DetailsInfos(
                vehicleData?.PE?.totalDebtsWithExceptionOfFinesSuspensiveAndAutuations,
                `${translate(`${I18N_BASE_PATH}.columns.totalDebts`)}`
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.RJ && (
        <DataSourceCardCollapsePanel title="Rio de Janeiro (RJ)" toPrint={toPrint}>
          {vehicleData?.RJ?.message && (
            <Alert message={vehicleData?.RJ?.message} type="info" showIcon />
          )}

          {vehicleData?.RJ?.fines && (
            <>
              {ListInfos(
                vehicleData?.RJ?.fines,
                translate(`${I18N_BASE_PATH}.columns.fines`)
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.RO && (
        <DataSourceCardCollapsePanel title="Rondônia (RO)" toPrint={toPrint}>
          {vehicleData?.RO?.message && (
            <Alert message={vehicleData?.RO?.message} type="info" showIcon />
          )}

          {vehicleData?.RO?.publicConsultation && (
            <>
              {DetailsInfos(
                vehicleData?.RO?.publicConsultation,
                translate(`${I18N_BASE_PATH}.columns.publicConsultation`)
              )}
            </>
          )}

          {vehicleData?.RO?.additionalVehicleDebtsInformation && (
            <>
              <Divider />
              {DetailsInfos(
                {
                  licensingYear:
                    vehicleData?.RO?.additionalVehicleDebtsInformation?.licensingYear,
                  information:
                    vehicleData?.RO?.additionalVehicleDebtsInformation?.information,
                },
                translate(`${I18N_BASE_PATH}.columns.additionalVehicleDebtsInformation`)
              )}

              <>
                <Row justify="center" className="mrg-top-10">
                  <Col span={24}>
                    <Title level={5}>{`${translate(
                      `${I18N_BASE_PATH}.columns.pendingInformation`
                    )}`}</Title>
                  </Col>
                </Row>

                {vehicleData?.RO?.additionalVehicleDebtsInformation?.pendingInformation?.map(
                  (message, index) => (
                    <Row justify="space-between" className="mrg-top-10" key={index}>
                      <ColumnTitleValue span={24} value={message || '-'} />
                    </Row>
                  )
                )}
              </>

              <>
                <Divider />
                {ListInfos(
                  vehicleData?.RO?.additionalVehicleDebtsInformation?.debts,
                  translate(`${I18N_BASE_PATH}.columns.debts`)
                )}
              </>

              <>
                <Divider />
                {restrictionsAndImpactsInfos(
                  vehicleData?.RO?.additionalVehicleDebtsInformation?.restrictions,
                  translate(`${I18N_BASE_PATH}.columns.restrictions`)
                )}
              </>
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.RR && (
        <DataSourceCardCollapsePanel title="Roraima (RR)" toPrint={toPrint}>
          {vehicleData?.RR?.message && (
            <Alert message={vehicleData?.RR?.message} type="info" showIcon />
          )}

          <>
            <Divider />
            {DetailsInfos(
              {
                plate: vehicleData?.RR?.plate,
                renavam: vehicleData?.RR?.renavam,
                chassis: vehicleData?.RR?.chassis,
                brandModel: vehicleData?.RR?.brandModel,
                color: vehicleData?.RR?.color,
                manufactureYear: vehicleData?.RR?.manufactureYear,
                type: vehicleData?.RR?.type,
                fuel: vehicleData?.RR?.fuel,
                category: vehicleData?.RR?.category,
                species: vehicleData?.RR?.species,
                nationality: vehicleData?.RR?.nationality,
                hasTheft: vehicleData?.RR?.hasTheft,
                powerCapacity: vehicleData?.RR?.powerCapacity,
                passengerCapacity: vehicleData?.RR?.passengerCapacity,
                city: vehicleData?.RR?.city,
                vehicleSituation: vehicleData?.RR?.vehicleSituation,
                lastLicensing: vehicleData?.RR?.lastLicensing,
              },
              translate(
                'pages.private.executions.components.dataSourceCards.basicInfos.components.basicInfosCard.sources.vehicleViolations'
              )
            )}
          </>

          {vehicleData?.RR?.restrictions && (
            <>
              <Divider />
              <Row justify="center" className="mrg-top-10">
                <Col span={24}>
                  <Title level={5}>{`${translate(
                    `${I18N_BASE_PATH}.columns.restrictions`
                  )}`}</Title>
                </Col>
              </Row>
              {vehicleData?.RR?.restrictions.map((message, index) => (
                <Row justify="space-between" className="mrg-top-10" key={index}>
                  <ColumnTitleValue span={24} value={message || '-'} />
                </Row>
              ))}
            </>
          )}

          {vehicleData?.RR?.debts && (
            <>
              <Divider />
              <Title level={5}>{`${translate(`${I18N_BASE_PATH}.columns.debts`)}`}</Title>

              <Alert message={vehicleData?.RR?.debts?.message} type="info" showIcon />

              {TotalDebtsInfos(
                vehicleData?.RR?.debts?.totalDebts,
                translate(`${I18N_BASE_PATH}.columns.totalDebts`)
              )}
            </>
          )}

          {vehicleData?.RR?.services && (
            <>
              <Divider />

              {DetailsInfos(
                {
                  message: vehicleData?.RR?.services?.message,
                  totalDescription: vehicleData?.RR?.services?.totalDescription,
                  totalServiceValue: vehicleData?.RR?.services?.totalServiceValue,
                  totalPaidValue: vehicleData?.RR?.services?.totalPaidValue,
                  totalDueValue: vehicleData?.RR?.services?.totalDueValue,
                },
                translate('utils.tokensPermissions.services.full.title')
              )}
              {ListInfos(
                vehicleData?.RR?.services?.servicesDetails,
                translate(`${I18N_BASE_PATH}.columns.servicesDetails`)
              )}
            </>
          )}

          {vehicleData?.RR?.infractions && (
            <>
              <Divider />
              {DetailsInfos(
                {
                  message: vehicleData?.RR?.infractions?.message,
                },
                translate(
                  'components.dataSourceCards.denatranDriverViolations.headerLabel'
                )
              )}

              {ListInfos(
                vehicleData?.RR?.infractions?.infractionsDetails,
                translate(`${I18N_BASE_PATH}.columns.infractionsDetails`)
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.SC && (
        <DataSourceCardCollapsePanel title="Santa Catarina (SC)" toPrint={toPrint}>
          {vehicleData?.SC?.stampAuthorization && (
            <>
              {DetailsInfos(
                vehicleData?.SC?.stampAuthorization,
                translate(`${I18N_BASE_PATH}.columns.stampAuthorization`)
              )}
            </>
          )}

          {vehicleData?.SC?.debtsList && (
            <>
              <Divider />
              {DetailsInfos(
                {
                  totalDebts: vehicleData?.SC?.debtsList?.totalDebts,
                  detranFee: vehicleData?.SC?.debtsList?.detranFee,
                  dpvatInsurance: vehicleData?.SC?.debtsList?.dpvatInsurance,
                  ipva: vehicleData?.SC?.debtsList?.ipva,
                  fines: vehicleData?.SC?.debtsList?.fines,
                },
                `${translate(`${I18N_BASE_PATH}.columns.debts`)}: ${
                  vehicleData?.SC?.debtsList?.message
                }`
              )}
              <Divider dashed />
              {ListInfos(
                vehicleData?.SC?.debtsList?.finesList,
                translate(`${I18N_BASE_PATH}.columns.fines`)
              )}
            </>
          )}

          {vehicleData?.SC?.notifiedInfractionHistory && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.SC?.notifiedInfractionHistory?.notifiedInfractions,
                `${translate(`${I18N_BASE_PATH}.columns.notifiedInfractionHistory`)}: ${
                  vehicleData?.SC?.notifiedInfractionHistory?.message
                }`
              )}
            </>
          )}

          {vehicleData?.SC?.autuationInfractions && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.SC?.autuationInfractions?.infractions,
                `${translate(`${I18N_BASE_PATH}.columns.autuationInfractions`)}: ${
                  vehicleData?.SC?.autuationInfractions?.message
                }`
              )}
            </>
          )}

          {vehicleData?.SC?.finesList && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.SC?.finesList?.fines,
                `${translate(`${I18N_BASE_PATH}.columns.fines`)}: ${
                  vehicleData?.SC?.finesList?.message
                }`
              )}
            </>
          )}

          {vehicleData?.SC?.lastProcess && (
            <>
              <Divider />
              {DetailsInfos(
                {
                  process: vehicleData?.SC?.lastProcess?.process,
                  interested: vehicleData?.SC?.lastProcess?.interested,
                  startIn: vehicleData?.SC?.lastProcess?.startIn,
                  situation: vehicleData?.SC?.lastProcess?.situation,
                  endIn: vehicleData?.SC?.lastProcess?.endIn,
                  invoiceIssuer: vehicleData?.SC?.lastProcess?.invoiceIssuer,
                  issuerDocumentUF: vehicleData?.SC?.lastProcess?.issuerDocumentUF,
                  emissionDate: vehicleData?.SC?.lastProcess?.emissionDate,
                  invoiceValue: vehicleData?.SC?.lastProcess?.invoiceValue,
                },
                translate(`${I18N_BASE_PATH}.columns.lastProcess`)
              )}
              <Divider dashed />
              {ListInfos(
                vehicleData?.SC?.lastProcess?.history,
                translate(`${I18N_BASE_PATH}.columns.history`)
              )}
            </>
          )}

          {vehicleData?.SC?.infractionAppeals && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.SC?.infractionAppeals?.appeals,
                `${translate(`${I18N_BASE_PATH}.columns.infractionAppeals`)}: ${
                  vehicleData?.SC?.infractionAppeals?.message
                }`
              )}
            </>
          )}

          {vehicleData?.SC?.CEDONotificationEdicts && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.SC?.CEDONotificationEdicts?.notifications,
                `${translate(`${I18N_BASE_PATH}.columns.CEDONotificationEdicts`)}: ${
                  vehicleData?.SC?.CEDONotificationEdicts?.message
                }`
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.SE && (
        <DataSourceCardCollapsePanel title="Sergipe (SE)" toPrint={toPrint}>
          {vehicleData?.SE?.message && (
            <Alert message={vehicleData?.SE?.message} type="info" showIcon />
          )}

          {vehicleData?.SE?.identification && (
            <>
              {DetailsInfos(
                vehicleData?.SE?.identification,
                translate(
                  'components.dataSourceCards.financialInformation.people.components.identification'
                )
              )}
            </>
          )}

          {vehicleData?.SE?.personalData && (
            <>
              <Divider />
              {DetailsInfos(
                vehicleData?.SE?.personalData,
                translate(
                  'pages.private.dashboard.components.charts.funnel.steps.DATA_CONFIRMATION.description'
                )
              )}
            </>
          )}

          {vehicleData?.SE?.vehicleData && (
            <>
              <Divider />
              {DetailsInfos(
                vehicleData?.SE?.vehicleData,
                translate(
                  'pages.private.executions.components.dataSourceCards.basicInfos.components.basicInfosCard.sources.vehicleViolations'
                )
              )}
            </>
          )}

          {vehicleData?.SE?.vehicleSituation && (
            <>
              <Divider />
              {DetailsInfos(
                vehicleData?.SE?.vehicleSituation,
                translate(`${I18N_BASE_PATH}.columns.vehicleSituation`)
              )}
            </>
          )}

          {vehicleData?.SE?.vehicleDriver && (
            <>
              <Divider />
              {DetailsInfos(
                vehicleData?.SE?.vehicleDriver,
                translate('components.dataSourceCards.denatranDriverAltInfos.title')
              )}
            </>
          )}

          {vehicleData?.SE?.debts && (
            <>
              <Divider />
              {DetailsInfos(
                {
                  totalValue: vehicleData?.SE?.debts?.totalValue,
                  pending: vehicleData?.SE?.debts?.pending?.map((message, index) => (
                    <Row justify="space-between" className="mrg-top-10" key={index}>
                      <ColumnTitleValue span={24} value={message || '-'} />
                    </Row>
                  )),
                },
                `${translate(`${I18N_BASE_PATH}.columns.debts`)}: ${
                  vehicleData?.SE?.debts?.message
                }`
              )}
              <Divider dashed />
              {ListInfos(
                vehicleData?.SE?.debts?.debtsDescription,
                translate(`${I18N_BASE_PATH}.columns.description`)
              )}
            </>
          )}

          {vehicleData?.SE?.fines && (
            <>
              <Divider />

              {ListInfos(
                vehicleData?.SE?.fines?.finesDescription,
                `${translate(`${I18N_BASE_PATH}.columns.fines`)}: ${
                  vehicleData?.SE?.fines?.totalValue
                }`
              )}
            </>
          )}

          {vehicleData?.SE?.processes && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.SE?.processes,
                translate('validations.has_no_processes.title')
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}
    </DataSourceCardCollapsePanel>
  );
}

export default VehicleDetranInfosCollapsePanel;
