import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import { Row, Col, Form, Select, Spin, Radio } from 'antd';
import _ from 'lodash';

import { useReports } from 'hooks/reports';
import { useProfileTemplate } from 'hooks/profileTemplate';

import Button from 'components/Button';
import { useComponentNextList } from 'components/List';

import PermissionWrapper from 'routes/PermissionWrapper';
import Wrapper from 'pages/private/wrapper';
import { useWorkflow } from 'hooks/useWorkflow';
import { useAuth } from 'hooks/auth';

import useCreateLinkModal from './components/CreateLinkModal';
import CardLink from './components/CardLink';
import CustomFilters from './components/CustomFilters';

import './styles.less';

const { Option } = Select;

const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelList.components.links.index.links';

const Links = () => {
  const { t: translate } = useTranslation();

  const { loadAllReports, loadingAllReports } = useReports();

  const { getWorkflowsData, data: workflowsData } = useWorkflow();

  const {
    allRecursiveProfileTemplates,
    loadAllProfileTemplates,
    loadingAllProfileTemplates,
  } = useProfileTemplate();

  const { user } = useAuth();

  const refreshListRef = useRef(() => {});

  const [form] = Form.useForm();

  const [query, setQuery] = useQueryParams({
    active: StringParam,
    token: StringParam,
    name: StringParam,
    cpf: StringParam,
    cnpj: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    templateId: StringParam,
    status: StringParam,
    personId: StringParam,
    companyId: StringParam,
    workflowId: StringParam,
    chatbotOnly: StringParam,
    webOnly: StringParam,
  });

  useEffect(() => {
    loadAllReports();
  }, [loadAllReports]);

  useEffect(() => {
    loadAllProfileTemplates({
      active: 'all',
    });
  }, [loadAllProfileTemplates]);

  const customLoading = useMemo(() => {
    return loadingAllReports || loadingAllProfileTemplates;
  }, [loadingAllProfileTemplates, loadingAllReports]);

  const fetchWorkflows = useCallback(async () => {
    await getWorkflowsData({ tenantId: user?.tenantId });
  }, [getWorkflowsData, user]);

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  const {
    componentList,
    updateParams,
    refreshList,
    loading,
    data,
  } = useComponentNextList({
    component: CardLink,
    rowKey: '_id',
    shouldApplyDefaultMargin: false,
    getParams: {
      url: '/onboardings',
      config: {
        params: {
          active: query?.active !== 'all' ? query?.active : undefined,
          _token: query?.token,
          _name: query?.name,
          _cpf: query?.cpf,
          _cnpj: query?.cnpj,
          _startDate: query?.startDate,
          _endDate: query?.endDate,
          _templateId: query?.templateId,
          _status: query?.status,
          _personId: query?.personId,
          _companyId: query?.companyId,
          _workflowId: query?.workflowId,
          _webOnly: query?.webOnly,
          _chatbotOnly: query?.chatbotOnly,
        },
      },
    },
    customLoader: customLoading,
    componentProps: {
      allProfileTemplates: allRecursiveProfileTemplates,
      workflowsData,
    },
  });

  const { openModal: openCreateLinkModal, CreateLinkModal } = useCreateLinkModal({
    refreshListRef: refreshList,
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  useEffect(() => {
    const activeValue = query?.active;

    updateParams({
      url: '/onboardings',
      config: {
        params: {
          active: activeValue !== 'all' ? activeValue : undefined,
          _token: query?.token,
          _name: query?.name,
          _cpf: query?.cpf,
          _cnpj: query?.cnpj,
          _startDate: query?.startDate,
          _endDate: query?.endDate,
          _templateId: query?.templateId,
          _status: query?.status,
          _personId: query?.personId,
          _companyId: query?.companyId,
          _workflowId: query?.workflowId,
          _webOnly: query?.webOnly,
          _chatbotOnly: query?.chatbotOnly,
          _cursor: true,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      let startDate;
      let endDate;
      if (values.createdAt) {
        let tempDate = new Date(values?.createdAt[0]);
        tempDate.setHours(0, 0, 0, 0);
        startDate = tempDate.toISOString();

        tempDate = new Date(values?.createdAt[1]);
        tempDate.setHours(23, 59, 59, 999);
        endDate = tempDate.toISOString();
      }

      setQuery({
        active: values.active && values.active !== 'all' ? values.active : undefined,
        token: values?.token ? values?.token.trim() : undefined,
        name: values?.name ? values?.name.trim() : undefined,
        cpf: values?.cpf ? values?.cpf.trim() : undefined,
        cnpj: values?.cnpj ? values?.cnpj.trim() : undefined,
        templateId: values?.templateId ? values?.templateId.trim() : undefined,
        status: values?.status ? values?.status.trim() : undefined,
        personId: values?.personId ? values?.personId.trim() : undefined,
        companyId: values?.companyId ? values?.companyId.trim() : undefined,
        workflowId: values?.workflowId ? values?.workflowId.trim() : undefined,
        chatbotOnly: values?.chatbotOnly ?? undefined,
        webOnly: values.webOnly ?? undefined,
        startDate,
        endDate,
      });
    }, 500),
    []
  );

  return (
    <Wrapper>
      <div id="onboarding-links-page">
        {CreateLinkModal}
        <Form
          form={form}
          layout="inline"
          initialValues={{
            active: query?.active || 'true',
          }}
          onValuesChange={handleFormChange}
          className="no-mrg header-onboarding-links"
        >
          <Row align="middle">
            <Col span={12} className="section-left">
              <Trans i18nKey={`${I18N_BASE_PATH}.pageTitle.subtitle`}>
                Onboarding links are responsible for capturing data in your user
                onboarding.
              </Trans>
            </Col>
            <Col span={8}>
              <CustomFilters form={form} query={query} />
            </Col>
            <PermissionWrapper requiredPermissions={['onboardings:create']}>
              <Col span={4}>
                <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block mrg-top-20">
                  <Button
                    shape="round"
                    type="primary"
                    style={{ height: 39, width: 130 }}
                    outlined
                    className="flex no-mrg-btm center font-size-13"
                    onClick={openCreateLinkModal}
                  >
                    <i className="caf-ic_add font-size-18 mrg-right-5" />
                    <strong>{translate(`${I18N_BASE_PATH}.form.button`)}</strong>
                  </Button>
                </div>
              </Col>
            </PermissionWrapper>
            <div className="border-top" />
            <Col span={24} className="flex-align-center">
              {user?.accountData?.featureFlags?.onboardingChatbot && (
                <div className="links-onboarding">
                  <span className="subtitle-footer">
                    {translate(
                      `${I18N_BASE_PATH}.components.customFilters.labels.category`,
                      'Category:'
                    )}
                  </span>
                  <Form.Item>
                    <Radio
                      checked={query?.webOnly !== '1' && query?.chatbotOnly !== '1'}
                      onChange={() => {
                        setQuery((value) => ({
                          ...value,
                          chatbotOnly: '0',
                          webOnly: '0',
                        }));
                      }}
                    >
                      {translate(
                        `${I18N_BASE_PATH}.components.customFilters.labels.both`,
                        'Both'
                      )}
                    </Radio>
                  </Form.Item>
                  <Form.Item initialValue={query?.webOnly}>
                    <Radio
                      value={query.webOnly}
                      checked={query?.webOnly === '1'}
                      onChange={() => {
                        setQuery((value) => ({
                          ...value,
                          chatbotOnly: '0',
                          webOnly: '1',
                        }));
                      }}
                    >
                      {translate(
                        `${I18N_BASE_PATH}.components.customFilters.labels.onboardingLink`,
                        'Onboarding link'
                      )}
                    </Radio>
                  </Form.Item>
                  <Form.Item initialValue={query?.chatbotOnly}>
                    <Radio
                      value={query?.chatbotOnly}
                      checked={query?.chatbotOnly === '1'}
                      onChange={() => {
                        setQuery((value) => ({
                          ...value,
                          chatbotOnly: '1',
                          webOnly: '0',
                        }));
                      }}
                    >
                      {translate(
                        `${I18N_BASE_PATH}.components.customFilters.labels.whatsapp`,
                        'Whatsapp'
                      )}
                    </Radio>
                  </Form.Item>
                </div>
              )}
              <div className="listing-by-status">
                <span className="subtitle-footer margin-right-10">
                  {translate(
                    `${I18N_BASE_PATH}.components.customFilters.labels.situation`,
                    'Situation:'
                  )}
                </span>
                <Form.Item name="active" className="no-mrg-btm">
                  <Select
                    bordered={false}
                    defaultValue="true"
                    className="select-items"
                    style={{ width: '80px' }}
                  >
                    <Option value="true">
                      {translate(`${I18N_BASE_PATH}.form.type.options.active`)}
                    </Option>
                    <Option value="false">
                      {translate(`${I18N_BASE_PATH}.form.type.options.inactive`)}
                    </Option>
                    <Option value="all">
                      {translate(`${I18N_BASE_PATH}.form.type.options.all`)}
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
        <div className="list-wrapper">
          {customLoading || (loading && !data) ? (
            <Row align="middle" justify="center">
              <Col>
                <Spin className="mrg-top-45" />
              </Col>
            </Row>
          ) : (
            <div>{componentList}</div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Links;
