import { Spin } from 'antd';
import React from 'react';

const DashboardLoading = () => {
  return (
    <div className="flex center" style={{ height: '80vh' }}>
      <Spin className="mrg-top-20 mrg-btm-25 mrg-horizon-25" />
    </div>
  );
};

export default DashboardLoading;
