import React, { useMemo } from 'react';
import EmptyExecutionState from 'components/EmptyExecutionState';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography } from 'antd';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import VehicleInfosCollapsePanel from './VehicleInfosCollapsePanel';
import VehicleRestrictionsInfosCollapsePanel from './VehicleRestrictionsInfosCollapsePanel';
import VehiclesDefaultInfosCollapsePanel from './VehiclesDefaultInfosCollapsePanel';
import VehicleSefazInfosCollapsePanel from './VehicleSefazInfosCollapsePanel';
import VehicleDetranInfosCollapsePanel from './VehicleDetranInfosCollapsePanel';

const { Title } = Typography;

export const VehicleViolations = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const data = useMemo(() => source?.data || {}, [source]);

  const vehicle = useMemo(() => {
    const {
      plate,
      chassis,
      chassisSituation,
      renavam,
      origin,
      cmtVehicle,
      srfAgencyCode,
      engineCapacity,
      vehicleCategory,
      vehicleType,
      vehicleKind,
      vehicleColor,
      vehicleFuel,
      vehiclePower,
      vehicleImportOperationType,
      passengersQuantity,
      gearBox,
      modelBrand,
      modelYear,
      manufactureYear,
      quantityAxles,
      auxiliaryAxeNumber,
      rearAxeNumber,
      engineNumber,
      loadCapacity,
      bodyworkName,
      bodyworkNumber,
      bodyworkType,
      assemblyType,
      redaNumber,
      pbtVehicle,
      city,
      state,
      registrationDate,
      situation,
      taxRestrictionLimitDate,
      ownerName,
      ownerDocumentType,
      ownerIdentificationNumber,
      diNumber,
      diRegistrationDate,
      identificationNumberBilling,
      importProcessNumber,
      importerIdentificationNumber,
      importerDocumentType,
      billingDocumentType,
      billingDestinationState,
      restrictionAgentName,
      tenantRestriction,
      inclusionDateRestriction,
      tenantDocumentRestriction,
      updateDate,
      additionalInformation,
    } = data;

    return {
      plate,
      chassis,
      chassisSituation,
      renavam,
      origin,
      cmtVehicle,
      srfAgencyCode,
      engineCapacity,
      vehicleCategory,
      vehicleType,
      vehicleKind,
      vehicleColor,
      vehicleFuel,
      vehiclePower,
      vehicleImportOperationType,
      passengersQuantity,
      gearBox,
      modelBrand,
      modelYear,
      manufactureYear,
      quantityAxles,
      auxiliaryAxeNumber,
      rearAxeNumber,
      engineNumber,
      loadCapacity,
      bodyworkName,
      bodyworkNumber,
      bodyworkType,
      assemblyType,
      redaNumber,
      pbtVehicle,
      city,
      state,
      registrationDate,
      situation,
      taxRestrictionLimitDate,
      ownerName,
      ownerDocumentType,
      ownerIdentificationNumber,
      diNumber,
      diRegistrationDate,
      identificationNumberBilling,
      importProcessNumber,
      importerIdentificationNumber,
      importerDocumentType,
      billingDocumentType,
      billingDestinationState,
      restrictionAgentName,
      tenantRestriction,
      inclusionDateRestriction,
      tenantDocumentRestriction,
      updateDate,
      additionalInformation,
    };
  }, [data]);

  const restrictions = useMemo(() => data?.restrictions, [data]);

  const prf = useMemo(() => {
    if (!data?.prf) return null;

    return {
      ...data?.prf,
      infoType: 'prf',
      debtPaid: data?.prf?.debtPaid ? 'Sim' : 'Não',
    };
  }, [data]);

  const dnit = useMemo(() => {
    if (!data?.dnit) return null;

    return {
      ...data?.dnit,
      infoType: 'dnit',
    };
  }, [data]);

  const sefaz = useMemo(() => {
    if (!data?.sefaz) return null;

    return {
      ...data?.sefaz,
      infoType: 'sefaz',
    };
  }, [data]);
  const detran = useMemo(() => {
    if (!data?.detran) return null;

    return data?.detran;
  }, [data]);

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_driver"
      title={translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.basicInfosCard.sources.vehicleViolations',
        'Vehicle query on Denatran'
      )}
      toPrint={toPrint}
    >
      {statusCode !== '01' ? (
        <EmptyExecutionState
          statusCode={statusCode}
          message={source?.message || source?.data?.message}
        />
      ) : (
        <>
          <Row>
            <Col span={24}>
              <Title level={4}>{vehicle?.modelBrand}</Title>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <VehicleInfosCollapsePanel vehicle={vehicle} toPrint={toPrint} />
            </Col>
          </Row>

          {restrictions && (
            <Row>
              <Col span={24}>
                <VehicleRestrictionsInfosCollapsePanel
                  restrictions={restrictions}
                  toPrint={toPrint}
                />
              </Col>
            </Row>
          )}
          {prf && (
            <Row>
              <Col span={24}>
                <VehiclesDefaultInfosCollapsePanel vehicleInfo={prf} toPrint={toPrint} />
              </Col>
            </Row>
          )}
          {dnit && (
            <Row>
              <Col span={24}>
                <VehiclesDefaultInfosCollapsePanel vehicleInfo={dnit} toPrint={toPrint} />
              </Col>
            </Row>
          )}
          {sefaz && (
            <Row>
              <Col span={24}>
                <VehicleSefazInfosCollapsePanel vehicleInfo={sefaz} toPrint={toPrint} />
              </Col>
            </Row>
          )}
          {detran && (
            <Row>
              <Col span={24}>
                <VehicleDetranInfosCollapsePanel vehicleInfo={detran} toPrint={toPrint} />
              </Col>
            </Row>
          )}
        </>
      )}
    </DataSourceCardCollapsePanel>
  );
};
