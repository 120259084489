import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useQueryParams, StringParam } from 'use-query-params';
import { Collapse, Select } from 'antd';

import { useProfileTemplate } from 'hooks/profileTemplate';

const { Panel } = Collapse;
const { Option } = Select;

const ProfileTemplatesFilter = ({ value, title, onChange }) => {
  const { t: translate } = useTranslation();

  const {
    allRecursiveProfileTemplates,
    loadingAllProfileTemplates,
    loadAllProfileTemplates,
  } = useProfileTemplate();

  const [query] = useQueryParams({
    _type: StringParam,
  });

  const [searchProfileTemplate, setSearchProfileTemplate] = useState(null);
  const [data, setData] = useState();

  useEffect(() => {
    loadAllProfileTemplates({
      active: 'all',
    });
  }, [loadAllProfileTemplates]);

  const clearData = useCallback(() => setData([]), []);

  const onCustomerSearch = useCallback(
    async (searchTerm = '') => {
      if (searchTerm.trim().length < 2) {
        setSearchProfileTemplate(null);
        clearData();

        setData(allRecursiveProfileTemplates);
        return;
      }

      setSearchProfileTemplate(searchTerm);

      let newData = allRecursiveProfileTemplates;

      if (query._type) {
        newData = newData.filter((pfTemplate) => pfTemplate.type === query._type);
      }

      if (searchTerm)
        newData = newData.filter((pfTemplate) =>
          pfTemplate?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
        );

      setData(newData);
    },
    [allRecursiveProfileTemplates, clearData, query]
  );

  return (
    <Collapse
      defaultActiveKey={['profileTemplates']}
      ghost
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <div>
          <i className={isActive ? 'caf-ic_arrow_up' : 'caf-ic_arrow_down'} />
        </div>
      )}
    >
      <Panel header={title} key="profileTemplates">
        <Select
          mode="multiple"
          labelInValue
          value={Object.keys(value).map((key) => ({
            key,
            value: key,
            label: value[key],
          }))}
          placeholder="Busque por nome"
          optionLabelProp="label"
          dropdownClassName="multiselect-filters"
          filterOption={false}
          listHeight={320}
          dropdownAlign={{ offset: [0, 2] }}
          removeIcon={<i className="caf-ic_circle_false" />}
          menuItemSelectedIcon={<i className="caf-ic_checkmark" />}
          loading={loadingAllProfileTemplates}
          notFoundContent={
            !loadingAllProfileTemplates && !data?.length ? (
              <span>
                {searchProfileTemplate
                  ? translate(
                      'components.filters.profileTemplatesFilter.collapse.panel.select.notFoundContent.searchProfileTemplateActive',
                      { searchProfileTemplate: `"${searchProfileTemplate}"` }
                    )
                  : translate(
                      'components.filters.profileTemplatesFilter.collapse.panel.select.notFoundContent.searchProfileTemplateInactive'
                    )}
              </span>
            ) : null
          }
          onSearch={onCustomerSearch}
          onBlur={() => {
            clearData();
            setSearchProfileTemplate(null);
          }}
          onChange={(values) => {
            const profileTemplates = {};

            values.forEach((item) => {
              profileTemplates[item.value] = item.label;
            });
            onChange(profileTemplates);
            clearData();
            setSearchProfileTemplate(null);
          }}
        >
          {data?.map((d) => (
            <Option key={d._id} label={d.name} value={d._id}>
              <div className="multiselect-option">
                <span className="option-title">{d.name}</span>
              </div>
            </Option>
          ))}
        </Select>
      </Panel>
    </Collapse>
  );
};

ProfileTemplatesFilter.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ProfileTemplatesFilter;
