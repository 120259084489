/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Tabs, Tag } from 'antd';
import PageTitle from 'components/PageTitle';
import { useHistory } from 'react-router-dom';
import {
  Code as CodeIcon,
  Sidebar,
  Smartphone as SmartphoneIcon,
} from '@combateafraude/icons/general';

import PermissionWrapper from 'routes/PermissionWrapper';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { useAuth } from 'hooks/auth';

import useDynamoComponentList from 'components/List/hooks/useDynamoComponentList';
import { CardWrapper } from 'components/CardWrapper';

import androidLogo from 'assets/images/android-logo.png';
import iosLogo from 'assets/images/ios-logo.png';
import { hasRequiredPermissions } from 'routes/permissionFunctions';

import useGenerateAccessKeys from './components/GenerateAccessKey';
import AccessKeysCard from './components/AccessKeysCard';

import Wrapper from '../wrapper';
import IntegrationGuide from './IntegrationGuide';
import Tokens from './Tokens';

import './styles.less';

const { Title, Link } = Typography;
const { TabPane } = Tabs;

const AccessKeys = () => {
  const refreshListRef = useRef(() => {});
  const history = useHistory();
  const { t: translate } = useTranslation();

  const { user: loggedUser } = useAuth();

  const { openModal, GenerateAccessKeyModal } = useGenerateAccessKeys({ refreshListRef });

  const { componentList, refreshList } = useDynamoComponentList({
    component: AccessKeysCard,
    rowKey: 'client_secret',
    getParams: {
      url: `${process.env.REACT_APP_BASE_URL_MANAGEMENT_API}/access-keys`,
      config: {
        params: { tenantId: loggedUser?.tenantId },
      },
    },
  });

  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  const tabHeader = useCallback(
    (title, icon) => (
      <div className="flex align-center text-dark">
        <div className="font-size-22 mrg-right-5">{icon}</div>
        <strong className="text-semibold font-size-16">{title}</strong>
      </div>
    ),
    []
  );

  const handleTabClick = useCallback(
    (key) => {
      history.push(`${key}`);
    },
    [history]
  );
  return (
    <>
      {GenerateAccessKeyModal}

      <Wrapper id="access-keys-component">
        <CardWrapper hasPadding>
          <Tabs onChange={handleTabClick} defaultActiveKey={history.location.pathname}>
            {hasRequiredPermissions(loggedUser, ['integration-guide:read'], []) ? (
              !hasFeatureFlag('isWorkflowBuilder', loggedUser) ? (
                <>
                  <TabPane
                    tab={
                      <div className="flex align-center text-dark">
                        {tabHeader(
                          translate('pages.private.accessKeys.index.integrationGuide'),
                          <Sidebar />
                        )}
                      </div>
                    }
                    key="/integration-guide-deprecated"
                  >
                    <IntegrationGuide />
                  </TabPane>
                  <TabPane
                    tab={tabHeader(
                      translate('pages.private.accessKeys.index.tokens'),
                      <CodeIcon />
                    )}
                    key="/integration-guide"
                  >
                    <Tokens />
                  </TabPane>
                </>
              ) : (
                <TabPane
                  tab={tabHeader(
                    translate('pages.private.accessKeys.index.tokens'),
                    <CodeIcon />
                  )}
                  key="/integration-guide"
                >
                  <Tokens />
                </TabPane>
              )
            ) : (
              <></>
            )}
            {hasRequiredPermissions(
              loggedUser,
              ['access-keys:read'],
              [],
              false,
              'management'
            ) ? (
              <TabPane
                tab={tabHeader(
                  translate('pages.private.accessKeys.index.mobileKey'),
                  <SmartphoneIcon />
                )}
                key="/access-keys"
              >
                <>
                  <div className="flex align-center gx-w-100 mrg-btm-40 space-between">
                    <PageTitle
                      title={translate('pages.private.accessKeys.index.pageTitle.title')}
                      subtitle={translate(
                        'pages.private.accessKeys.index.pageTitle.subtitle'
                      )}
                    />

                    <div className="flex end-center mrg-right-20">
                      <Title level={4} className="no-mrg-vertical mrg-right-15">
                        {translate('pages.private.accessKeys.index.docs')}
                      </Title>
                      <Link
                        href="https://docs.combateafraude.com/docs/mobile/android/getting-started/"
                        target="_blank"
                        className="mrg-right-10"
                      >
                        <img
                          src={androidLogo}
                          alt={translate(
                            'pages.private.accessKeys.index.alts.androidLogo'
                          )}
                          className="android-logo grayscale-95"
                        />
                      </Link>
                      <Link
                        href="https://docs.combateafraude.com/docs/mobile/ios/getting-started/"
                        target="_blank"
                      >
                        <img
                          src={iosLogo}
                          alt={translate('pages.private.accessKeys.index.alts.iosLogo')}
                          className="ios-logo grayscale-95"
                        />
                      </Link>
                      <div className="flex center">
                        <PermissionWrapper
                          product="management"
                          requiredPermissions={['access-keys:create']}
                        >
                          <Button type="primary" onClick={openModal}>
                            + {translate('pages.private.accessKeys.index.newKey')}
                          </Button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </div>
                  {componentList}
                </>
              </TabPane>
            ) : (
              <></>
            )}
          </Tabs>
        </CardWrapper>
      </Wrapper>
    </>
  );
};

export default AccessKeys;
