import { Col, Row, Divider, Typography, Alert } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import '../styles.less';

const { Title } = Typography;

const I18N_BASE_PATH = 'components.dataSourceCards.denatranVehicleInfos';

function VehicleSefazInfosCollapsePanel({ vehicleInfo = {}, toPrint = false }) {
  const { t: translate } = useTranslation();

  const vehicleData = useMemo(() => vehicleInfo, [vehicleInfo]);

  const customHeader = useMemo(() => {
    return (
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col span={18}>
          <i className="caf-ic_driver" />
          <span className="text-dark text-bold pdd-left-10">
            {translate(`${I18N_BASE_PATH}.columns.sefazTitle`).toUpperCase()}
          </span>
        </Col>
      </Row>
    );
  }, [translate]);

  const ListInfos = (data, title) => {
    return (
      <>
        {title && (
          <Row justify="center" className="mrg-top-10">
            <Col span={24}>
              <Title level={5}>{title}</Title>
            </Col>
          </Row>
        )}

        {data?.map((dt, index) => {
          return (
            <React.Fragment key={index}>
              <Row justify="space-between" className="mrg-top-10">
                {Object.keys(dt).map((dtKey) => (
                  <ColumnTitleValue
                    span={8}
                    key={dtKey}
                    className="inline-infos"
                    title={`${translate(`${I18N_BASE_PATH}.columns.${dtKey}`)}:`}
                    value={dt[dtKey] || '-'}
                  />
                ))}
              </Row>
              {index < data?.length - 1 && <Divider dashed />}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const DetailsInfos = (data, title) => {
    return (
      <>
        <Row justify="center" className="mrg-top-10">
          <Col span={24}>
            <Title level={5}>{title}</Title>
          </Col>
        </Row>
        <Row justify="space-between" className="mrg-top-10">
          {Object.keys(data).map((dtKey) => {
            return (
              <ColumnTitleValue
                span={8}
                key={dtKey}
                className="inline-infos"
                title={`${translate(`${I18N_BASE_PATH}.columns.${dtKey}`)}:`}
                value={data[dtKey] || '-'}
              />
            );
          })}
        </Row>
      </>
    );
  };

  const ExtractsInfos = (extracts, title) => {
    return (
      <>
        {title && (
          <Row justify="center" className="mrg-top-10">
            <Col span={24}>
              <Title level={5}>{title}</Title>
            </Col>
          </Row>
        )}

        {extracts?.map((extract, index) => (
          <React.Fragment key={index}>
            <Row justify="space-between" className="mrg-top-10">
              {Object.keys(extract).map((key) => {
                if (key !== 'launches') {
                  return (
                    <ColumnTitleValue
                      key={key}
                      span={6}
                      title={`${translate(`${I18N_BASE_PATH}.columns.${key}`)}:`}
                      value={extract[key] || '-'}
                    />
                  );
                }
                return null;
              })}
            </Row>

            {extract?.launches && (
              <>
                <Row justify="center" className="mrg-top-10">
                  <Col span={24}>
                    <Title level={5}>
                      {translate(`${I18N_BASE_PATH}.columns.launches`)}
                    </Title>
                  </Col>
                </Row>
                {extract?.launches?.map((launch, launchIndex) => (
                  <React.Fragment key={launchIndex}>
                    <Row justify="space-between" className="mrg-top-10" key={launchIndex}>
                      {Object.keys(launch).map((launchKey) => (
                        <ColumnTitleValue
                          key={launchKey}
                          span={6}
                          title={`${translate(
                            `${I18N_BASE_PATH}.columns.${launchKey}`
                          )}:`}
                          value={launch[launchKey] || '-'}
                        />
                      ))}
                    </Row>

                    {launchIndex < extract?.launches?.length - 1 && <Divider dashed />}
                  </React.Fragment>
                ))}
              </>
            )}

            {index < extracts?.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </>
    );
  };

  const IpvaActiveDebtInfos = (ipvaActiveDebt, title) => {
    return (
      <>
        {title && (
          <Row justify="center" className="mrg-top-10">
            <Col span={24}>
              <Title level={5}>{title}</Title>
            </Col>
          </Row>
        )}

        {ipvaActiveDebt?.map((debt, index) => (
          <React.Fragment key={index}>
            <Row justify="space-between" className="mrg-top-10">
              {Object.keys(debt).map((key) => {
                if (key !== 'details') {
                  return (
                    <ColumnTitleValue
                      key={key}
                      span={6}
                      title={`${translate(`${I18N_BASE_PATH}.columns.${key}`)}:`}
                      value={debt[key] || '-'}
                    />
                  );
                }
                return null;
              })}
            </Row>

            {debt?.details && (
              <>
                <Row justify="center" className="mrg-top-10">
                  <Col span={24}>
                    <Title level={5}>
                      {translate(`${I18N_BASE_PATH}.columns.details`)}
                    </Title>
                  </Col>
                </Row>
                {debt.details.map((detail, detailIndex) => (
                  <Row justify="space-between" className="mrg-top-10" key={detailIndex}>
                    <ColumnTitleValue span={6} value={detail || '-'} />
                  </Row>
                ))}
              </>
            )}

            {index < ipvaActiveDebt?.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <DataSourceCardCollapsePanel customHeader={customHeader} toPrint={toPrint}>
      {vehicleData?.AL && (
        <DataSourceCardCollapsePanel title="Alagoas (AL)" toPrint={toPrint}>
          <>
            {vehicleData?.AL?.message && (
              <Alert message={vehicleData?.AL?.message} type="info" showIcon />
            )}

            {DetailsInfos(
              {
                owner: vehicleData?.AL?.owner || '-',
                exercise: vehicleData?.AL?.exercise || '-',
                renavam: vehicleData?.AL?.renavam || '-',
                plate: vehicleData?.AL?.plate || '-',
                modelBrand: vehicleData?.AL?.modelBrand || '-',
                manufactureYear: vehicleData?.AL?.manufactureYear || '-',
                ipvaTotal: vehicleData?.AL?.ipvaTotal || '-',
                bonusNFC: vehicleData?.AL?.bonusNFC || '-',
              },
              translate('services.vehicle_violations.title')
            )}
          </>

          {vehicleData?.AL?.installments && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.AL?.installments.details,
                translate(`${I18N_BASE_PATH}.columns.installments`)
              )}
            </>
          )}

          {vehicleData?.AL?.payments && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.AL?.payments.details,
                translate(`${I18N_BASE_PATH}.columns.payments`)
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.AM && (
        <DataSourceCardCollapsePanel title="Amazonas (AM)" toPrint={toPrint}>
          <>
            {vehicleData?.AM?.message && (
              <Alert message={vehicleData?.AM?.message} type="info" showIcon />
            )}

            {vehicleData?.AM?.exercises && (
              <>
                {ListInfos(
                  vehicleData?.AM?.exercises,
                  translate(`${I18N_BASE_PATH}.columns.exercises`)
                )}
              </>
            )}

            {vehicleData?.AM?.ipvas && (
              <>
                <Divider />
                {ListInfos(vehicleData?.AM?.ipvas?.ipvaDetails, 'IPVA')}
              </>
            )}
          </>
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.BA && (
        <DataSourceCardCollapsePanel title="Bahia (BA)" toPrint={toPrint}>
          <>
            {DetailsInfos(
              {
                message: vehicleData?.BA?.message || '-',
                plate: vehicleData?.BA?.plate || '-',
                model: vehicleData?.BA?.model || '-',
              },
              translate('services.vehicle_violations.title')
            )}
          </>

          {vehicleData?.BA?.ipvaPending?.length && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.BA?.ipvaPending,
                translate(`${I18N_BASE_PATH}.columns.ipvaPending`)
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.CE && (
        <DataSourceCardCollapsePanel title="Ceará (CE)" toPrint={toPrint}>
          {vehicleData?.CE && (
            <>
              {vehicleData?.CE?.message && (
                <Alert message={vehicleData?.CE?.message} type="info" showIcon />
              )}

              {DetailsInfos(
                {
                  message: vehicleData?.CE?.message || '-',
                  chassis: vehicleData?.CE?.vehicle?.chassis || '-',
                  plate: vehicleData?.CE?.vehicle?.plate || '-',
                  renavam: vehicleData?.CE?.vehicle?.renavam || '-',
                  modelBrand: vehicleData?.CE?.vehicle?.modelBrand || '-',
                  manufactureYear: vehicleData?.CE?.vehicle?.manufactureYear || '-',
                  modelYear: vehicleData?.CE?.vehicle?.modelYear || '-',
                  city: vehicleData?.CE?.vehicle?.city || '-',
                },
                translate('services.vehicle_violations.title')
              )}
            </>
          )}

          {vehicleData?.CE?.vehicleDebts && (
            <>
              <Divider />

              {ListInfos(
                vehicleData?.CE?.vehicleDebts,
                translate(`${I18N_BASE_PATH}.columns.debts`)
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.DF && (
        <DataSourceCardCollapsePanel title="Distrito Federal (DF)" toPrint={toPrint}>
          <>
            {vehicleData?.DF?.message && (
              <Alert message={vehicleData?.DF?.message} type="info" showIcon />
            )}

            {DetailsInfos(
              {
                renavam: vehicleData?.DF?.renavam || '-',
                plateChassis: vehicleData?.DF?.plateChassis || '-',
                nameSocialReason: vehicleData?.DF?.nameSocialReason || '-',
                vehicleCategory: vehicleData?.DF?.vehicleCategory || '-',
                vehicleModel: vehicleData?.DF?.vehicleModel || '-',
                vehicleType: vehicleData?.DF?.vehicleType || '-',
              },
              translate('services.vehicle_violations.title')
            )}
          </>

          {vehicleData?.DF?.ipvaDebts && (
            <>
              <Divider />
              {ListInfos(vehicleData?.DF?.ipvaDebts, 'IPVA')}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.MA && (
        <DataSourceCardCollapsePanel title="Maranhão (MA)" toPrint={toPrint}>
          <>
            {DetailsInfos(
              {
                message: vehicleData?.MA?.message || '-',
                ownerName: vehicleData?.MA?.owner?.name || '-',
                ownerDocument: vehicleData?.MA?.owner?.cpfCnpj || '-',
                renavam: vehicleData?.MA?.renavam || '-',
                chassis: vehicleData?.MA?.chassis || '-',
                modelBrand: vehicleData?.MA?.modelBrand || '-',
                plate: vehicleData?.MA?.plate || '-',
                color: vehicleData?.MA?.color || '-',
                city: vehicleData?.MA?.city || '-',
              },
              translate('services.vehicle_violations.title')
            )}
          </>

          {vehicleData?.MA?.ipvaDebts?.debts?.length && (
            <>
              <Divider />

              {ListInfos(
                vehicleData?.MA?.ipvaDebts?.debts,
                `IPVA: ${vehicleData?.MA?.ipvaDebts?.message}`
              )}
            </>
          )}

          {vehicleData?.MA?.otherDebts?.debts && (
            <>
              <Divider />

              {ListInfos(
                vehicleData?.MA?.otherDebts?.debts,
                `${translate(`${I18N_BASE_PATH}.columns.debts`)}: ${
                  vehicleData?.MA?.otherDebts?.message
                }`
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.MT && (
        <DataSourceCardCollapsePanel title="Mato Grosso (MT)" toPrint={toPrint}>
          <>
            {vehicleData?.MT?.message && (
              <Alert message={vehicleData?.MT?.message} type="info" showIcon />
            )}

            {DetailsInfos(
              {
                model: vehicleData?.MT?.model || '-',
                manufactureYear: vehicleData?.MT?.manufactureYear || '-',
                chassis: vehicleData?.MT?.chassis || '-',
                owner: vehicleData?.MT?.owner || '-',
                plate: vehicleData?.MT?.plate || '-',
                renavam: vehicleData?.MT?.renavam || '-',
              },
              translate('services.vehicle_violations.title')
            )}
          </>

          {vehicleData?.MT?.extracts && (
            <>
              <Divider />

              {ExtractsInfos(
                vehicleData?.MT?.extracts,
                translate(`${I18N_BASE_PATH}.columns.extracts`)
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.MG && (
        <DataSourceCardCollapsePanel title="Minas Gerais (MG)" toPrint={toPrint}>
          {vehicleData?.MG?.hasAlert && (
            <>
              <Col span={24}>
                {vehicleData?.MG?.alert?.map((message, index) => (
                  <Alert key={index} message={message} type="warning" showIcon />
                ))}
              </Col>
            </>
          )}

          {vehicleData?.MG?.hasVehicleData && (
            <>
              {DetailsInfos(
                vehicleData?.MG?.vehicleData,
                translate('services.vehicle_violations.title')
              )}
            </>
          )}

          {vehicleData?.MG?.hasExercise && (
            <>
              <Divider />
              {ListInfos(vehicleData?.MG?.exercise, 'Exercícios')}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.PA && (
        <DataSourceCardCollapsePanel title="Pará (PA)" toPrint={toPrint}>
          <>
            {DetailsInfos(
              {
                message: vehicleData?.PA?.message || '-',
                owner: vehicleData?.PA?.owner || '-',
                plate: vehicleData?.PA?.plate || '-',
                color: vehicleData?.PA?.color || '-',
                chassis: vehicleData?.PA?.chassis || '-',
                model: vehicleData?.PA?.model || '-',
                type: vehicleData?.PA?.type || '-',
                renavam: vehicleData?.PA?.renavam || '-',
                year: vehicleData?.PA?.year || '-',
              },
              translate('services.vehicle_violations.title')
            )}
          </>

          {vehicleData?.PA?.ipva && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.PA?.ipva?.ipvaExercise,
                `IPVA: R$ ${vehicleData?.PA?.ipva?.total || '0,00'}`
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.PB && (
        <DataSourceCardCollapsePanel title="Paraíba (PB)" toPrint={toPrint}>
          <>
            {vehicleData?.PB?.message && (
              <Alert message={vehicleData?.PB?.message} type="info" showIcon />
            )}

            {DetailsInfos(
              {
                plate: vehicleData?.PB?.plate || '-',
                renavam: vehicleData?.PB?.renavam || '-',
                owner: vehicleData?.PB?.owner || '-',
              },
              translate('services.vehicle_violations.title')
            )}
          </>

          {vehicleData?.PB?.ipvaDebts && (
            <>
              <Divider />
              {ListInfos(vehicleData?.PB?.ipvaDebts, 'IPVA')}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.PI && (
        <DataSourceCardCollapsePanel title="Piauí (PI)" toPrint={toPrint}>
          <>
            {vehicleData?.PI?.message && (
              <Alert message={vehicleData?.PI?.message} type="info" showIcon />
            )}

            {DetailsInfos(
              {
                renavam: vehicleData?.PI?.renavam || '-',
                plate: vehicleData?.PI?.plate || '-',
                situation: vehicleData?.PI?.situation || '-',
                originDate: vehicleData?.PI?.originDate || '-',
                cpfCnpj: vehicleData?.PI?.owner?.cpfCnpj || '-',
                nameSocialReason: vehicleData?.PI?.owner?.nameSocialReason || '-',
                address: vehicleData?.PI?.owner?.address || '-',
                zipCode: vehicleData?.PI?.owner?.zipCode || '-',
                city: vehicleData?.PI?.owner?.city || '-',
                state: vehicleData?.PI?.owner?.state || '-',
              },
              translate('services.vehicle_violations.title')
            )}
          </>

          {vehicleData?.PI?.ipva && (
            <>
              <Divider />
              {ListInfos(vehicleData?.PI?.ipva.ipvaDescriptions, 'IPVA')}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.RS && (
        <DataSourceCardCollapsePanel title="Rio Grande do Sul (RS)" toPrint={toPrint}>
          <>
            {DetailsInfos(
              {
                message: vehicleData?.RS?.message || '-',
                plate: vehicleData?.RS?.plate || '-',
                renavam: vehicleData?.RS?.renavam || '-',
                modelBrandManufactureYear:
                  vehicleData?.RS?.modelBrandManufactureYear || '-',
              },
              translate('services.vehicle_violations.title')
            )}
          </>

          {vehicleData?.RS?.ipva?.length && (
            <>
              <Divider />
              {ListInfos(vehicleData?.RS?.ipva, 'IPVA')}
            </>
          )}

          {vehicleData?.RS?.ipvaActiveDebt && (
            <>
              {IpvaActiveDebtInfos(
                vehicleData?.RS?.ipvaActiveDebt,
                translate(`${I18N_BASE_PATH}.columns.ipvaPending`)
              )}
            </>
          )}

          {vehicleData?.RS?.licensing && (
            <>
              <Divider />
              {DetailsInfos(
                vehicleData?.RS?.licensing,
                translate(`${I18N_BASE_PATH}.columns.licensing`)
              )}
            </>
          )}

          {vehicleData?.RS?.expiredFines && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.RS?.expiredFines,
                translate(`${I18N_BASE_PATH}.columns.expiredFines`)
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.SP && (
        <DataSourceCardCollapsePanel title="São Paulo (SP)" toPrint={toPrint}>
          {vehicleData?.SP?.totalDebts && (
            <>
              <Row justify="center" className="mrg-top-10">
                <Col span={24}>
                  <Title level={5}>
                    {translate(
                      `components.dataSourceCards.financialInformation.shared.spcRestrictionsScore.index.spcScoreRange.totalDebts`
                    )}
                    : R$ {vehicleData?.SP?.totalDebts || '-'}
                  </Title>
                </Col>
              </Row>
            </>
          )}

          {vehicleData?.SP?.vehicle && (
            <>
              <Divider />
              {DetailsInfos(
                vehicleData?.SP?.vehicle,
                translate('services.vehicle_violations.title')
              )}
            </>
          )}

          {vehicleData?.SP?.ipva && (
            <>
              <Divider dashed />

              {DetailsInfos(
                {
                  calculationBase: vehicleData?.SP?.ipva?.calculationBase || '-',
                  aliquot: vehicleData?.SP?.ipva?.aliquot || '-',
                  calculatedIpva: vehicleData?.SP?.ipva?.calculatedIpva || '-',
                  paulistaFiscalNoteCredit:
                    vehicleData?.SP?.ipva?.paulistaFiscalNoteCredit || '-',
                  dueIpva: vehicleData?.SP?.ipva?.dueIpva || '-',
                  paymentMade: vehicleData?.SP?.ipva?.paymentMade || '-',
                  discountsOtherDeductions:
                    vehicleData?.SP?.ipva?.discountsOtherDeductions || '-',
                  balance: vehicleData?.SP?.ipva?.balance || '-',
                  legalAdditions: vehicleData?.SP?.ipva?.legalAdditions || '-',
                  toPayValue: vehicleData?.SP?.ipva?.toPayValue || '-',
                },
                'IPVA'
              )}

              <Divider dashed />

              {vehicleData?.SP?.ipva?.debtsPayment &&
                ListInfos(
                  vehicleData?.SP?.ipva?.debtsPayment,
                  translate(`${I18N_BASE_PATH}.columns.payments`)
                )}

              <Divider dashed />

              {vehicleData?.SP?.ipva?.debtsNotRegistered &&
                ListInfos(
                  vehicleData?.SP?.ipva?.debtsNotRegistered,
                  translate(`${I18N_BASE_PATH}.columns.debtsNotRegistered`)
                )}

              <Divider dashed />

              {vehicleData?.SP?.ipva?.registeredDebts &&
                DetailsInfos(
                  vehicleData?.SP?.ipva?.registeredDebts,
                  translate(`${I18N_BASE_PATH}.columns.registeredDebts`)
                )}
            </>
          )}

          {vehicleData?.SP?.fines && (
            <>
              <Divider />
              {DetailsInfos(
                {
                  totalQuantity: vehicleData?.SP?.fines?.totalQuantity || '-',
                  totalValue: vehicleData?.SP?.fines?.totalValue || '-',
                },
                'Multas'
              )}

              <Divider dashed />

              {vehicleData?.SP?.fines?.fines &&
                ListInfos(
                  vehicleData?.SP?.fines?.fines,
                  translate(`${I18N_BASE_PATH}.columns.finesInfo`)
                )}

              <Divider dashed />

              {vehicleData?.SP?.fines?.detailFines &&
                ListInfos(
                  vehicleData?.SP?.fines?.detailFines,
                  translate(`${I18N_BASE_PATH}.columns.details`)
                )}
            </>
          )}

          {vehicleData?.SP?.dpvat && (
            <>
              <Divider />
              {ListInfos(vehicleData?.SP?.dpvat, 'DPVAT')}
            </>
          )}
          {vehicleData?.SP?.taxes && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.SP?.taxes,
                translate(
                  'pages.private.KYBCompany.pages.companyDetails.subPages.financialInfo.tabs.accounts.api.localFinancialStatements.cashFlow.taxation'
                )
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.SE && (
        <DataSourceCardCollapsePanel title="Sergipe (SE)" toPrint={toPrint}>
          <>
            {DetailsInfos(
              {
                message: vehicleData?.SE?.message || '-',
                renavam: vehicleData?.SE?.renavam || '-',
                plate: vehicleData?.SE?.plate || '-',
                cpfCnpj: vehicleData?.SE?.cpfCnpj || '-',
                ownerName: vehicleData?.SE?.ownerName || '-',
                validityDate: vehicleData?.SE?.validityDate || '-',
              },
              translate('services.vehicle_violations.title')
            )}
          </>

          {vehicleData?.SE?.debt && (
            <>
              <Divider />

              {ListInfos(
                vehicleData?.SE?.debt?.debts,
                `${translate(`${I18N_BASE_PATH}.columns.debts`)}: R$ ${
                  vehicleData?.SE?.debt?.totalValue || '0,00'
                }`
              )}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}

      {vehicleData?.TO && (
        <DataSourceCardCollapsePanel title="Tocantins (TO)" toPrint={toPrint}>
          <>
            <Alert message={vehicleData?.TO?.message} type="info" showIcon />

            {DetailsInfos(
              {
                owner: vehicleData?.TO?.data?.owner || '-',
                address: vehicleData?.TO?.data?.address || '-',
                neighborhood: vehicleData?.TO?.data?.neighborhood || '-',
                city: vehicleData?.TO?.data?.city || '-',
                modelBrand: vehicleData?.TO?.data?.modelBrand || '-',
                manufacture: vehicleData?.TO?.data?.manufacture || '-',
                power: vehicleData?.TO?.data?.power || '-',
              },
              translate('services.vehicle_violations.title')
            )}
          </>

          {vehicleData?.TO?.previousIpva && (
            <>
              <Divider />
              {ListInfos(
                vehicleData?.TO?.previousIpva?.previousIpvas,
                translate(`${I18N_BASE_PATH}.columns.previousIpvas`)
              )}
            </>
          )}

          {vehicleData?.TO?.ipva && (
            <>
              <Divider />
              {ListInfos(vehicleData?.TO?.ipva?.ipvaDetails, 'IPVA')}
            </>
          )}
        </DataSourceCardCollapsePanel>
      )}
    </DataSourceCardCollapsePanel>
  );
}

export default VehicleSefazInfosCollapsePanel;
