import React, { useCallback, useMemo } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Avatar, Table, Button } from 'antd';
import PropTypes from 'prop-types';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useTranslation } from 'react-i18next';

import ProfileDefault from 'assets/images/profile-default.png';
import { isGroupAdmin } from 'utils/translations';
import useModalJsonView from './ModalJsonView';

import { resourcesTypes, actionTypes } from '../../utils';

import './styles.less';

const I18N_BASE_PATH = 'pages.private.securityAndPrivacy.auditLog.logsTable';

const LogsTable = ({ datasource }) => {
  const { openModalJsonView, ModalComponent } = useModalJsonView();
  const { i18nFormatDate } = useI18nFormatters();
  const { t: translate } = useTranslation();

  const formattAction = useCallback(
    (action, resourceType) => {
      const ac = actionTypes.find((a) => a.value === action);
      const re = resourcesTypes.find((r) => r.value === resourceType);
      return (
        <>
          {translate(ac.label)} {translate(`${I18N_BASE_PATH}.index.at`)}{' '}
          <b>{translate(re?.label)}</b>
        </>
      );
    },
    [translate]
  );

  const columns = useMemo(() => {
    return [
      {
        title: translate(`${I18N_BASE_PATH}.index.user`),
        dataIndex: 'name',
        key: 'name',
        render: (text, row) => (
          <div>
            <Avatar src={row?.user?.profileImageUrl || ProfileDefault} />
            <span style={{ paddingLeft: '10px' }}>
              {row?.user?.name ||
                row?.name ||
                `${translate(`${I18N_BASE_PATH}.cafUser`)} (${row?.userId})`}
            </span>
          </div>
        ),
      },
      {
        title: translate(`${I18N_BASE_PATH}.index.action`),
        dataIndex: 'action',
        key: 'action',
        render: (text, row) => (
          <span style={{ paddingLeft: '10px' }}>
            {formattAction(text, row?.resourceType)}
          </span>
        ),
      },
      {
        title: translate(`${I18N_BASE_PATH}.index.group`),
        dataIndex: 'permission',
        key: 'permision',
        render: (text, row) => {
          return (
            <div>
              <span>
                <span>
                  {isGroupAdmin(row?.group?.id)
                    ? translate('global.hooks.group.getListGroups.admin')
                    : row?.group?.name}{' '}
                </span>
                <Button
                  id="click_audit_log_show_permission"
                  onClick={() =>
                    openModalJsonView({
                      data: row?.currentPermissions?.replace(
                        'Administrador',
                        translate('global.hooks.group.getListGroups.admin')
                      ),
                    })
                  }
                  type="button"
                  className="button-table-eye"
                >
                  <EyeOutlined style={{ color: '#004af7' }} />
                </Button>
              </span>
            </div>
          );
        },
      },
      {
        title: translate(`${I18N_BASE_PATH}.index.updates`),
        key: 'alteracoes',
        render: (text, row) => (
          <div className="version-content">
            <Button
              id="click_audit_log_show_diff"
              onClick={() =>
                openModalJsonView({
                  data: row?.currentVersion,
                  dataToCompare: row?.previousVersion,
                })
              }
              type="button"
              className="button-table-eye"
            >
              <EyeOutlined style={{ color: '#004af7' }} />
            </Button>
          </div>
        ),
      },
      {
        title: translate(`${I18N_BASE_PATH}.index.createdAt`),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => (
          <div className="version-content">
            <p>{i18nFormatDate(text, 4)}</p>
          </div>
        ),
      },
    ];
  }, [formattAction, i18nFormatDate, openModalJsonView, translate]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={datasource}
        pagination={false}
        size="small"
        className="users-table-container"
      />
      {ModalComponent}
    </>
  );
};

LogsTable.propTypes = {
  datasource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default LogsTable;
