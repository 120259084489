const I18N_BASE_PATH = 'components.dataSourceCards.vehicleInfosModal.columns';

/**
 *  Mapear os campos que deseja exibir,
 *  por padrao as labels sao mapeadas com a mesma key no i18n
 * */

const MAPPED_FIELDS = [
  'manufactureYear',
  'modelYear',
  'gearBox',
  'loadCapacity',
  'vehicleCategory',
  'chassis',
  'engineCapacity',
  'cmtVehicle',
  'srfAgencyCode',
  'vehicleFuel',
  'vehicleColor',
  'updateDate',
  'registrationDate',
  'taxRestrictionLimitDate',
  'diRegistrationDate',
  'vehicleKind',
  'modelBrand',
  'city',
  'bodyworkName',
  'ownerName',
  'bodyworkNumber',
  'quantityAxles',
  'diNumber',
  'auxiliaryAxeNumber',
  'rearAxeNumber',
  'engineNumber',
  'identificationNumberBilling',
  'importerIdentificationNumber',
  'ownerIdentificationNumber',
  'importProcessNumber',
  'redaNumber',
  'pbtVehicle',
  'plate',
  'vehiclePower',
  'origin',
  'passengersQuantity',
  'renavam',
  'situation',
  'chassisSituation',
  'bodyworkType',
  'vehicleType',
  'billingDocumentType',
  'importerDocumentType',
  'ownerDocumentType',
  'assemblyType',
  'vehicleImportOperationType',
  'state',
  'billingDestinationState',
  'restrictionAgentName',
  'tenantRestriction',
  'inclusionDateRestriction',
  'tenantDocumentRestriction',
];

export function fieldLabelMappings(key) {
  if (MAPPED_FIELDS.includes(key)) return `${I18N_BASE_PATH}.${key}`;
  return null;
}
