import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function usePostDashboard(post, setIsFrameLoading, path, setTabPath) {
  const { i18n } = useTranslation();
  const location = useLocation();

  const dashType = location.pathname.split('/')[2];
  const prevPath = useRef(path);

  useEffect(() => {
    const hasPathChanged = prevPath.current !== path;
    const dashboardId = hasPathChanged ? path : dashType;

    prevPath.current = path;

    if (dashType !== 'onboarding') {
      setTabPath(false);
    }

    if (!dashboardId) return;
    setIsFrameLoading(true);

    const postDashboardData = async () => {
      await post({
        url: '/dashboard',
        payload: { dashboardId, language: i18n.language },
      });
    };

    postDashboardData();
  }, [dashType, post, setIsFrameLoading, i18n.language, path, setTabPath]);
}

export function useIFrameLoading(setIsFrameLoading) {
  useEffect(() => {
    const handleIFrameLoading = (event) => {
      if (event.origin !== 'https://caf.metabaseapp.com') {
        return;
      }

      // Detects when the metabase iframe is loaded
      if (event.data?.metabase) {
        setIsFrameLoading(false);
      }
    };

    // Register the event listener
    window.addEventListener('message', handleIFrameLoading);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('message', handleIFrameLoading);
    };
  }, [setIsFrameLoading]);
}
