import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Button, Card, Form, Select, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import EmptyMessage from 'components/EmptyMessage';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import './styles.less';
import { useAuth } from 'hooks/auth';

const I18N_BASE_PATH = 'pages.private.routines.detail.components.routineValidation';

const RoutineValidation = ({ validationsOptions, validations, onChange, form }) => {
  const { t: translate } = useTranslation();
  const { Option } = Select;
  const { Text } = Typography;
  const { user } = useAuth();

  const [validationsSelected, setValidationsSelected] = useState([]);
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    const formFilters =
      form?.getFieldValue('validations')?.map((validation) => validation?.rule) || [];
    setValidationsSelected(formFilters);
  }, []);

  const showSubjectTitle = useMemo(
    () => !!user?.accountData?.featureFlags?.useRulesSubjectTitle,
    [user]
  );

  useEffect(() => {
    const fieldsArray = validationsSelected?.map((selected) => {
      const rule = validationsOptions.find((validation) => validation.rule === selected);
      const customField = (
        <Select>
          {rule?.status?.INVALID?.availableActions?.map((action) => (
            <Option value={action}>
              <div className={`option-${action}`}>
                <Text>
                  {translate(
                    `${I18N_BASE_PATH}.form.actionWhenInvalid.options.${action}`
                  )}
                </Text>
              </div>
            </Option>
          )) || <Option />}
        </Select>
      );
      return customField;
    });
    setCustomFields(fieldsArray);
  }, [translate, validations, validationsOptions, validationsSelected]);

  const onFieldChange = useCallback(
    (index, key, value) => {
      // Limpa campos do form
      const formFilter = form.getFieldValue('validations');
      formFilter[index].actionWhenInvalid = undefined;
      form.setFieldsValue({ filter: formFilter });

      // Seta novo estado filters
      const newState = [...validationsSelected];
      newState[key] = value;
      setValidationsSelected(newState);
      onChange(formFilter);
    },
    [form, onChange, validationsSelected]
  );

  const onFieldRemoved = useCallback(() => {
    const formFilter = form.getFieldValue('validations');
    onChange(formFilter);
  });

  return (
    <>
      <Row align="middle" className="flex no-mrg mrg-top-50">
        <Col className="flex-1 page-title">
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.subtitle`)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card className="pdd-left-50 pdd-right-50">
            <Form.List name="validations">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <>
                      <Row gutter={50}>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'rule']}
                            label={
                              index === 0
                                ? translate(`${I18N_BASE_PATH}.form.validation`)
                                : undefined
                            }
                            rules={[{ required: true }]}
                          >
                            <Select
                              onChange={(value) => onFieldChange(index, key, value)}
                            >
                              {validationsOptions.map((option) => (
                                <Option value={option.rule} disabled={option.disabled}>
                                  {showSubjectTitle && option.subjectTitle
                                    ? translate(
                                        `validations.${option.rule.key}.subjectTitle`,
                                        option.subjectTitle
                                      )
                                    : translate(
                                        `validations.${option.rule.key}.title`,
                                        option.title
                                      )}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            name={[name, 'actionWhenInvalid']}
                            label={
                              index === 0
                                ? translate(
                                    `${I18N_BASE_PATH}.form.actionWhenInvalid.label`
                                  )
                                : undefined
                            }
                            rules={[{ required: true }]}
                          >
                            {customFields[key] ?? <Input />}
                          </Form.Item>
                        </Col>

                        <Col span={2} className="flex center-start last-column">
                          <Form.Item
                            label={
                              index === 0
                                ? translate(`${I18N_BASE_PATH}.form.delete`)
                                : undefined
                            }
                          >
                            <Button
                              htmlType="button"
                              type="danger"
                              rounded={false}
                              onClick={() => {
                                remove(name);
                                onFieldRemoved(key);
                              }}
                              className="remove-button"
                            >
                              <i className="caf-ic_trash" />
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ))}
                  {fields.length === 0 && (
                    <EmptyMessage
                      show
                      description={translate(`${I18N_BASE_PATH}.emptyValidationList`)}
                      withCard={false}
                      type="compliance"
                      className="empty-custom"
                    />
                  )}
                  <Row>
                    <Col
                      span={4}
                      className={`${
                        fields.length === 0
                          ? 'display-block mrg-horizon-auto no-mrg-top mrg-btm-30'
                          : ''
                      }`}
                    >
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {translate(`${I18N_BASE_PATH}.form.add`)}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Card>
        </Col>
      </Row>
      <Row />
    </>
  );
};

RoutineValidation.propTypes = {
  validationsOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  validations: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RoutineValidation;
