import React, { useEffect, useRef, useCallback } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { Typography, Row, Col, Card, Form, Select, Alert } from 'antd';
import PageTitle from 'components/PageTitle';
import Tag from 'components/Tag';

import { useAuth } from 'hooks/auth';

import useComponentList from 'components/List/hooks/useComponentList';
import SearchInput from 'components/Form/SearchInput';
import _ from 'lodash';

import androidLogo from 'assets/images/android-logo.png';
import iosLogo from 'assets/images/ios-logo.png';

import HiddenParagraph from 'components/HiddenParagraph';
import useModalProfileTemplateApiGuide from 'components/ModalProfileTemplateApiGuide';
import useConfirmAction from 'pages/private/ProfileModels/ProfileModels/components/ConfirmAction';
import { AlertTriangle } from '@combateafraude/icons/general';
import IntegrationGuideCard from './components/IntegrationGuideCard';
import useWebhookModal from './components/WebhookModal';
import './styles.less';

const { Title, Link } = Typography;
const { Option } = Select;

const AccessKeys = () => {
  const refreshListRef = useRef(() => {});
  const { t: translate } = useTranslation();
  const [query] = useQueryParams({
    _search: StringParam,
    _order: StringParam,
    _sort: StringParam,
    active: StringParam,
  });
  const { openModal, WebhookModal } = useWebhookModal();
  const {
    openModal: openApiIntegrationGuideModal,
    ProfileTemplateApiGuideModal,
  } = useModalProfileTemplateApiGuide();
  const { openModal: openConfirmActionModal, ConfirmActionModal } = useConfirmAction({
    refreshListRef,
    type: 'profile-template',
  });

  const { user } = useAuth();

  const { componentList, refreshList, setFilterData } = useComponentList({
    component: IntegrationGuideCard,
    rowKey: '_id',
    getParams: {
      url: `/profile-templates`,
      config: {
        params: {},
      },
    },
    queryParams: {
      _search: StringParam,
      _order: StringParam,
      _sort: StringParam,
      active: StringParam,
    },
    customModal: { openApiIntegrationGuideModal, openModal, openConfirmActionModal },
    defaultFilter: {
      _order: query?._order || '1',
      _sort: query?._sort || 'name',
      active: query?.active || undefined,
    },
  });

  const handleFormChange = useCallback(
    _.debounce((__, values) => {
      setFilterData({
        ...values,
        active: values.active && values.active !== 'all' ? values.active : undefined,
        _search: values._search ? values._search.trim() : undefined,
      });
    }, 500),
    []
  );
  useEffect(() => {
    refreshListRef.current = refreshList;
  }, [refreshList]);

  return (
    <>
      {ProfileTemplateApiGuideModal}
      {WebhookModal}
      {ConfirmActionModal}
      <div
        id="integration-guide-component"
        className="flex align-center gx-w-100 mrg-btm-40 space-between"
      >
        <PageTitle
          title={translate('pages.private.accessKeys.integrationGuide.index.title')}
          subtitle={translate('pages.private.accessKeys.integrationGuide.index.subtitle')}
        />

        <div className="flex end-center mrg-right-20">
          <Form
            layout="inline"
            onValuesChange={handleFormChange}
            initialValues={{ _search: query?._search, active: query?.active || 'all' }}
          >
            <SearchInput
              placeholder={translate(
                'pages.private.profileModels.profileModelList.index.profileModelList.form.profileModel.placeholder'
              )}
              style={{ minWidth: 150 }}
            />
            <Form.Item name="active" className="no-mrg-btm">
              <Select
                bordered={false}
                style={{ width: 100, fontWeight: 500 }}
                className="text-dark"
              >
                <Option value="all">
                  {translate(
                    'pages.private.profileModels.profileModelList.index.profileModelList.form.select.options.all'
                  )}
                </Option>
                <Option value="true">
                  {translate(
                    'pages.private.profileModels.profileModelList.index.profileModelList.form.select.options.true'
                  )}
                </Option>
                <Option value="false">
                  {translate(
                    'pages.private.profileModels.profileModelList.index.profileModelList.form.select.options.false'
                  )}
                </Option>
              </Select>
            </Form.Item>
          </Form>
          <Title level={4} className="no-mrg-vertical mrg-right-15">
            {translate('pages.private.accessKeys.index.docs')}
          </Title>
          <Link
            href="https://docs.combateafraude.com/docs/mobile/android/getting-started/"
            target="_blank"
            className="mrg-right-10"
          >
            <img
              src={androidLogo}
              alt={translate('pages.private.accessKeys.index.alts.androidLogo')}
              className="android-logo grayscale-95"
            />
          </Link>
          <Link
            href="https://docs.combateafraude.com/docs/mobile/ios/getting-started/"
            target="_blank"
          >
            <img
              src={iosLogo}
              alt={translate('pages.private.accessKeys.index.alts.iosLogo')}
              className="ios-logo grayscale-95"
            />
          </Link>
        </div>
      </div>
      <Card>
        <Alert
          className="mrg-top-20"
          message={
            <div>
              <AlertTriangle color="#F16E00" fontSize={20} className="mrg-right-10" />
              {translate('pages.private.accessKeys.integrationGuide.index.alertMessage1')}
              <a
                href="integration-guide"
                className="text-bold gx-text-underline mrg-left-5 mrg-right-5"
              >
                Tokens
              </a>
              {translate('pages.private.accessKeys.integrationGuide.index.alertMessage2')}
            </div>
          }
          type="warning"
        />
        <Row className="flex center">
          <Col span={1}>
            <Tag className="ant-tag-success text-bold">
              {translate('pages.private.accessKeys.integrationGuide.index.api')}
            </Tag>
          </Col>
          <Col span={3}>
            <b className="text-dark text-bold">
              {translate('pages.private.accessKeys.integrationGuide.index.accessToken')}
            </b>
          </Col>
          <Col span={20}>
            <HiddenParagraph
              text={user?.accountData?.products?.trust?.cafAccessToken || ''}
              copyable
              hidden
              trackEvent
            />
          </Col>
        </Row>
      </Card>
      {componentList}
    </>
  );
};

export default AccessKeys;
