import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { useAuth } from 'hooks/auth';

import Button from 'components/Button';
import notify from 'assets/images/notify.svg';

import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import Wrapper from '../wrapper';

import './styles.less';

const { Title, Paragraph } = Typography;

const Unauthorized = () => {
  const { t: translate } = useTranslation();

  const { user } = useAuth();

  const hasProduct = useMemo(() => !!user?.products?.trust, [user]);

  const redirectTo = useMemo(() => {
    if (!hasRequiredPermissions(user, ['metrics:read'], [])) {
      return `/profile/people?_type=${translate(
        'pages.private.unauthorized.index.type.person'
      )}`;
    }

    return '/home';
  }, [user, translate]);

  return (
    <Wrapper>
      <div id="unauthorized-component" className="flex-column center">
        <Title className="title">
          {translate(
            `pages.private.unauthorized.index.wrapperTitle.${
              hasProduct ? 'hasProduct' : 'hasNotProduct'
            }`
          )}
        </Title>

        <Paragraph className="subtitle">
          {translate('pages.private.unauthorized.index.wrapperParagraph')}
        </Paragraph>

        <img
          className="grayscale-95 mrg-top-60 mrg-btm-30"
          src={notify}
          alt={translate('pages.private.unauthorized.index.wrapperImg.alt')}
        />

        {hasProduct && (
          <Button type="primary">
            <Link to={redirectTo}>
              {translate('pages.private.unauthorized.index.wrapperButton')}
            </Link>
          </Button>
        )}
      </div>
    </Wrapper>
  );
};

export default Unauthorized;
