import { Col, Row, Divider, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import '../styles.less';

const { Title } = Typography;

const I18N_BASE_PATH = 'components.dataSourceCards.denatranDriverAltInfos';

function DriverExamsInfosCollapsePanel({ exams = [], toPrint = false }) {
  const { t: translate } = useTranslation();

  const customHeader = useMemo(() => {
    return (
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col span={18}>
          <i className="caf-ic_file" />
          <span className="text-dark text-bold pdd-left-10">
            {translate(`${I18N_BASE_PATH}.examsTitle`).toUpperCase()}
          </span>
        </Col>
      </Row>
    );
  }, [translate]);

  const DetailsInfos = (data, title) => {
    return (
      <>
        {title && (
          <Row>
            <Col span={24}>
              <Title level={5}>{title}</Title>
            </Col>
          </Row>
        )}

        <Row justify="space-between" className="mrg-top-10">
          {Object.keys(data).map((dtKey) => {
            return (
              <ColumnTitleValue
                key={dtKey}
                span={8}
                className="inline-infos"
                title={`${translate(`${I18N_BASE_PATH}.columns.${dtKey}`)}:`}
                value={data[dtKey] || '-'}
              />
            );
          })}
        </Row>
      </>
    );
  };

  return (
    <DataSourceCardCollapsePanel customHeader={customHeader} toPrint={toPrint}>
      {exams?.map((exam, index) => (
        <React.Fragment key={index}>
          {DetailsInfos(exam)}

          {index < exams?.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </DataSourceCardCollapsePanel>
  );
}

export default DriverExamsInfosCollapsePanel;
