const I18N_BASE_PATH = 'components.dataSourceCards.driverInfosModal.columns';

/**
 *  Mapear os campos que deseja exibir,
 *  por padrao as labels sao mapeadas com a mesma key no i18n
 * */
const MAPPED_FIELDS = [
  'cnhNumber',
  'cnhIssuerAgency',
  'driverName',
  'driverGender',
  'driverBirthDate',
  'documentType',
  'documentNumber',
  'driverNationality',
  'driverMotherName',
  'driverFatherName',
  'driverFirstLicenseDate',
  'pguId',
  'cnhCategory',
  'addressUsedCnhRequest',
  'cnhStatus',
  'cnhExpirationDate',
  'cpf',
  'state',
  'renachNumber',
  'cnhEmissionDate',
  'cnhObservation',
  'cnhOrdinance',
  'cnhImpediment',
];

export function fieldLabelMappings(key) {
  if (MAPPED_FIELDS.includes(key)) return `${I18N_BASE_PATH}.${key}`;

  return null;
}
