import React, { useMemo } from 'react';
import EmptyExecutionState from 'components/EmptyExecutionState';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography } from 'antd';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import DriverInfosCollapsePanel from '../DriverInfosCollapsePanel';
import DriverViolationsInfosCollapsePanel from './DriverViolationsInfosCollapsePanel';
import DriverLicensePointsInfosCollapsePanel from './DriverLicensePointsInfosCollapsePanel';
import DriverExamsInfosCollapsePanel from './DriverExamsInfosCollapsePanel';
import DriverCoursesInfosCollapsePanel from './DriverCoursesInfosCollapsePanel';
import DriverBlocksInfosCollapsePanel from './DriverBlocksInfosCollapsePanel';

const { Title } = Typography;

export const DriverViolations = ({ source = {}, toPrint = false }) => {
  const { t: translate } = useTranslation();

  const data = useMemo(() => source?.data, [source]);

  const driver = useMemo(() => {
    const {
      driverName,
      cpf,
      driverBirthDate,
      state,
      driverMotherName,
      driverFatherName,
      renachNumber,
      cnhNumber,
      cnhCategory,
      cnhEmissionDate,
      cnhExpirationDate,
      driverFirstLicenseDate,
      cnhObservation,
      cnhOrdinance,
      cnhImpediment,
      cnhStatus,
    } = data;

    return {
      driverName,
      cpf,
      driverBirthDate,
      state,
      driverMotherName,
      driverFatherName,
      renachNumber,
      cnhNumber,
      cnhCategory,
      cnhEmissionDate,
      cnhExpirationDate,
      driverFirstLicenseDate,
      cnhObservation,
      cnhOrdinance: cnhOrdinance || '-',
      cnhImpediment: cnhImpediment || '-',
      cnhStatus,
    };
  }, [data]);
  const licensePoints = useMemo(() => {
    if (
      data?.licensePoints?.driversLicensePointsEffectSuspensive === '' ||
      data?.licensePoints?.driversLicensePointsEffectSuspensive === '0'
    )
      return null;

    return data?.licensePoints;
  }, [data]);
  const exams = useMemo(() => data?.exams, [data]);
  const courses = useMemo(() => data?.courses, [data]);
  const blocks = useMemo(() => data?.blocks, [data]);
  const violations = useMemo(() => data?.violations, [data]);

  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_driver"
      title={translate(
        'pages.private.executions.components.dataSourceCards.basicInfos.components.basicInfosCard.sources.driverViolations',
        'Driver data'
      )}
      toPrint={toPrint}
    >
      {statusCode !== '01' ? (
        <EmptyExecutionState
          statusCode={statusCode}
          message={source?.message || source?.data?.message}
        />
      ) : (
        <>
          <Row>
            <Col span={24}>
              <Title level={4}>{driver?.driverName}</Title>
            </Col>

            <Col span={24}>
              <DriverInfosCollapsePanel driver={driver} toPrint={toPrint} />
            </Col>
          </Row>
          <Row className="mrg-top-10">
            <Col span={24}>
              <DriverViolationsInfosCollapsePanel
                violations={violations}
                toPrint={toPrint}
              />
            </Col>
          </Row>

          {licensePoints && (
            <Row>
              <Col span={24}>
                <DriverLicensePointsInfosCollapsePanel
                  licensePoints={licensePoints}
                  toPrint={toPrint}
                />
              </Col>
            </Row>
          )}
          {exams && (
            <Row>
              <Col span={24}>
                <DriverExamsInfosCollapsePanel exams={exams} toPrint={toPrint} />
              </Col>
            </Row>
          )}
          {courses && (
            <Row>
              <Col span={24}>
                <DriverCoursesInfosCollapsePanel courses={courses} toPrint={toPrint} />
              </Col>
            </Row>
          )}
          {blocks && (
            <Row>
              <Col span={24}>
                <DriverBlocksInfosCollapsePanel blocks={blocks} toPrint={toPrint} />
              </Col>
            </Row>
          )}
        </>
      )}
    </DataSourceCardCollapsePanel>
  );
};
