import { Button, Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';
import ColumnTitleValue from 'pages/private/Profiles/components/TableInfoCard/ColumnTitleValue';

import useVehicleInfosDetails from './VehicleInfosDetailsModal';
import '../styles.less';

const I18N_BASE_PATH = 'components.dataSourceCards.denatranVehicleInfos';
function VehicleInfosCollapsePanel({ vehicle = {}, toPrint = false }) {
  const { t: translate } = useTranslation();
  const { openModal, VehicleInfosDetailsModal } = useVehicleInfosDetails();

  const customHeader = useMemo(() => {
    return (
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col span={18}>
          <i className="caf-ic_driver" />
          <span className="text-dark text-bold pdd-left-10">
            {translate(`${I18N_BASE_PATH}.title`)}
          </span>
        </Col>
      </Row>
    );
  }, [translate]);

  const DetailsInfos = (data) => {
    return (
      <Row justify="space-between" className="mrg-top-10">
        {Object.keys(data).map((dtKey) => {
          return (
            <ColumnTitleValue
              key={dtKey}
              span={8}
              className="inline-infos"
              title={`${translate(`${I18N_BASE_PATH}.columns.${dtKey}`)}:`}
              value={data[dtKey] || '-'}
            />
          );
        })}
      </Row>
    );
  };

  return (
    <>
      {VehicleInfosDetailsModal}
      <DataSourceCardCollapsePanel customHeader={customHeader} toPrint={toPrint}>
        {vehicle &&
          DetailsInfos({
            modelBrandDescription: vehicle?.modelBrand,
            manufactureYear: vehicle?.manufactureYear,
            power: vehicle?.vehiclePower,
            plate: vehicle?.plate,
            chassiSerial: vehicle?.chassis,
            colorDescription: vehicle?.vehicleColor,
          })}

        <Row className="mrg-top-10">
          <Button type="link" onClick={() => openModal(vehicle)}>
            <i className="caf-ic_arrow-right" />
            <span className="mrg-left-10">{`${translate(
              `${I18N_BASE_PATH}.columns.moreDetails`
            )}`}</span>
          </Button>
        </Row>
      </DataSourceCardCollapsePanel>
    </>
  );
}

export default VehicleInfosCollapsePanel;
