/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { toMaskedCnpj, toMaskedCpf } from 'utils/formatters';
import { validateCpf, validateCnpj } from 'utils/validations';

import ViewMore from 'components/ViewMore';
import DottedLabel from 'components/DottedLabel';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

const I18N_BASE_PATH = 'pages.private.executions.components.list.index.list.loadMore';
const I18N_LEGAL_BASE_PATH =
  'components.dataSourceCards.legalInformation.lists.legalRecordsExtendedList.index.legalRecordsExtendedList';

const LawsuitSide = {
  Active: 'Active',
  Passive: 'Passive',
  Interested: 'Interested',
  Unknown: 'Unknown',
};

const LegalRecordsList = ({ data, initialDisplayValue = 3, toPrint }) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDateOnly, i18nFormatCurrency } = useI18nFormatters();

  const [displayValue, setDisplayValue] = useState(
    Math.min(initialDisplayValue, data.length)
  );

  const loadMoreData = useCallback(() => {
    if (displayValue + 10 > data.length) {
      return setDisplayValue(data.length);
    }

    setDisplayValue(displayValue < 10 ? 10 : displayValue + 10);
  }, [data.length, displayValue]);

  return (
    <div id="executions-legal-information-processes-container">
      <Row className="end-center font-size-16">
        <p>
          {translate(`${I18N_LEGAL_BASE_PATH}.displayValue`, {
            displayValue,
            dataLength: data?.length,
          })}
        </p>
      </Row>
      <Row className="legal-information-processes-title">
        {Array.isArray(data) &&
          data?.slice(0, toPrint ? data?.length : displayValue)?.map((record) => (
            <Col
              className="col-ant"
              span={24}
              style={{
                paddingLeft: '0px',
                paddingRight: '0px',
              }}
            >
              <DataSourceCardCollapsePanel
                title={
                  <>
                    {record?.name || 'NÃO IDENTIFICADO'}
                    {record?.cnjCode && (
                      <>
                        <br />
                        <span className="text-light font-size-15">{record.cnjCode}</span>
                      </>
                    )}
                  </>
                }
                toPrint={toPrint}
                icon="caf-ic_file"
              >
                <Row className="mrg-left-5 mrg-right-5 mrg-top-15">
                  <Col className="mrg-btm-10" span={24}>
                    <span>
                      {translate(
                        `${I18N_LEGAL_BASE_PATH}.generalInformation`
                      ).toUpperCase()}
                    </span>
                  </Col>
                  <Col span={24}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.subject`)}</strong>
                    <p>{record?.subjects[0]?.subject || 'Indisponível'}</p>
                  </Col>
                  <Col span={4}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.court`)}</strong>
                    <p>{record?.courtAcronym || 'Indisponível'}</p>
                  </Col>
                  <Col span={4}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.lawsuitValue`)}</strong>
                    <p>{i18nFormatCurrency(record?.amount, 'pt-BR') || 'Indisponível'}</p>
                  </Col>
                  <Col span={4}>
                    <strong>
                      {translate(`${I18N_LEGAL_BASE_PATH}.distributionDate`)}
                    </strong>
                    <p>
                      {(record?.distributionDate &&
                        i18nFormatDateOnly(record?.distributionDate, 1)) ||
                        'Indisponível'}
                    </p>
                  </Col>
                  <Col span={4}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.parties`)}</strong>
                    <p>
                      {record?.parties?.length > 0
                        ? `${record?.parties?.length}
                          ${translate(`${I18N_LEGAL_BASE_PATH}.involvedParties`)}`
                        : 'Indisponível'}
                    </p>
                  </Col>
                  <Col span={4}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.rank`)}</strong>
                    <p>
                      {record?.instance > 0 ? `${record?.instance}º` : 'Indisponível'}
                    </p>
                  </Col>
                  <Col span={4}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.status`)}</strong>
                    <DottedLabel
                      title="Status"
                      description={record?.status || 'Indisponível'}
                      type={record?.status?.includes('Ativo') ? 'primary' : ''}
                    />
                  </Col>
                  <Col span={8}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.judgingAgency`)}</strong>
                    <p>{record?.courtDistrict || 'Indisponível'}</p>
                  </Col>
                  <Col span={8}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.lawsuitClass`)}</strong>
                    <p>
                      {record?.classifications[0]?.code &&
                      record?.classifications[0]?.classification
                        ? `(${record?.classifications[0]?.code}) ${record?.classifications[0]?.classification}`
                        : 'Indisponível'}
                    </p>
                  </Col>
                  <Col span={24}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.judge`)}</strong>
                    <p>{record?.judge || 'Indisponível'}</p>
                  </Col>
                </Row>
                <Divider />
                <Row className="mrg-left-5 mrg-right-5 mrg-top-15">
                  <Col className="mrg-btm-10" span={24}>
                    <span>
                      {translate(`${I18N_LEGAL_BASE_PATH}.lawsuitData`).toUpperCase()}
                    </span>
                  </Col>
                  <Col span={4}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.courtOrigin`)}</strong>
                    <p>{record?.courtAcronym || 'Indisponível'}</p>
                  </Col>
                  <Col span={8}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.courtDistrict`)}</strong>
                    <p>{record?.courtDistrict || 'Indisponível'}</p>
                  </Col>
                  <Col span={6}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.city`)}</strong>
                    <p>{record?.city || 'Indisponível'}</p>
                  </Col>
                  <Col span={4}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.state`)}</strong>
                    <p>{record?.state || 'Indisponível'}</p>
                  </Col>
                  <Col span={6}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.justiceSector`)}</strong>
                    <p>{record?.area || 'Indisponível'}</p>
                  </Col>
                  <Col span={4}>
                    <strong>{translate(`${I18N_LEGAL_BASE_PATH}.phase`)}</strong>
                    <p>{record?.phase || 'Indisponível'}</p>
                  </Col>
                </Row>
                <Divider />
                <Row className="mrg-left-5 mrg-right-5 mrg-top-15">
                  <Col className="mrg-btm-10" span={24}>
                    <span>
                      {translate(`${I18N_LEGAL_BASE_PATH}.lawsuitParties`).toUpperCase()}
                    </span>
                  </Col>
                  {Object.keys(LawsuitSide).map((key) => (
                    <>
                      {record?.parties?.find(
                        (party) => party.side === LawsuitSide[key]
                      ) && (
                        <Col className="mrg-btm-10" span={24}>
                          <h3 className="font-size-21 text-bold">
                            {translate(`${I18N_LEGAL_BASE_PATH}.side${LawsuitSide[key]}`)}
                          </h3>
                        </Col>
                      )}
                      {record?.parties
                        ?.filter((party) => party.side === LawsuitSide[key])
                        .map((party) => (
                          <>
                            <Col span={8}>
                              <strong>
                                {party?.type?.charAt(0).toUpperCase() +
                                  party?.type?.slice(1).toLowerCase()}
                              </strong>
                              <p>{party?.party || 'Indisponível'}</p>
                            </Col>
                            <Col span={16}>
                              {validateCpf(party.mainDocument) && (
                                <>
                                  <strong>
                                    {translate(`${I18N_LEGAL_BASE_PATH}.cpf`)}
                                  </strong>
                                  <p>{toMaskedCpf(party.mainDocument)}</p>
                                </>
                              )}
                              {validateCnpj(party.mainDocument) && (
                                <>
                                  <strong>
                                    {translate(`${I18N_LEGAL_BASE_PATH}.cnpj`)}
                                  </strong>
                                  <p>{toMaskedCnpj(party?.mainDocument)}</p>
                                </>
                              )}
                            </Col>
                          </>
                        ))}
                    </>
                  ))}
                </Row>
              </DataSourceCardCollapsePanel>
            </Col>
          ))}
      </Row>
      {!toPrint && displayValue && data?.length > displayValue && (
        <Row className="center">
          <ViewMore
            className="center"
            type="link"
            title={translate(`${I18N_BASE_PATH}.viewMore.title`)}
            onClick={() => loadMoreData()}
          />
        </Row>
      )}
    </div>
  );
};

export default LegalRecordsList;
