export const BOTH_TYPES_SOURCES = [
  'zip_code_details',
  'suspicious_places',
  'email_age_private',
  'denatran_driver_violations',
  'denatran_vehicle_violations',
  'antt',
  'driver_violations',
  'vehicle_violations',
];
