export function hasFeatureFlag(featureFlags, user) {
  if (!Array.isArray(featureFlags)) {
    // If a single featureFlag is passed, convert it to an array
    featureFlags = [featureFlags];
  }

  if (
    user?.accountData?.featureFlags !== undefined &&
    featureFlags.some((flag) => user.accountData.featureFlags[flag])
  ) {
    return true; // At least one feature flag exists
  }

  return false;
}
