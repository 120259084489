import React from 'react';
import PropTypes from 'prop-types';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { useAuth } from 'hooks/auth';
import { Link } from 'react-router-dom';
import './styles.less';
import classNames from 'classnames';
import CardTag from '../CardTag';

const QuickAccessCard = (props) => {
  const {
    Icon,
    title,
    description,
    to,
    permissions,
    className,
    isTagged,
    tagName,
  } = props;
  const { user } = useAuth();

  if (
    !!permissions &&
    !hasRequiredPermissions(
      user,
      permissions.requiredPermissions,
      permissions.requiredResources,
      permissions.or,
      permissions.product
    )
  )
    return null;

  return (
    <Link to={to}>
      <div className={classNames('quickAccess__wrapper', className)}>
        <div className="quickAccess__icon_wrapper">
          <span>
            <Icon />
          </span>
        </div>
        <div className="quickAccess__content_wrapper">
          <div className="quickAccess__title_wrapper">
            <h3 className="quickAccess__content_title">{title}</h3>
            {isTagged && <CardTag text={tagName} />}
          </div>
          <h5 className="quickAccess__content_description">{description}</h5>
        </div>
      </div>
    </Link>
  );
};

QuickAccessCard.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    requiredPermissions: PropTypes.arrayOf(PropTypes.string),
    requiredResources: PropTypes.arrayOf(PropTypes.string),
    or: PropTypes.bool,
    product: PropTypes.string,
  }),
  className: PropTypes.string,
  isTagged: PropTypes.bool,
  tagName: PropTypes.string,
};

QuickAccessCard.defaultProps = {
  permissions: {},
  className: '',
  isTagged: false,
  tagName: '',
};

export default QuickAccessCard;
